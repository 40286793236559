@mixin hover-transition {
   transition: all 0.2s ease-in-out;
}

@mixin content-flex {
   display: flex;
   flex-direction: row;
   align-content: flex-start;
   justify-content: space-between;
   flex-wrap: wrap;
}

@mixin content-tile-wrapper {
   @include color("text-primary");
   @include background-color("content-tile");
   box-shadow: 0 -2px 0 darken(#e5e5e5, 5%) inset;
   border-radius: 3px;
}

@mixin content-tile-wrapper-responsive {
   flex: 1 1 100%;
}

@mixin disabled {
   opacity: 0.4;
   cursor: not-allowed;
   pointer-events: none;
}

@mixin font-secondary {
   font-family: "Open Sans", sans-serif;
}

@mixin font-primary {
   font-family: "Roboto", sans-serif;
}

@mixin grid-layout {
   display: grid;
   min-width: 0;
   min-height: 0;
}

// Flexible width calculations for content tiles
@mixin flexy($disp: flex, $dir: null, $wrap: null) {
   display: $disp;
   flex-direction: $dir;
   flex-wrap: $wrap;
}

// Responsive screen size mixins
@mixin small-screen {
   @media (max-width: #{$small-width}) {
      @content;
   }
}

@mixin medium-screen {
   @media (max-width: #{$medium-width}) {
      @content;
   }
}

@mixin focus-border {
   @include border-color("focus");
   border: 2px solid inset;
   outline: none;
   transition: box-shadow 0.2s, border-color 0.2s ease-in-out;
}
