.modal-container {
   display: flex;
   flex-direction: column;
   height: 100%;
   flex: 1 1 100%;
   max-height: $dialog-max-height;

   .modal-header {
      @include background-color("content-tile");
      display: flex;
      justify-content: space-between;
      padding: $spacing-md 0;
      min-height: 50px;

      .modal-heading {
         @include color("text-primary");
         font-size: 1.5em;
         margin-right: 1em;
      }

      .modal-heading-buttons {
         display: flex;
         z-index: 2;

         a.help-icon {
            @include color("text-accent");
            margin-right: 5px;
         }

         a.close-icon {
            @include color("text-accent");

            &:hover {
               @include hover-transition;
               cursor: pointer;
               opacity: 0.8;
            }
         }
      }
   }

   .modal-confirmation-icon {
      @include color("text-accent");
      font-size: 4em;
      flex-shrink: 0;
      padding-right: 1em;
   }

   .modal-confirmation-header {
      display: flex;
      justify-content: center;
      padding: $spacing-md;
      font-size: 1em;
      grid-row: 1;
   }

   .modal-confirmation-content {
      display: flex;
      justify-content: space-between;
      padding: $spacing-md;
      font-size: 1em;
      align-items: center;

      .modal-confirmation-message {
         @include color("text-secondary");
         white-space: pre-line;
         padding-left: 10px;
      }

      .modal-heading-buttons {
         display: flex;
         z-index: 2;

         a.help-icon {
            @include color("text-accent");
            margin-right: 5px;
         }

         a.close-icon {
            @include color("text-accent");

            &:hover {
               @include hover-transition;
               cursor: pointer;
               opacity: 0.8;
            }
         }
      }
   }

   .modal-confirmation-footer {
      border-top: 1px solid #d5d5d5;
      display: flex;
      padding: $spacing-md;
      justify-content: flex-end;

      lcs-progress-button-ext {
         padding-left: 10px;
      }
   }

   >.modal-flex-container {
      padding: 0 $spacing-md;
      overflow-y: auto; // min-height: 0;

      &:first-child {
         width: 100%;
      }
   }

   .footer {
      @include background-color("ui-03");
      grid-row: 3;
   }

   form {
      flex: 1 1 auto;
   }

   lcs-footer {
      flex: 0 1 50px;
      min-height: 50px;
   }
}

.modal-flex-container {
   h5 {
      font-weight: $font-weight--semi-bold;
      @include color("text-secondary");
      @include border-color("border");
      font-family: "Roboto", sans-serif;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: $spacing-md 0;
      border-bottom: 1px solid;
   }
}

.info-header {
   border-bottom: 1px solid;
   @include border-color("border");
   display: flex;
   justify-content: space-between;
   align-items: center;

   h5 {
      font-weight: $font-weight--semi-bold;
      @include color("text-secondary");
      align-items: center;
      padding: $spacing-md 0;
      width: auto;
      flex-grow: 1;
      border: none;
      margin: 0;
   }

   lcs-information-overlay {
      i.material-icons {
         @include color("text-accent");
         align-self: center;
      }
   }
}

.error-dialog,
.ui-overlaypanel-content,
.overlay-panel-contents {
   .modal-header {
      .modal-heading {
         @include color("alert");
      }
   }

   .error-messages-container {
      @include color("text-primary");
      font-family: "Roboto", sans-serif;

      .error-message-info {
         font-size: 1.1em;
         margin: $spacing-sm 0;
         white-space: pre-line;
      }

      .view-more-link {
         padding-top: $spacing-md 0;
      }

      a {
         font-size: 1.1em;
         margin: $spacing-sm 0;
      }

      .error-message-info-details {
         height: 20em;
      }

      .error-message-list {
         @include color("alert");
         margin-left: 0;

         .error-message {
            font-size: 1em;
         }
      }
   }
}

// jquery-ui.structure.min.css override for primeng dialog v9.0.5
.ui-dialog,
.p-dialog {
   top: initial;
   left: initial;
}

.error-messages-container {
   max-width: 37em;
}