.form-element-container {
   @include flexy($wrap: wrap, $dir:row);
   justify-content: flex-start;
   align-content: flex-start;
   padding: $spacing-sm $spacing-sm $spacing-md $spacing-sm;
}

.flex-wrapper {
   @include flexy($wrap: wrap, $dir:row);
   padding: 0 $spacing-sm;
}

.flex {
   @include flexy($wrap: wrap, $dir:row);
   align-items: flex-start;
}

.form-element {
   padding: 0 $spacing-sm $spacing-sm $spacing-sm;
   width: $input-width;
   min-width: $input-width;
   &[class^="flex-"],
   &[class*=" flex-"] {
      width: auto;
   }
   @include small-screen {
      width: 100%;
   }
}

.form-header {
   flex: 1 1 100%;
   font-weight: $font-weight--bold;
   padding: $spacing-sm;
   display: flex;
   align-items: center;
   margin: 1.25em 0 0 0;
   &.no-margin {
      padding-top: $spacing-sm;
      margin-top: 0;
   }
}

.flex-space-between {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}
