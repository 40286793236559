//  Custom flex grid layout
.flex-twelfth {
   flex: per-line(12);

   @include medium-screen {
      flex: 1 1 per-line(3);
   }

   @include small-screen {
      flex: 1 1 per-line(2);
   }
}

.flex-sixth {
   flex: per-line(6);

   @include medium-screen {
      flex: 1 1 per-line(2);
   }

   @include small-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-fifth {
   flex: 1 per-line(5);

   @include medium-screen {
      flex: 1 1 per-line(3);
   }

   @include small-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-fourth {
   flex: 1 per-line(4);

   @include medium-screen {
      flex: 1 1 per-line(2);
   }

   @include small-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-third {
   flex: per-line(3);

   @include medium-screen {
      flex: 1 1 per-line(2);
   }

   @include small-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-half {
   flex: 1 per-line(2);

   @include medium-screen {
      flex: 1 1 per-line(2);
   }

   @include small-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-two-thirds {
   flex: per-line(1.5);

   @include medium-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-full {
   flex: per-line(1);
}

.flex-fill {
   flex: 1 auto;

   @include small-screen {
      flex: 1 1 per-line(1);
   }
}

.flex-center {
   @include flexy();
   align-items: center;
}

.flex-start {
   @include flexy($wrap: wrap);
   justify-content: flex-start;
}

.flex-end {
   @include flexy();
   justify-content: flex-end;
}

.flex-space-between {
   @include flexy($wrap: wrap);
   justify-content: space-between;
}

.flex-space-between-nowrap {
   @include flexy($wrap: nowrap);
   justify-content: space-between;
}

.justify-content-space-between {
   justify-content: space-between;
}

.align-center {
   align-self: center;
   align-content: center;
   align-items: center;
}

.nowrap {
   flex-wrap: nowrap;
}

.flex-no-shrink {
   flex-shrink: 0;
}

.flex-no-grow {
   flex-grow: 0;
}
