* {
   box-sizing: border-box;
}

/* .signableDocumentsFooter button,
.signableDocumentsFooter .button,
#popup-sign-main button,
#popup-input-main button {
   background: none !important;
} */

owa-signable-documents-sign * {
   box-sizing: content-box;
}

#PaginationContainer {
   font-size: 14px;
   font-weight: normal;
   color: #666666;
   display: flex;
   align-items: center;
}

#PageCount {
   margin-left: 0.5em;
}

#mSignBtn {
   color: #2c77bc;
   text-align: center;
   height: 40px;
   width: auto;
   display: inline-flex;
   align-items: center;
   padding: 0 1em;
   margin: 0 1em;
   border: 1px solid #d5d5d5;
   border-radius: 3px;
   flex-grow: 0;
   text-align: left;
   flex-shrink: 0;
   font-weight: 600;
   background: none;
}

#mSignBtn i {
   font-size: 1.5em;
   margin-right: 0.5em;
   background: none;
}

#DocumentSigningWizard-LoadOverlay {
   height: 1200px;
}

.popup-sign-validation-message,
.popup-initial-validation-message {
   color: #eb343c;
}

.leaseMain {
   /* background-color: #fff;*/
   font-family: Arial, Helvetica, sans-serif;
   /*background-color: #ccc;*/
   /*border-radius:7px;*/
   width : 816px;
   padding: 0px;
   margin: 0px auto 170px auto;
   box-sizing: content-box !important;
}

#LeaseWrapper {
   position: relative;
   /*height: 900px;*/
}

#lease {
   /*height: 900px;*/
   box-shadow: 0px 0px 10px #bababa;
}

.pagingArrow {
   cursor: pointer;
}

.signOverlay {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   font-size: 24px;
   text-align: center;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   -o-transition: 0.5s;
   -ms-transition: 0.5s;
   -moz-transition: 0.5s;
   -webkit-transition: 0.5s;
   border-style: solid;
   border-color: gray;
   border-width: 2px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.transparentOverlay {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   background-color: transparent;
}

.transparentOverlay.focused {
   position: absolute;
   z-index: 14;
   cursor: pointer;
   background-color: transparent;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 15px #68a844;
   border-style: solid;
   border-color: #68a844;
   border-width: 2px;
   border-radius: 3px;
}

.signOverlay.focused {
   position: absolute;
   z-index: 14;
   padding: 3px;
   cursor: pointer;
   text-align: left;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 15px #68a844;
   border-style: solid;
   border-color: #68a844;
   border-width: 2px;
   border-radius: 3px;
}

.signOverlay.Medium {
   font-size: 18px;
}

.signOverlay.Small {
   font-size: 12px;
}

.signOverlay.Waiting {
   background-color: white;
   -webkit-box-shadow: none;
   -ms-box-shadow: none;
   box-shadow: none;
   cursor: default;
}

.signOverlay:hover:not(.Waiting) {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   font-size: 24px;
   text-align: right;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   border-style: solid;
   border-color: gray;
   border-width: 2px;
}

.fillOverlay {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   -o-transition: 0.5s;
   -ms-transition: 0.5s;
   -moz-transition: 0.5s;
   -webkit-transition: 0.5s;
   border-style: solid;
   border-color: gray;
   border-width: 2px;
   text-align: left;
}

.fillOverlay.required {
   border-color: #076dab;
}

.checkBox {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-left: 0px !important;
}

.textBox {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.multiLineTextBox {
   overflow: hidden;
   position: relative;
   font-family: sans-serif;
   line-height: 1.2;
   padding-top: 2px;
   padding-left: 4px !important;
   padding-right: 4px;
   box-sizing: border-box;
   font-weight: normal;
}

.fieldPadding {
   padding-left: 1px;
}

.verticalalign {
   vertical-align: bottom;
   display: table-cell;
}

.fillOverlay.focused {
   position: absolute;
   z-index: 14;
   padding: 3px;
   cursor: pointer;
   text-align: left;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 15px #68a844;
   border-style: solid;
   border-color: #68a844;
   border-width: 2px;
   border-radius: 3px;
}

.fillOverlay:hover:not(.Waiting) {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   text-align: left;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   border-style: solid;
   border-color: gray;
   border-width: 2px;
}

.initialOverlay {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   background-color: #f6f99f;
   background-image: url("/content/images/pen-initial.png");
   background-size: cover;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.19);
   -o-transition: 0.5s;
   -ms-transition: 0.5s;
   -moz-transition: 0.5s;
   -webkit-transition: 0.5s;
   border-style: solid;
   border-color: gray;
   border-width: 2px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.initialOverlay.focused {
   position: absolute;
   z-index: 14;
   padding: 3px;
   cursor: pointer;
   text-align: left;
   background-color: #f6f99f;
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 15px #68a844;
   border-style: solid;
   border-color: #68a844;
   border-width: 2px;
   border-radius: 3px;
}

.initialOverlay.Waiting {
   background-color: white;
   cursor: default;
   -webkit-box-shadow: none;
   -ms-box-shadow: none;
   box-shadow: none;
}

.initialOverlay:hover:not(.Waiting) {
   position: absolute;
   z-index: 10;
   cursor: pointer;
   background-color: #f6f99f;
   background-image: url("/content/images/pen-initial.png");
   -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
   border-style: solid;
   border-color: gray;
   border-width: 2px;
}

.initialOverlay:hover .initial-placeholder-container {
   display: none;
}

.SignatureFlag {
   position: absolute;
   left: -110px;
   z-index: 9;
   border: 2px solid #aaa;
   width: 80px;
   height: 30px;
   background-color: yellow;
   box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.1);
   float: left;
   border-right: none;
   display: table;
   padding-left: 10px;
}

.SignatureFlag.focused {
   background-color: #f6f99f;
   border-color: #68a844;
}

.SignatureFlag.focused:before {
   border-left-color: #68a844;
}

.SignatureFlag.focused:after {
   border-left-color: #f6f99f;
}

.SignatureFlag:after,
.SignatureFlag:before {
   left: 100%;
   top: 50%;
   border: solid transparent;
   content: " ";
   height: 0;
   width: 0;
   position: absolute;
   pointer-events: none;
}

.SignatureFlag:after {
   border-color: rgba(136, 183, 213, 0);
   border-left-color: yellow;
   border-width: 15px;
   margin-top: -15px;
}

.SignatureFlag:before {
   border-color: rgba(194, 225, 245, 0);
   border-left-color: #aaa;
   border-width: 17px;
   margin-top: -17px;
}

.flagText {
   display: table-cell;
   vertical-align: middle;
   box-sizing: content-box;
}

.FieldFlag {
   position: absolute;
   left: -22px;
   z-index: 9;
   border: 2px solid #aaa;
   width: 0;
   height: 15px;
   background-color: yellow;
   box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.1);
   float: left;
   border-right: none;
   display: table;
   padding-left: 10px;
}

.FieldFlag.required {
   left: -65px;
   border-color: #076dab;
   z-index: 1000;
}

.FieldFlag.required:before {
   border-left-color: #076dab;
}

.FieldFlag.focused {
   background-color: #f6f99f;
   border-color: #68a844;
}

.FieldFlag.focused:before {
   border-left-color: #68a844;
}

.FieldFlag.focused:after {
   border-left-color: #f6f99f;
}

.FieldFlag:after,
.FieldFlag:before {
   left: 100%;
   top: 50%;
   border: solid transparent;
   content: " ";
   height: 0;
   width: 0;
   position: absolute;
   pointer-events: none;
}

.FieldFlag:after {
   border-color: rgba(136, 183, 213, 0);
   border-left-color: yellow;
   border-width: 7.5px;
   margin-top: -7.5px;
}

.FieldFlag:before {
   border-color: rgba(194, 225, 245, 0);
   border-left-color: #aaa;
   border-width: 9.5px;
   margin-top: -9.5px;
}

.initial-placeholder-container {
   font-size: 16px;
}

.initial-placeholder-container.Small {
   font-size: 8px;
}

#previous-signature {
   font-size: 38px;
   display: inline;
}

#previous-initial {
   font-size: 38px;
   display: inline;
}

#PageImage {
   width: 100%;
}

.signableDocumentsFooter {
   height: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0px auto;
   background-color: white;
   position: fixed;
   bottom: 0;
   z-index: 1000;
   width: 100%;
   border-top: 1px solid #d5d5d5;
}

.signableDocumentsFooter h2 {
   display: inline-flex;
   line-height: 38px;
   white-space: pre;
   margin: 0;
}

.signableDocumentsFooter div#SignatureProgress {
   width: 440px;
   float: left;
}

.signableDocumentsFooter #paginationDrop {
   white-space: pre-line;
   margin: 0 0.5em;
}

.signableDocumentsFooter #mFinishBtn {
   line-height: 0em;
   text-align: center;
   display: inline;
   color: white !important;
}

#paginationDrop .button {
   background: none;
}

.mobile-app-icon-bar {
   margin: 0px auto;
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 100%;
   max-width: 800px;
   color: #2c77bc;
   height: 60px;
   box-sizing: border-box;
}

.mobile-app-icon-bar button {
   padding: 1.25em;
   cursor: pointer;
   border: none;
   color: #2c77bc;
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

#mNextBtn,
#btnNext,
#btnSignNext {
   flex-direction: row-reverse;
   background: none;
}

#mNextBtn:hover,
#mPrevBtn:hover {
   opacity: 0.7;
   transition: all 0.2s ease-in-out;
}

#mNextBtn i,
#btnNext i,
#btnSignNext i {
   margin-left: 0.5em;
}

#mPrevBtn i,
#btnPrevious i,
#btnPrevSign i {
   margin-right: 0.5em;
}

#mPrevBtn,
#btnPrevious,
#btnPrevSign,
#btnSignNext,
#btnStartSigning {
   background: none;
}

#btnStartSigning {
   justify-content: flex-end;
}

#btnStartSigning i {
   margin-right: 0.5em;
}

#btnOk,
#btnSave,
#btnSign,
#btnFinish {
   justify-content: center;
   font-weight: 600;
   width: auto;
   color: #fff;
   padding: 0.75em;
}

#btnConfirm,
#btnCancel {
   justify-content: center;
   font-weight: 600;
   color: #fff;
}

#enter-new-signature {
   margin-top: 10px;
}

#btnCancel i {
   margin-right: 4px;
   font-size: 1em;
}

.mobile-app-icon-bar button.disabled {
   /* opacity: 0.25; */
   cursor: not-allowed;
}

.mobile-app-icon-bar button svg,
.mobile-app-icon-bar button i,
.mobile-app-icon-bar button img {
   font-size: 2em;
}

.leasePageFooterButton.prvBtn {
   float: left;
}

.leasePageButton.nxtBtn {
   float: right;
   background: none;
}

.clearFloat {
   clear: both;
}

.popup-completed-main {
   background-color: white;
   position: absolute;
   padding: 20px;
   z-index: 30;
   line-height: 1.45;
}

#popup-completed-main {
   line-height: 1.45;
}

.popup-sign-main {
   background-color: white;
   position: absolute;
   padding: 20px;
   z-index: 30;
}

.popup-sign-main input[type="text"] {
   height: 75px;
   font-family: "Lato", sans-serif;
   font-size: 25px;
}

.popup-sign-main #initial-input {
   height: 25px !important;
}

.no-close .ui-dialog-titlebar-close {
   display: none !important;
}

.default-input-pane {
   min-height: 100px;
}

.dialog-info-pane {
   padding: 10px 0px 5px 0px;
   border-bottom: 1px solid gray;
}

.nav-button-container {
   padding-top: 10px;
}

.popup-input-dialog .ui-dialog-titlebar,
.popup-sign-dialog .ui-dialog-titlebar,
.no-titlebar-dialog .ui-dialog-titlebar {
   background: none;
   border: none;
}

.popup-sign-dialog .ui-dialog-buttonset {
   float: none !important;
   display: flex;
   justify-content: space-between;
}

.requiredFieldButton {
   margin-left: auto !important;
}

#sign-dialog-close-button,
#field-dialog-close-button {
   float: right;
   font-size: 25px;
   cursor: pointer;
   color: gray;
   padding: 0;
   top: 0;
   right: 0;
}

#DocumentSigningWizard-LoadOverlay > .pageWrap > img{
   height: 60px;
}

.close-button {
   position: absolute;
   color: #666666;
   right: 14px;
   top: 5px;
   font-size: 2em;
   line-height: 1;
   cursor: pointer;
   text-align: right;
   background: none !important;
   width: auto;
   padding: 0;
}

#requiredLabel {
   color: #eb343c;
   float: right;
   font-size: 13px;
}

#SignatureProgress {
   text-align: center;
   padding-bottom: 10px;
}

#SignatureProgress h2 {
   /*color: #327E04;*/
   font-weight: bold;
   font-size: 15px;
   margin: 0 0 5px 0;
}

.pageCompleted {
   color: #6db844;
   font-family: FontAwesome;
}

.pageIncomplete {
   color: #666666;
   font-family: FontAwesome;
}

.startSigningButton {
   min-width: 95px;
}

#remainningRequiredFields {
   max-height: 96px;
   border: 1px solid #e5e5e5;
   border-radius: 5px;
   padding: 1em;
   display: grid;
   white-space: nowrap;
   overflow-x: hidden;
   overflow-y: auto;
   margin: 0.5em 0;
}

#remainningRequiredFields a {
   text-decoration: underline;
   cursor: pointer;
}

#signatureDisableMessage .required {
   font-weight: bold;
   color: #2c77bc;
   line-height: 1.45;
}

.sm-fielddescription-label {
   padding-left: 25px;
   line-height: 1.45;
   font-size: 12px;
   max-width: 390px;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   /* margin: 0.5em 0; */
}

/* These styles compensate for conflicting styles in TWA */

.pageWrap {
   margin: 0;
   overflow: hidden;
}

.main {
   padding: 0;
   background-color: inherit;
}

#signature-input,
#initial-input {
   box-sizing: border-box;
}

#default-signature-pane input,
#default-initial-pane input {
   margin-top: 5px;
}

#MainContent {
   margin: 30px 0;
}

.dialog-buttons-container {
   border-top: solid 1px #ccc;
   margin-top: 30px;
   padding-top: 5px;
}

.dialog-desc-section {
   padding-bottom: 10px;
}

#input-position {
   font-size: 12px;
}

#input-position a {
   text-decoration: underline;
   color: #2c77bc;
}

#progress-section {
   font-size: 10px;
   float: right;
}

.textArea {
   resize: none;
   height: 75px;
}

.reveal input[type="text"],
.reveal input[type="date"],
.reveal input[type="text"],
.reveal input[type="number"],
.reveal select {
   display: block;
   min-width: initial;
   background-color: #f5f5f5;
   border: 1px solid #d5d5d5;
}

.reveal input[type="text"]:focus {
   border: 1px solid #2c77bc;
   background-color: #f5f5f5;
   -webkit-transition: all 0.3s ease-out;
   -moz-transition: all 0.3s ease-out;
   -ms-transition: all 0.3s ease-out;
   -o-transition: all 0.3s ease-out;
   transition: all 0.3s ease-out;
}

.reveal select {
   width: 100%;
   padding: 0.5em;
}

.reveal select option {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   padding: 0.5em;
}

.selectric-wrapper .button{
   min-width: 0px !important;
}

.dateField {
   position: relative;
   width: 100%;
}

.dateField .fa-calendar {
   position: absolute;
   right: 10px;
   top: 10px;
   color: #f28228;
}

@supports (-ms-ime-align:auto) {
   body {
      height: auto;
   }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
   /* Safari 5+ ONLY */
   ::i-block-chrome,
   .leaseMain {
      padding-bottom: 150px;
   }
}

@media not all and (min-resolution: 0.001dpcm) {
   @media {
      /*Safari 10.1+ only*/
      .leaseMain {
         padding-bottom: 150px;
      }
   }
}

@media screen and (min-width:816px) and (max-width:1500px) {
   .landscape .FieldFlag,
   .landscape .SignatureFlag {
      left: 0px !important;
      width: 0px;
      padding-left: 0px;
      margin-left: 10px;
   }
   .landscape .FieldFlag.required:after {
      border-left-color: #2c77bc;
   }
   .landscape .flagText {
      display: none;
   }
}

@media screen and (max-width:816px) {
   .leaseMain,
   #lease {
      width: 100%;
      overflow-y: hidden;
   }
   body {
      min-width: 100% !important;
   }
   .signableDocumentsFooter #PaginationContainer {
      display: none;
   }
   #DocumentSigningWizard-LoadOverlay {
      height: 100%;
   }

   #popup-input-main,
   #popup-sign-main {
      top: 0px !important;
   }
   .FieldFlag,
   .SignatureFlag {
      left: 0px !important;
      width: 0px;
      height: 15px;
      padding-left: 0px;
      margin-left: 10px;
   }
   .FieldFlag.required:after {
      border-left-color: #2c77bc;
   }
   .flagText {
      display: none;
   }
   .textArea {
      height: 150px;
   }
}

@media screen and (min-width: 40em) and (max-width: 51em) {
   .FieldFlag,
   .SignatureFlag {
      height: 20px;
   }
   .FieldFlag:after,
   .SignatureFlag:after {
      border-width: 9.5px;
      margin-top: -9.5px;
   }
   .FieldFlag:before,
   .SignatureFlag:before {
      border-width: 11.5px;
      margin-top: -11.5px;
   }
}

@media screen and (max-width:39.9375em) {
   .FieldFlag,
   .SignatureFlag {
      height: 10px;
      margin-left: 5px;
   }
   .FieldFlag:after,
   .SignatureFlag:after {
      border-width: 4.5px;
      margin-top: -4.5px;
   }
   .FieldFlag:before,
   .SignatureFlag:before {
      border-width: 6.5px;
      margin-top: -6.5px;
   }
}
