.verify-pending-container {
   background: #fff;
   box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
   width: 25rem;
   padding: 2.2rem 2.2rem 0rem 2.2rem;
   padding-bottom: 3rem;
   margin: 100px auto;
}

.verify-pending-button-container {
   padding-top: 1rem;
}

.verify-pending-button-container.right {
   float: right;
}

.verify-pending-message-row {
   display: flex;
   align-content: center;
   align-items: center;
   margin-top: 1em;
   margin-bottom: 1rem;
}

.verify-pending-controls {
   margin-top: 1.5em;
   margin-bottom: 1.5em;
   font-size: 0.9em;
   display: flex;
   justify-content: space-between;
}