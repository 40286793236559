@mixin ellipsis ($max-height, $lines: 2) {
   // Fallback for non-webkit browsers.
   // Fallback does not render ellipsis.
   overflow: hidden;
   max-height: $max-height;
    
   // Webkit solution for multiline ellipsis
    display: -webkit-box;
   -webkit-box-orient: vertical !important;
   -webkit-line-clamp: $lines;  
   
   // Solution for Opera
   text-overflow: -o-ellipsis-lastline;
 }

 @mixin sticky {
   position: sticky;
   top: 0;
}