lcs-sidebar-menu {
   .sidebar-menu-overlay {
      position: fixed;
      z-index: $full-menu-z-index;
      width: 100%;
      height: 100%;
      background-color: rgba(128, 128, 128, 0.6);
      .sidebar-menu-scroller {
         @extend .ios-scrollable;
         overflow-y: scroll;
         overflow-x: hidden;
         height: 100%;
         @include small-screen {
            width: 100% !important;
         }
      }
   }
   .sidebar-menu {
      @include background("menu");
      @include color("text-primary");
      @include small-screen {
         width: calc(100% + 16px) !important;
      }
      height: 100%;
      bottom: 0px;
      overflow-x: hidden;
      position: relative;
      transition: all $layout-animation-transition-time ease-out;
      &.visible {
         transition: all $layout-animation-transition-time ease-in;
      }
      ul {
         @include background-color("menu");
         list-style: none;
         margin-left: 0;
         margin-bottom: 0;
         overflow-x: hidden;
      }
      li {
         box-sizing: border-box;
         text-transform: initial;
         width: 20em;
         @include small-screen {
            width: 100% !important;
         }
         a {
            @include border-color("menu-border");
            @include font-primary;
            @include color("menu-link");
            display: flex;
            align-items: center;
            height: $menu-item-height;
            padding: 0 $spacing-lg;
            border-bottom: 1px solid;
            justify-content: space-between;
            > div {
               width: 15em;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
               height: 100%;
               line-height: $menu-item-height;
               @include small-screen {
                  width: 100%;
               }
            }
            &:hover {
               @include background("menu-hover");
               @include color("menu-hover-link");
               transition: all 0.3s ease-out;
               text-decoration: none;
               &.icon::before {
                  @include color("menu-link");
               }
               &.icon::after {
                  @include color("menu-link");
               }
            }
         }
         &.sidebar-menu-page-header {
            @include background("menu");
            @include color("menu-header");
            font-size: em(20px);
            font-weight: $font-weight--normal;
            display: flex;
            align-items: center;
            height: $menu-item-height;
            padding-left: $spacing-md;
            border-bottom: none;
         }
         &.sidebar-menu-page-back {
            a {
               justify-content: flex-start;
               @include background("menu-hover");
               text-transform: uppercase;
               padding-left: $spacing-md;
               letter-spacing: 1px;
            }
         }
      }
      .sidebar-menu-page {
         position: absolute;
         top: 0;
         bottom: 0;
         left: 100%;
         height: 100%;
         @include small-screen {
            width: calc(100% - 16px) !important;
         }
      }
   }
}

.sidebar-menu-tooltip {
   z-index: $full-menu-z-index !important;
}
