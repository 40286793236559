.checkbox-wrapper {
   user-select: none;

   &:not(.checkbox-disabled) {
      cursor: pointer;

      .checkbox-label {
         cursor: pointer;
      }
   }

   margin: $spacing-sm 0;

   .checkbox-label {
      margin: 0;
      display: inline;
      font-family: $font-family--primary;
   }
}

.checkbox-wrapper:focus-within {
   &:focus {
      @include focus-border;
   }

   label.checkbox-label:after {
      @include border-color("secondary");
   }
}

.toggleable-input-wrapper.toggleable-input-inline {
   display: flex;
   align-items: center;

   & .checkbox-wrapper {
      margin: 0;
   }

   &:disabled {
      +label {
         @include disabled;
      }
   }
}