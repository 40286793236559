.lcs-column-responsive-toggle .material-icons {
   @include color("link");
   background: none;
}

.lcs-group-cell {
   @include background-color("datatable-group");

   .group-header {
      font-weight: 600;
   }
}

.lcs-table-responsive-item-cell .lcs-cell {
   word-break: break-word !important;
   white-space: normal !important;
   text-overflow: inherit !important;
   text-align: left !important;
}

.row-group-wrapper {
   cursor: pointer;
   display: flex;
   align-items: center;

   .material-icons.row-group-icon {
      font-size: 2em;
      cursor: pointer;

      &.row-group-collapsed {
         -webkit-transform: rotate(-90deg);
         -moz-transform: rotate(-90deg);
         -ms-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
         transform: rotate(-90deg);
      }
   }
}

.table-wrapper {
   width: 100%;
   height: 100%;
   overflow-y: auto;
}

.lcs-table-responsive-item-cell {
   overflow: hidden;

   .lcs-cell.has-drilldown .drilldown-link {
      @include color("link");
   }
}

.lcs-table-responsive-item-header {
   .lcs-header-cell {
      background: none !important;
   }
}

.lcs-column-responsive-toggle .material-icons {
   display: flex;
}

.datatable-internal-spinner-wrapper {
   height: 80px;
   position: relative;
}

.lcs-cell {
   text-align: left;

   .link {
      &.email {
         text-align: right;
      }
   }

   &.numeric {
      text-align: right;
   }

   &.boolean,
   &.attachment {
      text-align: center;
   }
}

.lcs-table-responsive-item {
   .lcs-cell {
      text-align: right;

      &.link,
      &.link.email,
      &.numeric,
      &.boolean,
      &.attachment {
         text-align: right;
      }
   }
}
