#chatContent {
   z-index: 1000;
   position: relative;
   float: right;

   // position: fixed;
   // transform: rotate(-90deg);
   // top: 200px;
   // right: -30px;
   @include small-screen {
      position: relative;
      transform: rotate(0deg);
      top: 0;
      right: 0;
      width: 100%;
   }
}

.rmWebChatPlugin {
   display: flex;
   padding-bottom: 1em;

   input.rmWebChatQueueButton {
      @include background-color("secondary");
      @include font-secondary;
      color: #fff;
      font-weight: $font-weight--semi-bold;
      min-width: 0 !important;
      padding: $spacing-xs 1.25em;
      border-radius: 3px;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: $input-height;
      -webkit-appearance: button;

      &:hover {
         @include background-color("secondary-darken-5");
         @include hover-transition;
      }

      @include small-screen {
         border-radius: 3px;
         -webkit-appearance: button;
         margin-bottom: 5px;
      }
   }
}

.manage-conversations-error {
   width: 100%;
   background: blue;
}
