lcs-date-range-picker {
   border: 1px solid transparent;
   border-radius: 3px;

   &:focus-within,
   &:focus {
      @include border-color("focus");
   }
}

.date-range-wrapper {
   @include border-color("input-border");
   @include font-primary;
   border: 1px solid;
   border-radius: $border-radius;
   width: 100%;

   &.child-input-has-focus {
      box-shadow: none !important;

      .ui-calendar,
      .p-calendar {
         &:focus-within {
            box-shadow: none;
         }
      }
   }

   .date-range-date-picker-wrapper {
      flex: auto;
   }

   lcs-date-picker {
      border: none;
   }

   p-calendar {
      input[type="text"] {
         border: none;
         box-shadow: none;
      }

      button.ui-widget.ui-calendar-button,
      button.p-button {
         @include border-color("input-border");
         margin: 0;
         border-radius: 0;
         border-left: 1px solid;
         border-right: 1px solid;
         border-top: none;
         border-bottom: none;

         &:hover {
            @include border-color("input-border");
            margin: 0;
            border-radius: 0;
            border-left: 1px solid;
            border-right: 1px solid;
            border-top: none;
            border-bottom: none;

            .ui-button-icon-left,
            .ui-button-text,
            .p-button-icon {
               @include hover-transition;
               @include color("link");
            }
         }
      }
   }
}

.date-range-overlay-wrapper {
   @include background-color("input-background");
   padding: 0.5em;

   .date-range-button-grid {
      @include grid-layout;
      grid-template-columns: $input-height auto auto $input-height;
      grid-gap: 0.5em;
      user-select: none;
      -moz-user-select: none;
      border-radius: $border-radius;
      padding-bottom: 0.5em;

      .today {
         grid-column: span 2;
      }

      .date-range-button {
         height: $input-height;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   }
}

.date-range-open-overlay-icon {
   @include background-color("input-background");
   @include color("input-icon");
   font-family: "Material Icons";
   font-weight: normal;
   font-style: normal;
   font-size: 1.8em;
   user-select: none;
   -moz-user-select: none;

   &:hover {
      @include color("link");
   }

   &:focus {
      outline: none;
   }
}

.date-span-button,
.date-span-button-focused {
   height: $input-height;
   border-radius: 0px 3px 3px 0;
   width: 3em;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

.date-span-button {
   @include background-color("input-background");

   &:hover {
      border: none;
      @include background-color("filters-hamburger-menu");

      i {
         @include background-color("filters-hamburger-menu");
         @include color("link");
      }
   }
}

.date-span-button-focused {
   @include background-color("input-background");

   &:hover,
   &:focus {
      border: none;
      @include background-color("filters-hamburger-menu");

      i {
         @include color("link");
      }
   }
}

.date-range-button {
   @include color("link");
   @include border-color("input-border");
   cursor: pointer;
   font-size: 0.875em;
   font-weight: 600;
   border: 1px solid;
   border-radius: 3px;
   @include hover-transition;

   &:hover {
      @include background-color("input-background");
      @include color("text-primary");
   }

   &:focus,
   &:active {
      background-color: transparent;
   }
}

.ui-datepicker .ui-datepicker-title {
   @include color("text-primary");
}

.ui-datepicker.ui-widget .ui-datepicker-header {
   @include background-color("ui-background");
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active {
   @include background-color("primary");
   color: white;
}

.ui-datepicker .ui-datepicker-buttonbar {
   @include background-color("ui-background");
   @include color("text-primary");

   .ui-button {
      @include background-color("input-background");
      @include color("text-primary");
      @include border-color("input-border");

      &:hover {
         @include background-color("input-background");
      }
   }
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
   @include color("primary");
   background: transparent !important;

   &:hover {
      @include color("primary-lighten-5");
      background: transparent !important;
   }
}

.ui-datepicker .ui-datepicker-header a:hover {
   background: transparent !important;
}