body a:hover {
   color: inherit;
}

.ui-tooltip,
.ui-tooltip-right,
.ui-tooltip-bottom,
.ui-tooltip-left,
.ui-tooltip-top,
.p-tooltip,
.p-tooltip-right,
.p-tooltip-bottom,
.p-tooltip-left,
.p-tooltip-top {
   max-width: 50em;

   .ui-tooltip-text,
   .p-tooltip-text {
      @include background-color("reports-tooltip");
      @include color("text-primary-button-accent-color");
      color: $white;
      padding: $spacing-sm $spacing-md;
   }
}

.ui-tooltip.ui-tooltip-top .ui-tooltip-arrow,
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
   @include border-top-color("reports-tooltip");
   @include color("reports-tooltip");
}

.ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow,
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
   @include border-bottom-color("reports-tooltip");
   @include color("reports-tooltip");
}

.ui-tooltip.ui-tooltip-right .ui-tooltip-arrow,
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
   @include border-right-color("reports-tooltip");
   @include color("reports-tooltip");
}

.ui-tooltip.ui-tooltip-left .ui-tooltip-arrow,
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
   @include border-left-color("reports-tooltip");
   @include color("reports-tooltip");
}

.ui-widget-content a,
.p-component a {
   @include color("primary");
}

body .ui-widget,
body .p-component {
   font-size: inherit;
   font-family: $font-family--primary;
}

/* Icons */

.ui-paginator,
.p-paginator {
   .ui-paginator-last,
   .ui-paginator-prev,
   .ui-paginator-next,
   .ui-paginator-first,
   .p-paginator-last,
   .p-paginator-prev,
   .p-paginator-next,
   .p-paginator-first {
   padding: 0;
   vertical-align: middle;
   text-indent: 0;
}
}

.material-icons,
.fa,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-prev,
.ui-paginator .ui-paginator-next,
.ui-paginator .ui-paginator-first,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-first {
   font-family: "Material Icons";
   font-weight: normal;
   font-style: normal;
   font-size: 1.5em;
   display: inline-block;
   width: $spacing-md;
   height: $spacing-md;
   line-height: 1;
   text-transform: none;
   letter-spacing: normal;
   word-wrap: normal;
   white-space: nowrap;
   direction: ltr;
   -webkit-font-smoothing: antialiased;
   text-rendering: optimizeLegibility;
   -moz-osx-font-smoothing: grayscale;
   font-feature-settings: "liga";
}

.ui-widget .material-icons {
   font-family: "Material Icons";
   font-size: 1.5em;
}

.ui-icon-carat-2-n-s:before {
   content: "sort";
}

.ui-icon-triangle-1-n:before {
   content: "keyboard_arrow_up";
}

.ui-icon-triangle-1-e:before {
   content: "keyboard_arrow_right";
}

.ui-icon-triangle-1-s:before {
   content: "keyboard_arrow_down";
}

.ui-icon-triangle-1-w:before {
   content: "keyboard_arrow_left";
}

.ui-icon-carat-1-n:before {
   content: "keyboard_arrow_up";
}

.ui-icon-carat-1-e:before {
   content: "keyboard_arrow_right";
}

.ui-icon-carat-1-s:before {
   content: "keyboard_arrow_down";
}

.ui-icon-carat-1-w:before {
   content: "keyboard_arrow_left";
}

.ui-icon-arrow-1-n {
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   transform: rotate(-90deg);
}

.ui-icon-arrow-1-n:before {
   content: "play_arrow";
}

.ui-icon-arrowstop-1-n {
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   transform: rotate(-90deg);
}

.ui-icon-arrowstop-1-n:before {
   content: "skip_next";
}

.ui-icon-arrow-1-s {
   -webkit-transform: rotate(90deg);
   -moz-transform: rotate(90deg);
   -o-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   transform: rotate(90deg);
}

.ui-icon-arrow-1-s:before {
   content: "play_arrow";
}

.ui-icon-arrowstop-1-s {
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   transform: rotate(-90deg);
}

.ui-icon-arrowstop-1-s:before {
   content: "skip_previous";
}

.ui-icon-arrow-1-w {
   -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
   -o-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   transform: rotate(180deg);
}

.ui-icon-arrow-1-w:before {
   content: "play_arrow";
}

.ui-icon-arrowstop-1-w:before {
   content: "fast_rewind";
}

.ui-icon-arrow-1-e:before {
   content: "play_arrow";
}

.ui-icon-arrowstop-1-e:before {
   content: "fast_forward";
}

.ui-icon-arrowthick-1-s {
   -webkit-transform: rotate(90deg);
   -moz-transform: rotate(90deg);
   -o-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   transform: rotate(90deg);
}

.ui-icon-arrowthick-1-s:before {
   content: "play_arrow";
}

.ui-icon-arrowthick-1-n {
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   transform: rotate(-90deg);
}

.ui-icon-arrowthick-1-n:before {
   content: "play_arrow";
}

.ui-icon-circle-triangle-e:before {
   content: "play_circle_outline";
}

.ui-icon-circle-triangle-w {
   -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
   -o-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   transform: rotate(180deg);
}

.ui-icon-circle-triangle-w:before {
   content: "play_circle_outline";
}

.ui-icon-circle-triangle-s {
   -webkit-transform: rotate(90deg);
   -moz-transform: rotate(90deg);
   -o-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   transform: rotate(90deg);
}

.ui-icon-circle-triangle-s:before {
   content: "play_circle_outline";
}

.ui-icon-radio-off:before {
   content: "radio_button_unchecked";
}

.ui-icon-radio-on:before {
   content: "radio_button_checked";
}

.ui-icon-folder-collapsed:before {
   content: "folder";
}

.ui-icon-document:before {
   content: "content_paste";
}

.ui-icon-video:before {
   content: "movie";
}

.ui-icon-music:before {
   content: "music_note";
}

.ui-icon-plus:before {
   content: "add";
}

.ui-icon-minus:before {
   content: "remove";
}

.ui-icon-plusthick:before {
   content: "add";
}

.ui-icon-minusthick:before {
   content: "remove";
}

.ui-icon-pencil:before {
   content: "edit";
}

.ui-icon-closethick:before {
   content: "close";
}

.ui-icon-circle-close:before {
   content: "cancel";
}

.ui-icon-gear:before {
   content: "settings";
}

.ui-icon-calendar:before {
   content: "date_range";
}

.ui-icon-trash:before {
   content: "delete_forever";
}

.ui-icon-notice:before {
   content: "error_outline";
}

.ui-icon-alert:before {
   content: "warning";
}

.ui-icon-circle-zoomin:before {
   content: "zoom_in";
}

.ui-icon-circle-zoomout:before {
   content: "zoom_out";
}

.ui-icon-circle-arrow-e:before {
   content: "play_circle_outline";
}

.ui-icon-circle-arrow-w {
   -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
   -o-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   transform: rotate(180deg);
}

.ui-icon-circle-arrow-w:before {
   content: "play_circle_outline";
}

.ui-icon-3d-rotation:before {
   content: "3d_rotation";
}

.ui-icon-ac-unit:before {
   content: "ac_unit";
}

.ui-icon-access-alarm:before {
   content: "access_alarm";
}

.ui-icon-access-alarms:before {
   content: "access_alarms";
}

.ui-icon-access-time:before {
   content: "access_time";
}

.ui-icon-accessibility:before {
   content: "accessibility";
}

.ui-icon-accessible:before {
   content: "accessible";
}

.ui-icon-account-balance:before {
   content: "account_balance";
}

.ui-icon-account-balance-wallet:before {
   content: "account_balance_wallet";
}

.ui-icon-account-box:before {
   content: "account_box";
}

.ui-icon-account-circle:before {
   content: "account_circle";
}

.ui-icon-add:before {
   content: "add";
}

.ui-icon-add-a-photo:before {
   content: "add_a_photo";
}

.ui-icon-add-alarm:before {
   content: "add_alarm";
}

.ui-icon-add-alert:before {
   content: "add_alert";
}

.ui-icon-add-box:before {
   content: "add_box";
}

.ui-icon-add-circle:before {
   content: "add_circle";
}

.ui-icon-add-circle-outline:before {
   content: "add_circle_outline";
}

.ui-icon-add-location:before {
   content: "add_location";
}

.ui-icon-add-shopping-cart:before {
   content: "add_shopping_cart";
}

.ui-icon-add-to-photos:before {
   content: "add_to_photos";
}

.ui-icon-add-to-queue:before {
   content: "add_to_queue";
}

.ui-icon-adjust:before {
   content: "adjust";
}

.ui-icon-airline-seat-flat:before {
   content: "airline_seat_flat";
}

.ui-icon-airline-seat-flat-angled:before {
   content: "airline_seat_flat_angled";
}

.ui-icon-airline-seat-individual-suite:before {
   content: "airline_seat_individual_suite";
}

.ui-icon-airline-seat-legroom-extra:before {
   content: "airline_seat_legroom_extra";
}

.ui-icon-airline-seat-legroom-normal:before {
   content: "airline_seat_legroom_normal";
}

.ui-icon-airline-seat-legroom-reduced:before {
   content: "airline_seat_legroom_reduced";
}

.ui-icon-airline-seat-recline-extra:before {
   content: "airline_seat_recline_extra";
}

.ui-icon-airline-seat-recline-normal:before {
   content: "airline_seat_recline_normal";
}

.ui-icon-airplanemode-active:before {
   content: "airplanemode_active";
}

.ui-icon-airplanemode-inactive:before {
   content: "airplanemode_inactive";
}

.ui-icon-airplay:before {
   content: "airplay";
}

.ui-icon-airport-shuttle:before {
   content: "airport_shuttle";
}

.ui-icon-alarm:before {
   content: "alarm";
}

.ui-icon-alarm-add:before {
   content: "alarm_add";
}

.ui-icon-alarm-off:before {
   content: "alarm_off";
}

.ui-icon-alarm-on:before {
   content: "alarm_on";
}

.ui-icon-album:before {
   content: "album";
}

.ui-icon-all-inclusive:before {
   content: "all_inclusive";
}

.ui-icon-all-out:before {
   content: "all_out";
}

.ui-icon-android:before {
   content: "android";
}

.ui-icon-announcement:before {
   content: "announcement";
}

.ui-icon-apps:before {
   content: "apps";
}

.ui-icon-archive:before {
   content: "archive";
}

.ui-icon-arrow-back:before {
   content: "arrow_back";
}

.ui-icon-arrow-downward:before {
   content: "arrow_downward";
}

.ui-icon-arrow-drop-down:before {
   content: "arrow_drop_down";
}

.ui-icon-arrow-drop-down-circle:before {
   content: "arrow_drop_down_circle";
}

.ui-icon-arrow-drop-up:before {
   content: "arrow_drop_up";
}

.ui-icon-arrow-forward:before {
   content: "arrow_forward";
}

.ui-icon-arrow-upward:before {
   content: "arrow_upward";
}

.ui-icon-art-track:before {
   content: "art_track";
}

.ui-icon-aspect-ratio:before {
   content: "aspect_ratio";
}

.ui-icon-assessment:before {
   content: "assessment";
}

.ui-icon-assignment:before {
   content: "assignment";
}

.ui-icon-assignment-ind:before {
   content: "assignment_ind";
}

.ui-icon-assignment-late:before {
   content: "assignment_late";
}

.ui-icon-assignment-return:before {
   content: "assignment_return";
}

.ui-icon-assignment-returned:before {
   content: "assignment_returned";
}

.ui-icon-assignment-turned-in:before {
   content: "assignment_turned_in";
}

.ui-icon-assistant:before {
   content: "assistant";
}

.ui-icon-assistant-photo:before {
   content: "assistant_photo";
}

.ui-icon-attach-file:before {
   content: "attach_file";
}

.ui-icon-attach-money:before {
   content: "attach_money";
}

.ui-icon-attachment:before {
   content: "attachment";
}

.ui-icon-audiotrack:before {
   content: "audiotrack";
}

.ui-icon-autorenew:before {
   content: "autorenew";
}

.ui-icon-av-timer:before {
   content: "av_timer";
}

.ui-icon-backspace:before {
   content: "backspace";
}

.ui-icon-backup:before {
   content: "backup";
}

.ui-icon-battery-alert:before {
   content: "battery_alert";
}

.ui-icon-battery-charging-full:before {
   content: "battery_charging_full";
}

.ui-icon-battery-full:before {
   content: "battery_full";
}

.ui-icon-battery-std:before {
   content: "battery_std";
}

.ui-icon-battery-unknown:before {
   content: "battery_unknown";
}

.ui-icon-beach-access:before {
   content: "beach_access";
}

.ui-icon-beenhere:before {
   content: "beenhere";
}

.ui-icon-block:before {
   content: "block";
}

.ui-icon-bluetooth:before {
   content: "bluetooth";
}

.ui-icon-bluetooth-audio:before {
   content: "bluetooth_audio";
}

.ui-icon-bluetooth-connected:before {
   content: "bluetooth_connected";
}

.ui-icon-bluetooth-disabled:before {
   content: "bluetooth_disabled";
}

.ui-icon-bluetooth-searching:before {
   content: "bluetooth_searching";
}

.ui-icon-blur-circular:before {
   content: "blur_circular";
}

.ui-icon-blur-linear:before {
   content: "blur_linear";
}

.ui-icon-blur-off:before {
   content: "blur_off";
}

.ui-icon-blur-on:before {
   content: "blur_on";
}

.ui-icon-book:before {
   content: "book";
}

.ui-icon-bookmark:before {
   content: "bookmark";
}

.ui-icon-bookmark-border:before {
   content: "bookmark_border";
}

.ui-icon-border-all:before {
   content: "border_all";
}

.ui-icon-border-bottom:before {
   content: "border_bottom";
}

.ui-icon-border-clear:before {
   content: "border_clear";
}

.ui-icon-border-color:before {
   content: "border_color";
}

.ui-icon-border-horizontal:before {
   content: "border_horizontal";
}

.ui-icon-border-inner:before {
   content: "border_inner";
}

.ui-icon-border-left:before {
   content: "border_left";
}

.ui-icon-border-outer:before {
   content: "border_outer";
}

.ui-icon-border-right:before {
   content: "border_right";
}

.ui-icon-border-style:before {
   content: "border_style";
}

.ui-icon-border-top:before {
   content: "border_top";
}

.ui-icon-border-vertical:before {
   content: "border_vertical";
}

.ui-icon-branding-watermark:before {
   content: "branding_watermark";
}

.ui-icon-brightness-1:before {
   content: "brightness_1";
}

.ui-icon-brightness-2:before {
   content: "brightness_2";
}

.ui-icon-brightness-3:before {
   content: "brightness_3";
}

.ui-icon-brightness-4:before {
   content: "brightness_4";
}

.ui-icon-brightness-5:before {
   content: "brightness_5";
}

.ui-icon-brightness-6:before {
   content: "brightness_6";
}

.ui-icon-brightness-7:before {
   content: "brightness_7";
}

.ui-icon-brightness-auto:before {
   content: "brightness_auto";
}

.ui-icon-brightness-high:before {
   content: "brightness_high";
}

.ui-icon-brightness-low:before {
   content: "brightness_low";
}

.ui-icon-brightness-medium:before {
   content: "brightness_medium";
}

.ui-icon-broken-image:before {
   content: "broken_image";
}

.ui-icon-brush:before {
   content: "brush";
}

.ui-icon-bubble-chart:before {
   content: "bubble_chart";
}

.ui-icon-bug-report:before {
   content: "bug_report";
}

.ui-icon-build:before {
   content: "build";
}

.ui-icon-burst-mode:before {
   content: "burst_mode";
}

.ui-icon-business:before {
   content: "business";
}

.ui-icon-business-center:before {
   content: "business_center";
}

.ui-icon-cached:before {
   content: "cached";
}

.ui-icon-cake:before {
   content: "cake";
}

.ui-icon-call:before {
   content: "call";
}

.ui-icon-call-end:before {
   content: "call_end";
}

.ui-icon-call-made:before {
   content: "call_made";
}

.ui-icon-call-merge:before {
   content: "call_merge";
}

.ui-icon-call-missed:before {
   content: "call_missed";
}

.ui-icon-call-missed-outgoing:before {
   content: "call_missed_outgoing";
}

.ui-icon-call-received:before {
   content: "call_received";
}

.ui-icon-call-split:before {
   content: "call_split";
}

.ui-icon-call-to-action:before {
   content: "call_to_action";
}

.ui-icon-camera:before {
   content: "camera";
}

.ui-icon-camera-alt:before {
   content: "camera_alt";
}

.ui-icon-camera-enhance:before {
   content: "camera_enhance";
}

.ui-icon-camera-front:before {
   content: "camera_front";
}

.ui-icon-camera-rear:before {
   content: "camera_rear";
}

.ui-icon-camera-roll:before {
   content: "camera_roll";
}

.ui-icon-cancel:before {
   content: "cancel";
}

.ui-icon-card-giftcard:before {
   content: "card_giftcard";
}

.ui-icon-card-membership:before {
   content: "card_membership";
}

.ui-icon-card-travel:before {
   content: "card_travel";
}

.ui-icon-casino:before {
   content: "casino";
}

.ui-icon-cast:before {
   content: "cast";
}

.ui-icon-cast-connected:before {
   content: "cast_connected";
}

.ui-icon-center-focus-strong:before {
   content: "center_focus_strong";
}

.ui-icon-center-focus-weak:before {
   content: "center_focus_weak";
}

.ui-icon-change-history:before {
   content: "change_history";
}

.ui-icon-chat:before {
   content: "chat";
}

.ui-icon-chat-bubble:before {
   content: "chat_bubble";
}

.ui-icon-chat-bubble-outline:before {
   content: "chat_bubble_outline";
}

.ui-icon-check:before {
   content: "check";
}

.ui-icon-check-box:before {
   content: "check_box";
}

.ui-icon-check-box-outline-blank:before {
   content: "check_box_outline_blank";
}

.ui-icon-check-circle:before {
   content: "check_circle";
}

.ui-icon-chevron-left:before {
   content: "chevron_left";
}

.ui-icon-chevron-right:before {
   content: "chevron_right";
}

.ui-icon-child-care:before {
   content: "child_care";
}

.ui-icon-child-friendly:before {
   content: "child_friendly";
}

.ui-icon-chrome-reader-mode:before {
   content: "chrome_reader_mode";
}

.ui-icon-class:before {
   content: "class";
}

.ui-icon-clear:before {
   content: "clear";
}

.ui-icon-clear-all:before {
   content: "clear_all";
}

.ui-icon-close:before {
   content: "close";
}

.ui-icon-closed-caption:before {
   content: "closed_caption";
}

.ui-icon-cloud:before {
   content: "cloud";
}

.ui-icon-cloud-circle:before {
   content: "cloud_circle";
}

.ui-icon-cloud-done:before {
   content: "cloud_done";
}

.ui-icon-cloud-download:before {
   content: "cloud_download";
}

.ui-icon-cloud-off:before {
   content: "cloud_off";
}

.ui-icon-cloud-queue:before {
   content: "cloud_queue";
}

.ui-icon-cloud-upload:before {
   content: "cloud_upload";
}

.ui-icon-code:before {
   content: "code";
}

.ui-icon-collections:before {
   content: "collections";
}

.ui-icon-collections-bookmark:before {
   content: "collections_bookmark";
}

.ui-icon-color-lens:before {
   content: "color_lens";
}

.ui-icon-colorize:before {
   content: "colorize";
}

.ui-icon-comment:before {
   content: "comment";
}

.ui-icon-compare:before {
   content: "compare";
}

.ui-icon-compare-arrows:before {
   content: "compare_arrows";
}

.ui-icon-computer:before {
   content: "computer";
}

.ui-icon-confirmation-number:before {
   content: "confirmation_number";
}

.ui-icon-contact-mail:before {
   content: "contact_mail";
}

.ui-icon-contact-phone:before {
   content: "contact_phone";
}

.ui-icon-contacts:before {
   content: "contacts";
}

.ui-icon-content-copy:before {
   content: "content_copy";
}

.ui-icon-content-cut:before {
   content: "content_cut";
}

.ui-icon-content-paste:before {
   content: "content_paste";
}

.ui-icon-control-point:before {
   content: "control_point";
}

.ui-icon-control-point-duplicate:before {
   content: "control_point_duplicate";
}

.ui-icon-copyright:before {
   content: "copyright";
}

.ui-icon-create:before {
   content: "create";
}

.ui-icon-create-new-folder:before {
   content: "create_new_folder";
}

.ui-icon-credit-card:before {
   content: "credit_card";
}

.ui-icon-crop:before {
   content: "crop";
}

.ui-icon-crop-16-9:before {
   content: "crop_16_9";
}

.ui-icon-crop-3-2:before {
   content: "crop_3_2";
}

.ui-icon-crop-5-4:before {
   content: "crop_5_4";
}

.ui-icon-crop-7-5:before {
   content: "crop_7_5";
}

.ui-icon-crop-din:before {
   content: "crop_din";
}

.ui-icon-crop-free:before {
   content: "crop_free";
}

.ui-icon-crop-landscape:before {
   content: "crop_landscape";
}

.ui-icon-crop-original:before {
   content: "crop_original";
}

.ui-icon-crop-portrait:before {
   content: "crop_portrait";
}

.ui-icon-crop-rotate:before {
   content: "crop_rotate";
}

.ui-icon-crop-square:before {
   content: "crop_square";
}

.ui-icon-dashboard:before {
   content: "dashboard";
}

.ui-icon-data-usage:before {
   content: "data_usage";
}

.ui-icon-date-range:before {
   content: "date_range";
}

.ui-icon-dehaze:before {
   content: "dehaze";
}

.ui-icon-delete:before {
   content: "delete";
}

.ui-icon-delete-forever:before {
   content: "delete_forever";
}

.ui-icon-delete-sweep:before {
   content: "delete_sweep";
}

.ui-icon-description:before {
   content: "description";
}

.ui-icon-desktop-mac:before {
   content: "desktop_mac";
}

.ui-icon-desktop-windows:before {
   content: "desktop_windows";
}

.ui-icon-details:before {
   content: "details";
}

.ui-icon-developer-board:before {
   content: "developer_board";
}

.ui-icon-developer-mode:before {
   content: "developer_mode";
}

.ui-icon-device-hub:before {
   content: "device_hub";
}

.ui-icon-devices:before {
   content: "devices";
}

.ui-icon-devices-other:before {
   content: "devices_other";
}

.ui-icon-dialer-sip:before {
   content: "dialer_sip";
}

.ui-icon-dialpad:before {
   content: "dialpad";
}

.ui-icon-directions:before {
   content: "directions";
}

.ui-icon-directions-bike:before {
   content: "directions_bike";
}

.ui-icon-directions-boat:before {
   content: "directions_boat";
}

.ui-icon-directions-bus:before {
   content: "directions_bus";
}

.ui-icon-directions-car:before {
   content: "directions_car";
}

.ui-icon-directions-railway:before {
   content: "directions_railway";
}

.ui-icon-directions-run:before {
   content: "directions_run";
}

.ui-icon-directions-subway:before {
   content: "directions_subway";
}

.ui-icon-directions-transit:before {
   content: "directions_transit";
}

.ui-icon-directions-walk:before {
   content: "directions_walk";
}

.ui-icon-disc-full:before {
   content: "disc_full";
}

.ui-icon-dns:before {
   content: "dns";
}

.ui-icon-do-not-disturb:before {
   content: "do_not_disturb";
}

.ui-icon-do-not-disturb-alt:before {
   content: "do_not_disturb_alt";
}

.ui-icon-do-not-disturb-off:before {
   content: "do_not_disturb_off";
}

.ui-icon-do-not-disturb-on:before {
   content: "do_not_disturb_on";
}

.ui-icon-dock:before {
   content: "dock";
}

.ui-icon-domain:before {
   content: "domain";
}

.ui-icon-done:before {
   content: "done";
}

.ui-icon-done-all:before {
   content: "done_all";
}

.ui-icon-donut-large:before {
   content: "donut_large";
}

.ui-icon-donut-small:before {
   content: "donut_small";
}

.ui-icon-drafts:before {
   content: "drafts";
}

.ui-icon-drag-handle:before {
   content: "drag_handle";
}

.ui-icon-drive-eta:before {
   content: "drive_eta";
}

.ui-icon-dvr:before {
   content: "dvr";
}

.ui-icon-edit:before {
   content: "edit";
}

.ui-icon-edit-location:before {
   content: "edit_location";
}

.ui-icon-eject:before {
   content: "eject";
}

.ui-icon-email:before {
   content: "email";
}

.ui-icon-enhanced-encryption:before {
   content: "enhanced_encryption";
}

.ui-icon-equalizer:before {
   content: "equalizer";
}

.ui-icon-error:before {
   content: "error";
}

.ui-icon-error-outline:before {
   content: "error_outline";
}

.ui-icon-euro-symbol:before {
   content: "euro_symbol";
}

.ui-icon-ev-station:before {
   content: "ev_station";
}

.ui-icon-event:before {
   content: "event";
}

.ui-icon-event-available:before {
   content: "event_available";
}

.ui-icon-event-busy:before {
   content: "event_busy";
}

.ui-icon-event-note:before {
   content: "event_note";
}

.ui-icon-event-seat:before {
   content: "event_seat";
}

.ui-icon-exit-to-app:before {
   content: "exit_to_app";
}

.ui-icon-expand-less:before {
   content: "expand_less";
}

.ui-icon-expand-more:before {
   content: "expand_more";
}

.ui-icon-explicit:before {
   content: "explicit";
}

.ui-icon-explore:before {
   content: "explore";
}

.ui-icon-exposure:before {
   content: "exposure";
}

.ui-icon-exposure-neg-1:before {
   content: "exposure_neg_1";
}

.ui-icon-exposure-neg-2:before {
   content: "exposure_neg_2";
}

.ui-icon-exposure-plus-1:before {
   content: "exposure_plus_1";
}

.ui-icon-exposure-plus-2:before {
   content: "exposure_plus_2";
}

.ui-icon-exposure-zero:before {
   content: "exposure_zero";
}

.ui-icon-extension:before {
   content: "extension";
}

.ui-icon-face:before {
   content: "face";
}

.ui-icon-fast-forward:before {
   content: "fast_forward";
}

.ui-icon-fast-rewind:before {
   content: "fast_rewind";
}

.ui-icon-favorite:before {
   content: "favorite";
}

.ui-icon-favorite-border:before {
   content: "favorite_border";
}

.ui-icon-featured-play-list:before {
   content: "featured_play_list";
}

.ui-icon-featured-video:before {
   content: "featured_video";
}

.ui-icon-feedback:before {
   content: "feedback";
}

.ui-icon-fiber-dvr:before {
   content: "fiber_dvr";
}

.ui-icon-fiber-manual-record:before {
   content: "fiber_manual_record";
}

.ui-icon-fiber-new:before {
   content: "fiber_new";
}

.ui-icon-fiber-pin:before {
   content: "fiber_pin";
}

.ui-icon-fiber-smart-record:before {
   content: "fiber_smart_record";
}

.ui-icon-file-download:before {
   content: "file_download";
}

.ui-icon-file-upload:before {
   content: "file_upload";
}

.ui-icon-filter:before {
   content: "filter";
}

.ui-icon-filter-1:before {
   content: "filter_1";
}

.ui-icon-filter-2:before {
   content: "filter_2";
}

.ui-icon-filter-3:before {
   content: "filter_3";
}

.ui-icon-filter-4:before {
   content: "filter_4";
}

.ui-icon-filter-5:before {
   content: "filter_5";
}

.ui-icon-filter-6:before {
   content: "filter_6";
}

.ui-icon-filter-7:before {
   content: "filter_7";
}

.ui-icon-filter-8:before {
   content: "filter_8";
}

.ui-icon-filter-9:before {
   content: "filter_9";
}

.ui-icon-filter-9-plus:before {
   content: "filter_9_plus";
}

.ui-icon-filter-b-and-w:before {
   content: "filter_b_and_w";
}

.ui-icon-filter-center-focus:before {
   content: "filter_center_focus";
}

.ui-icon-filter-drama:before {
   content: "filter_drama";
}

.ui-icon-filter-frames:before {
   content: "filter_frames";
}

.ui-icon-filter-hdr:before {
   content: "filter_hdr";
}

.ui-icon-filter-list:before {
   content: "filter_list";
}

.ui-icon-filter-none:before {
   content: "filter_none";
}

.ui-icon-filter-tilt-shift:before {
   content: "filter_tilt_shift";
}

.ui-icon-filter-vintage:before {
   content: "filter_vintage";
}

.ui-icon-find-in-page:before {
   content: "find_in_page";
}

.ui-icon-find-replace:before {
   content: "find_replace";
}

.ui-icon-fingerprint:before {
   content: "fingerprint";
}

.ui-icon-first-page:before {
   content: "first_page";
}

.ui-icon-fitness-center:before {
   content: "fitness_center";
}

.ui-icon-flag:before {
   content: "flag";
}

.ui-icon-flare:before {
   content: "flare";
}

.ui-icon-flash-auto:before {
   content: "flash_auto";
}

.ui-icon-flash-off:before {
   content: "flash_off";
}

.ui-icon-flash-on:before {
   content: "flash_on";
}

.ui-icon-flight:before {
   content: "flight";
}

.ui-icon-flight-land:before {
   content: "flight_land";
}

.ui-icon-flight-takeoff:before {
   content: "flight_takeoff";
}

.ui-icon-flip:before {
   content: "flip";
}

.ui-icon-flip-to-back:before {
   content: "flip_to_back";
}

.ui-icon-flip-to-front:before {
   content: "flip_to_front";
}

.ui-icon-folder:before {
   content: "folder";
}

.ui-icon-folder-open:before {
   content: "folder_open";
}

.ui-icon-folder-shared:before {
   content: "folder_shared";
}

.ui-icon-folder-special:before {
   content: "folder_special";
}

.ui-icon-font-download:before {
   content: "font_download";
}

.ui-icon-format-align-center:before {
   content: "format_align_center";
}

.ui-icon-format-align-justify:before {
   content: "format_align_justify";
}

.ui-icon-format-align-left:before {
   content: "format_align_left";
}

.ui-icon-format-align-right:before {
   content: "format_align_right";
}

.ui-icon-format-bold:before {
   content: "format_bold";
}

.ui-icon-format-clear:before {
   content: "format_clear";
}

.ui-icon-format-color-fill:before {
   content: "format_color_fill";
}

.ui-icon-format-color-reset:before {
   content: "format_color_reset";
}

.ui-icon-format-color-text:before {
   content: "format_color_text";
}

.ui-icon-format-indent-decrease:before {
   content: "format_indent_decrease";
}

.ui-icon-format-indent-increase:before {
   content: "format_indent_increase";
}

.ui-icon-format-italic:before {
   content: "format_italic";
}

.ui-icon-format-line-spacing:before {
   content: "format_line_spacing";
}

.ui-icon-format-list-bulleted:before {
   content: "format_list_bulleted";
}

.ui-icon-format-list-numbered:before {
   content: "format_list_numbered";
}

.ui-icon-format-paint:before {
   content: "format_paint";
}

.ui-icon-format-quote:before {
   content: "format_quote";
}

.ui-icon-format-shapes:before {
   content: "format_shapes";
}

.ui-icon-format-size:before {
   content: "format_size";
}

.ui-icon-format-strikethrough:before {
   content: "format_strikethrough";
}

.ui-icon-format-textdirection-l-to-r:before {
   content: "format_textdirection_l_to_r";
}

.ui-icon-format-textdirection-r-to-l:before {
   content: "format_textdirection_r_to_l";
}

.ui-icon-format-underlined:before {
   content: "format_underlined";
}

.ui-icon-forum:before {
   content: "forum";
}

.ui-icon-forward:before {
   content: "forward";
}

.ui-icon-forward-10:before {
   content: "forward_10";
}

.ui-icon-forward-30:before {
   content: "forward_30";
}

.ui-icon-forward-5:before {
   content: "forward_5";
}

.ui-icon-free-breakfast:before {
   content: "free_breakfast";
}

.ui-icon-fullscreen:before {
   content: "fullscreen";
}

.ui-icon-fullscreen-exit:before {
   content: "fullscreen_exit";
}

.ui-icon-functions:before {
   content: "functions";
}

.ui-icon-g-translate:before {
   content: "g_translate";
}

.ui-icon-gamepad:before {
   content: "gamepad";
}

.ui-icon-games:before {
   content: "games";
}

.ui-icon-gavel:before {
   content: "gavel";
}

.ui-icon-gesture:before {
   content: "gesture";
}

.ui-icon-get-app:before {
   content: "get_app";
}

.ui-icon-gif:before {
   content: "gif";
}

.ui-icon-golf-course:before {
   content: "golf_course";
}

.ui-icon-gps-fixed:before {
   content: "gps_fixed";
}

.ui-icon-gps-not-fixed:before {
   content: "gps_not_fixed";
}

.ui-icon-gps-off:before {
   content: "gps_off";
}

.ui-icon-grade:before {
   content: "grade";
}

.ui-icon-gradient:before {
   content: "gradient";
}

.ui-icon-grain:before {
   content: "grain";
}

.ui-icon-graphic-eq:before {
   content: "graphic_eq";
}

.ui-icon-grid-off:before {
   content: "grid_off";
}

.ui-icon-grid-on:before {
   content: "grid_on";
}

.ui-icon-group:before {
   content: "group";
}

.ui-icon-group-add:before {
   content: "group_add";
}

.ui-icon-group-work:before {
   content: "group_work";
}

.ui-icon-hd:before {
   content: "hd";
}

.ui-icon-hdr-off:before {
   content: "hdr_off";
}

.ui-icon-hdr-on:before {
   content: "hdr_on";
}

.ui-icon-hdr-strong:before {
   content: "hdr_strong";
}

.ui-icon-hdr-weak:before {
   content: "hdr_weak";
}

.ui-icon-headset:before {
   content: "headset";
}

.ui-icon-headset-mic:before {
   content: "headset_mic";
}

.ui-icon-healing:before {
   content: "healing";
}

.ui-icon-hearing:before {
   content: "hearing";
}

.ui-icon-help:before {
   content: "help";
}

.ui-icon-help-outline:before {
   content: "help_outline";
}

.ui-icon-high-quality:before {
   content: "high_quality";
}

.ui-icon-highlight:before {
   content: "highlight";
}

.ui-icon-highlight-off:before {
   content: "highlight_off";
}

.ui-icon-history:before {
   content: "history";
}

.ui-icon-home:before {
   content: "home";
}

.ui-icon-hot-tub:before {
   content: "hot_tub";
}

.ui-icon-hotel:before {
   content: "hotel";
}

.ui-icon-hourglass-empty:before {
   content: "hourglass_empty";
}

.ui-icon-hourglass-full:before {
   content: "hourglass_full";
}

.ui-icon-http:before {
   content: "http";
}

.ui-icon-https:before {
   content: "https";
}

.ui-icon-image:before {
   content: "image";
}

.ui-icon-image-aspect-ratio:before {
   content: "image_aspect_ratio";
}

.ui-icon-import-contacts:before {
   content: "import_contacts";
}

.ui-icon-import-export:before {
   content: "import_export";
}

.ui-icon-important-devices:before {
   content: "important_devices";
}

.ui-icon-inbox:before {
   content: "inbox";
}

.ui-icon-indeterminate-check-box:before {
   content: "indeterminate_check_box";
}

.ui-icon-info:before {
   content: "info";
}

.ui-icon-info-outline:before {
   content: "info_outline";
}

.ui-icon-input:before {
   content: "input";
}

.ui-icon-insert-chart:before {
   content: "insert_chart";
}

.ui-icon-insert-comment:before {
   content: "insert_comment";
}

.ui-icon-insert-drive-file:before {
   content: "insert_drive_file";
}

.ui-icon-insert-emoticon:before {
   content: "insert_emoticon";
}

.ui-icon-insert-invitation:before {
   content: "insert_invitation";
}

.ui-icon-insert-link:before {
   content: "insert_link";
}

.ui-icon-insert-photo:before {
   content: "insert_photo";
}

.ui-icon-invert-colors:before {
   content: "invert_colors";
}

.ui-icon-invert-colors-off:before {
   content: "invert_colors_off";
}

.ui-icon-iso:before {
   content: "iso";
}

.ui-icon-keyboard:before {
   content: "keyboard";
}

.ui-icon-keyboard-arrow-down:before {
   content: "keyboard_arrow_down";
}

.ui-icon-keyboard-arrow-left:before {
   content: "keyboard_arrow_left";
}

.ui-icon-keyboard-arrow-right:before {
   content: "keyboard_arrow_right";
}

.ui-icon-keyboard-arrow-up:before {
   content: "keyboard_arrow_up";
}

.ui-icon-keyboard-backspace:before {
   content: "keyboard_backspace";
}

.ui-icon-keyboard-capslock:before {
   content: "keyboard_capslock";
}

.ui-icon-keyboard-hide:before {
   content: "keyboard_hide";
}

.ui-icon-keyboard-return:before {
   content: "keyboard_return";
}

.ui-icon-keyboard-tab:before {
   content: "keyboard_tab";
}

.ui-icon-keyboard-voice:before {
   content: "keyboard_voice";
}

.ui-icon-kitchen:before {
   content: "kitchen";
}

.ui-icon-label:before {
   content: "label";
}

.ui-icon-label-outline:before {
   content: "label_outline";
}

.ui-icon-landscape:before {
   content: "landscape";
}

.ui-icon-language:before {
   content: "language";
}

.ui-icon-laptop:before {
   content: "laptop";
}

.ui-icon-laptop-chromebook:before {
   content: "laptop_chromebook";
}

.ui-icon-laptop-mac:before {
   content: "laptop_mac";
}

.ui-icon-laptop-windows:before {
   content: "laptop_windows";
}

.ui-icon-last-page:before {
   content: "last_page";
}

.ui-icon-launch:before {
   content: "launch";
}

.ui-icon-layers:before {
   content: "layers";
}

.ui-icon-layers-clear:before {
   content: "layers_clear";
}

.ui-icon-leak-add:before {
   content: "leak_add";
}

.ui-icon-leak-remove:before {
   content: "leak_remove";
}

.ui-icon-lens:before {
   content: "lens";
}

.ui-icon-library-add:before {
   content: "library_add";
}

.ui-icon-library-books:before {
   content: "library_books";
}

.ui-icon-library-music:before {
   content: "library_music";
}

.ui-icon-lightbulb-outline:before {
   content: "lightbulb_outline";
}

.ui-icon-line-style:before {
   content: "line_style";
}

.ui-icon-line-weight:before {
   content: "line_weight";
}

.ui-icon-linear-scale:before {
   content: "linear_scale";
}

.ui-icon-link:before {
   content: "link";
}

.ui-icon-linked-camera:before {
   content: "linked_camera";
}

.ui-icon-list:before {
   content: "list";
}

.ui-icon-live-help:before {
   content: "live_help";
}

.ui-icon-live-tv:before {
   content: "live_tv";
}

.ui-icon-local-activity:before {
   content: "local_activity";
}

.ui-icon-local-airport:before {
   content: "local_airport";
}

.ui-icon-local-atm:before {
   content: "local_atm";
}

.ui-icon-local-bar:before {
   content: "local_bar";
}

.ui-icon-local-cafe:before {
   content: "local_cafe";
}

.ui-icon-local-car-wash:before {
   content: "local_car_wash";
}

.ui-icon-local-convenience-store:before {
   content: "local_convenience_store";
}

.ui-icon-local-dining:before {
   content: "local_dining";
}

.ui-icon-local-drink:before {
   content: "local_drink";
}

.ui-icon-local-florist:before {
   content: "local_florist";
}

.ui-icon-local-gas-station:before {
   content: "local_gas_station";
}

.ui-icon-local-grocery-store:before {
   content: "local_grocery_store";
}

.ui-icon-local-hospital:before {
   content: "local_hospital";
}

.ui-icon-local-hotel:before {
   content: "local_hotel";
}

.ui-icon-local-laundry-service:before {
   content: "local_laundry_service";
}

.ui-icon-local-library:before {
   content: "local_library";
}

.ui-icon-local-mall:before {
   content: "local_mall";
}

.ui-icon-local-movies:before {
   content: "local_movies";
}

.ui-icon-local-offer:before {
   content: "local_offer";
}

.ui-icon-local-parking:before {
   content: "local_parking";
}

.ui-icon-local-pharmacy:before {
   content: "local_pharmacy";
}

.ui-icon-local-phone:before {
   content: "local_phone";
}

.ui-icon-local-pizza:before {
   content: "local_pizza";
}

.ui-icon-local-play:before {
   content: "local_play";
}

.ui-icon-local-post-office:before {
   content: "local_post_office";
}

.ui-icon-local-printshop:before {
   content: "local_printshop";
}

.ui-icon-local-see:before {
   content: "local_see";
}

.ui-icon-local-shipping:before {
   content: "local_shipping";
}

.ui-icon-local-taxi:before {
   content: "local_taxi";
}

.ui-icon-location-city:before {
   content: "location_city";
}

.ui-icon-location-disabled:before {
   content: "location_disabled";
}

.ui-icon-location-off:before {
   content: "location_off";
}

.ui-icon-location-on:before {
   content: "location_on";
}

.ui-icon-location-searching:before {
   content: "location_searching";
}

.ui-icon-lock:before {
   content: "lock";
}

.ui-icon-lock-open:before {
   content: "lock_open";
}

.ui-icon-lock-outline:before {
   content: "lock_outline";
}

.ui-icon-looks:before {
   content: "looks";
}

.ui-icon-looks-3:before {
   content: "looks_3";
}

.ui-icon-looks-4:before {
   content: "looks_4";
}

.ui-icon-looks-5:before {
   content: "looks_5";
}

.ui-icon-looks-6:before {
   content: "looks_6";
}

.ui-icon-looks-one:before {
   content: "looks_one";
}

.ui-icon-looks-two:before {
   content: "looks_two";
}

.ui-icon-loop:before {
   content: "loop";
}

.ui-icon-loupe:before {
   content: "loupe";
}

.ui-icon-low-priority:before {
   content: "low_priority";
}

.ui-icon-loyalty:before {
   content: "loyalty";
}

.ui-icon-mail:before {
   content: "mail";
}

.ui-icon-mail-outline:before {
   content: "mail_outline";
}

.ui-icon-map:before {
   content: "map";
}

.ui-icon-markunread:before {
   content: "markunread";
}

.ui-icon-markunread-mailbox:before {
   content: "markunread_mailbox";
}

.ui-icon-memory:before {
   content: "memory";
}

.ui-icon-menu:before {
   content: "menu";
}

.ui-icon-merge-type:before {
   content: "merge_type";
}

.ui-icon-message:before {
   content: "message";
}

.ui-icon-mic:before {
   content: "mic";
}

.ui-icon-mic-none:before {
   content: "mic_none";
}

.ui-icon-mic-off:before {
   content: "mic_off";
}

.ui-icon-mms:before {
   content: "mms";
}

.ui-icon-mode-comment:before {
   content: "mode_comment";
}

.ui-icon-mode-edit:before {
   content: "mode_edit";
}

.ui-icon-monetization-on:before {
   content: "monetization_on";
}

.ui-icon-money-off:before {
   content: "money_off";
}

.ui-icon-monochrome-photos:before {
   content: "monochrome_photos";
}

.ui-icon-mood:before {
   content: "mood";
}

.ui-icon-mood-bad:before {
   content: "mood_bad";
}

.ui-icon-more:before {
   content: "more";
}

.ui-icon-more-horiz:before {
   content: "more_horiz";
}

.ui-icon-more-vert:before {
   content: "more_vert";
}

.ui-icon-motorcycle:before {
   content: "motorcycle";
}

.ui-icon-mouse:before {
   content: "mouse";
}

.ui-icon-move-to-inbox:before {
   content: "move_to_inbox";
}

.ui-icon-movie:before {
   content: "movie";
}

.ui-icon-movie-creation:before {
   content: "movie_creation";
}

.ui-icon-movie-filter:before {
   content: "movie_filter";
}

.ui-icon-multiline-chart:before {
   content: "multiline_chart";
}

.ui-icon-music-note:before {
   content: "music_note";
}

.ui-icon-music-video:before {
   content: "music_video";
}

.ui-icon-my-location:before {
   content: "my_location";
}

.ui-icon-nature:before {
   content: "nature";
}

.ui-icon-nature-people:before {
   content: "nature_people";
}

.ui-icon-navigate-before:before {
   content: "navigate_before";
}

.ui-icon-navigate-next:before {
   content: "navigate_next";
}

.ui-icon-navigation:before {
   content: "navigation";
}

.ui-icon-near-me:before {
   content: "near_me";
}

.ui-icon-network-cell:before {
   content: "network_cell";
}

.ui-icon-network-check:before {
   content: "network_check";
}

.ui-icon-network-locked:before {
   content: "network_locked";
}

.ui-icon-network-wifi:before {
   content: "network_wifi";
}

.ui-icon-new-releases:before {
   content: "new_releases";
}

.ui-icon-next-week:before {
   content: "next_week";
}

.ui-icon-nfc:before {
   content: "nfc";
}

.ui-icon-no-encryption:before {
   content: "no_encryption";
}

.ui-icon-no-sim:before {
   content: "no_sim";
}

.ui-icon-not-interested:before {
   content: "not_interested";
}

.ui-icon-note:before {
   content: "note";
}

.ui-icon-note-add:before {
   content: "note_add";
}

.ui-icon-notifications:before {
   content: "notifications";
}

.ui-icon-notifications-active:before {
   content: "notifications_active";
}

.ui-icon-notifications-none:before {
   content: "notifications_none";
}

.ui-icon-notifications-off:before {
   content: "notifications_off";
}

.ui-icon-notifications-paused:before {
   content: "notifications_paused";
}

.ui-icon-offline-pin:before {
   content: "offline_pin";
}

.ui-icon-ondemand-video:before {
   content: "ondemand_video";
}

.ui-icon-opacity:before {
   content: "opacity";
}

.ui-icon-open-in-browser:before {
   content: "open_in_browser";
}

.ui-icon-open-in-new:before {
   content: "open_in_new";
}

.ui-icon-open-with:before {
   content: "open_with";
}

.ui-icon-pages:before {
   content: "pages";
}

.ui-icon-pageview:before {
   content: "pageview";
}

.ui-icon-palette:before {
   content: "palette";
}

.ui-icon-pan-tool:before {
   content: "pan_tool";
}

.ui-icon-panorama:before {
   content: "panorama";
}

.ui-icon-panorama-fish-eye:before {
   content: "panorama_fish_eye";
}

.ui-icon-panorama-horizontal:before {
   content: "panorama_horizontal";
}

.ui-icon-panorama-vertical:before {
   content: "panorama_vertical";
}

.ui-icon-panorama-wide-angle:before {
   content: "panorama_wide_angle";
}

.ui-icon-party-mode:before {
   content: "party_mode";
}

.ui-icon-pause:before {
   content: "pause";
}

.ui-icon-pause-circle-filled:before {
   content: "pause_circle_filled";
}

.ui-icon-pause-circle-outline:before {
   content: "pause_circle_outline";
}

.ui-icon-payment:before {
   content: "payment";
}

.ui-icon-people:before {
   content: "people";
}

.ui-icon-people-outline:before {
   content: "people_outline";
}

.ui-icon-perm-camera-mic:before {
   content: "perm_camera_mic";
}

.ui-icon-perm-contact-calendar:before {
   content: "perm_contact_calendar";
}

.ui-icon-perm-data-setting:before {
   content: "perm_data_setting";
}

.ui-icon-perm-device-information:before {
   content: "perm_device_information";
}

.ui-icon-perm-identity:before {
   content: "perm_identity";
}

.ui-icon-perm-media:before {
   content: "perm_media";
}

.ui-icon-perm-phone-msg:before {
   content: "perm_phone_msg";
}

.ui-icon-perm-scan-wifi:before {
   content: "perm_scan_wifi";
}

.ui-icon-person:before {
   content: "person";
}

.ui-icon-person-add:before {
   content: "person_add";
}

.ui-icon-person-outline:before {
   content: "person_outline";
}

.ui-icon-person-pin:before {
   content: "person_pin";
}

.ui-icon-person-pin-circle:before {
   content: "person_pin_circle";
}

.ui-icon-personal-video:before {
   content: "personal_video";
}

.ui-icon-pets:before {
   content: "pets";
}

.ui-icon-phone:before {
   content: "phone";
}

.ui-icon-phone-android:before {
   content: "phone_android";
}

.ui-icon-phone-bluetooth-speaker:before {
   content: "phone_bluetooth_speaker";
}

.ui-icon-phone-forwarded:before {
   content: "phone_forwarded";
}

.ui-icon-phone-in-talk:before {
   content: "phone_in_talk";
}

.ui-icon-phone-iphone:before {
   content: "phone_iphone";
}

.ui-icon-phone-locked:before {
   content: "phone_locked";
}

.ui-icon-phone-missed:before {
   content: "phone_missed";
}

.ui-icon-phone-paused:before {
   content: "phone_paused";
}

.ui-icon-phonelink:before {
   content: "phonelink";
}

.ui-icon-phonelink-erase:before {
   content: "phonelink_erase";
}

.ui-icon-phonelink-lock:before {
   content: "phonelink_lock";
}

.ui-icon-phonelink-off:before {
   content: "phonelink_off";
}

.ui-icon-phonelink-ring:before {
   content: "phonelink_ring";
}

.ui-icon-phonelink-setup:before {
   content: "phonelink_setup";
}

.ui-icon-photo:before {
   content: "photo";
}

.ui-icon-photo-album:before {
   content: "photo_album";
}

.ui-icon-photo-camera:before {
   content: "photo_camera";
}

.ui-icon-photo-filter:before {
   content: "photo_filter";
}

.ui-icon-photo-library:before {
   content: "photo_library";
}

.ui-icon-photo-size-select-actual:before {
   content: "photo_size_select_actual";
}

.ui-icon-photo-size-select-large:before {
   content: "photo_size_select_large";
}

.ui-icon-photo-size-select-small:before {
   content: "photo_size_select_small";
}

.ui-icon-picture-as-pdf:before {
   content: "picture_as_pdf";
}

.ui-icon-picture-in-picture:before {
   content: "picture_in_picture";
}

.ui-icon-picture-in-picture-alt:before {
   content: "picture_in_picture_alt";
}

.ui-icon-pie-chart:before {
   content: "pie_chart";
}

.ui-icon-pie-chart-outlined:before {
   content: "pie_chart_outlined";
}

.ui-icon-pin-drop:before {
   content: "pin_drop";
}

.ui-icon-place:before {
   content: "place";
}

.ui-icon-play-arrow:before {
   content: "play_arrow";
}

.ui-icon-play-circle-filled:before {
   content: "play_circle_filled";
}

.ui-icon-play-circle-outline:before {
   content: "play_circle_outline";
}

.ui-icon-play-for-work:before {
   content: "play_for_work";
}

.ui-icon-playlist-add:before {
   content: "playlist_add";
}

.ui-icon-playlist-add-check:before {
   content: "playlist_add_check";
}

.ui-icon-playlist-play:before {
   content: "playlist_play";
}

.ui-icon-plus-one:before {
   content: "plus_one";
}

.ui-icon-poll:before {
   content: "poll";
}

.ui-icon-polymer:before {
   content: "polymer";
}

.ui-icon-pool:before {
   content: "pool";
}

.ui-icon-portable-wifi-off:before {
   content: "portable_wifi_off";
}

.ui-icon-portrait:before {
   content: "portrait";
}

.ui-icon-power:before {
   content: "power";
}

.ui-icon-power-input:before {
   content: "power_input";
}

.ui-icon-power-settings-new:before {
   content: "power_settings_new";
}

.ui-icon-pregnant-woman:before {
   content: "pregnant_woman";
}

.ui-icon-present-to-all:before {
   content: "present_to_all";
}

.ui-icon-print:before {
   content: "print";
}

.ui-icon-priority-high:before {
   content: "priority_high";
}

.ui-icon-public:before {
   content: "public";
}

.ui-icon-publish:before {
   content: "publish";
}

.ui-icon-query-builder:before {
   content: "query_builder";
}

.ui-icon-question-answer:before {
   content: "question_answer";
}

.ui-icon-queue:before {
   content: "queue";
}

.ui-icon-queue-music:before {
   content: "queue_music";
}

.ui-icon-queue-play-next:before {
   content: "queue_play_next";
}

.ui-icon-radio:before {
   content: "radio";
}

.ui-icon-radio-button-checked:before {
   content: "radio_button_checked";
}

.ui-icon-radio-button-unchecked:before {
   content: "radio_button_unchecked";
}

.ui-icon-rate-review:before {
   content: "rate_review";
}

.ui-icon-receipt:before {
   content: "receipt";
}

.ui-icon-recent-actors:before {
   content: "recent_actors";
}

.ui-icon-record-voice-over:before {
   content: "record_voice_over";
}

.ui-icon-redeem:before {
   content: "redeem";
}

.ui-icon-redo:before {
   content: "redo";
}

.ui-icon-refresh:before {
   content: "refresh";
}

.ui-icon-remove:before {
   content: "remove";
}

.ui-icon-remove-circle:before {
   content: "remove_circle";
}

.ui-icon-remove-circle-outline:before {
   content: "remove_circle_outline";
}

.ui-icon-remove-from-queue:before {
   content: "remove_from_queue";
}

.ui-icon-remove-red-eye:before {
   content: "remove_red_eye";
}

.ui-icon-remove-shopping-cart:before {
   content: "remove_shopping_cart";
}

.ui-icon-reorder:before {
   content: "reorder";
}

.ui-icon-repeat:before {
   content: "repeat";
}

.ui-icon-repeat-one:before {
   content: "repeat_one";
}

.ui-icon-replay:before {
   content: "replay";
}

.ui-icon-replay-10:before {
   content: "replay_10";
}

.ui-icon-replay-30:before {
   content: "replay_30";
}

.ui-icon-replay-5:before {
   content: "replay_5";
}

.ui-icon-reply:before {
   content: "reply";
}

.ui-icon-reply-all:before {
   content: "reply_all";
}

.ui-icon-report:before {
   content: "report";
}

.ui-icon-report-problem:before {
   content: "report_problem";
}

.ui-icon-restaurant:before {
   content: "restaurant";
}

.ui-icon-restaurant-menu:before {
   content: "restaurant_menu";
}

.ui-icon-restore:before {
   content: "restore";
}

.ui-icon-restore-page:before {
   content: "restore_page";
}

.ui-icon-ring-volume:before {
   content: "ring_volume";
}

.ui-icon-room:before {
   content: "room";
}

.ui-icon-room-service:before {
   content: "room_service";
}

.ui-icon-rotate-90-degrees-ccw:before {
   content: "rotate_90_degrees_ccw";
}

.ui-icon-rotate-left:before {
   content: "rotate_left";
}

.ui-icon-rotate-right:before {
   content: "rotate_right";
}

.ui-icon-rounded-corner:before {
   content: "rounded_corner";
}

.ui-icon-router:before {
   content: "router";
}

.ui-icon-rowing:before {
   content: "rowing";
}

.ui-icon-rss-feed:before {
   content: "rss_feed";
}

.ui-icon-rv-hookup:before {
   content: "rv_hookup";
}

.ui-icon-satellite:before {
   content: "satellite";
}

.ui-icon-save:before {
   content: "save";
}

.ui-icon-scanner:before {
   content: "scanner";
}

.ui-icon-schedule:before {
   content: "schedule";
}

.ui-icon-school:before {
   content: "school";
}

.ui-icon-screen-lock-landscape:before {
   content: "screen_lock_landscape";
}

.ui-icon-screen-lock-portrait:before {
   content: "screen_lock_portrait";
}

.ui-icon-screen-lock-rotation:before {
   content: "screen_lock_rotation";
}

.ui-icon-screen-rotation:before {
   content: "screen_rotation";
}

.ui-icon-screen-share:before {
   content: "screen_share";
}

.ui-icon-sd-card:before {
   content: "sd_card";
}

.ui-icon-sd-storage:before {
   content: "sd_storage";
}

.ui-icon-search:before {
   content: "search";
}

.ui-icon-security:before {
   content: "security";
}

.ui-icon-select-all:before {
   content: "select_all";
}

.ui-icon-send:before {
   content: "send";
}

.ui-icon-sentiment-dissatisfied:before {
   content: "sentiment_dissatisfied";
}

.ui-icon-sentiment-neutral:before {
   content: "sentiment_neutral";
}

.ui-icon-sentiment-satisfied:before {
   content: "sentiment_satisfied";
}

.ui-icon-sentiment-very-dissatisfied:before {
   content: "sentiment_very_dissatisfied";
}

.ui-icon-sentiment-very-satisfied:before {
   content: "sentiment_very_satisfied";
}

.ui-icon-settings:before {
   content: "settings";
}

.ui-icon-settings-applications:before {
   content: "settings_applications";
}

.ui-icon-settings-backup-restore:before {
   content: "settings_backup_restore";
}

.ui-icon-settings-bluetooth:before {
   content: "settings_bluetooth";
}

.ui-icon-settings-brightness:before {
   content: "settings_brightness";
}

.ui-icon-settings-cell:before {
   content: "settings_cell";
}

.ui-icon-settings-ethernet:before {
   content: "settings_ethernet";
}

.ui-icon-settings-input-antenna:before {
   content: "settings_input_antenna";
}

.ui-icon-settings-input-component:before {
   content: "settings_input_component";
}

.ui-icon-settings-input-composite:before {
   content: "settings_input_composite";
}

.ui-icon-settings-input-hdmi:before {
   content: "settings_input_hdmi";
}

.ui-icon-settings-input-svideo:before {
   content: "settings_input_svideo";
}

.ui-icon-settings-overscan:before {
   content: "settings_overscan";
}

.ui-icon-settings-phone:before {
   content: "settings_phone";
}

.ui-icon-settings-power:before {
   content: "settings_power";
}

.ui-icon-settings-remote:before {
   content: "settings_remote";
}

.ui-icon-settings-system-daydream:before {
   content: "settings_system_daydream";
}

.ui-icon-settings-voice:before {
   content: "settings_voice";
}

.ui-icon-share:before {
   content: "share";
}

.ui-icon-shop:before {
   content: "shop";
}

.ui-icon-shop-two:before {
   content: "shop_two";
}

.ui-icon-shopping-basket:before {
   content: "shopping_basket";
}

.ui-icon-shopping-cart:before {
   content: "shopping_cart";
}

.ui-icon-short-text:before {
   content: "short_text";
}

.ui-icon-show-chart:before {
   content: "show_chart";
}

.ui-icon-shuffle:before {
   content: "shuffle";
}

.ui-icon-signal-cellular-4-bar:before {
   content: "signal_cellular_4_bar";
}

.ui-icon-signal-cellular-connected-no-internet-4-bar:before {
   content: "signal_cellular_connected_no_internet_4_bar";
}

.ui-icon-signal-cellular-no-sim:before {
   content: "signal_cellular_no_sim";
}

.ui-icon-signal-cellular-null:before {
   content: "signal_cellular_null";
}

.ui-icon-signal-cellular-off:before {
   content: "signal_cellular_off";
}

.ui-icon-signal-wifi-4-bar:before {
   content: "signal_wifi_4_bar";
}

.ui-icon-signal-wifi-4-bar-lock:before {
   content: "signal_wifi_4_bar_lock";
}

.ui-icon-signal-wifi-off:before {
   content: "signal_wifi_off";
}

.ui-icon-sim-card:before {
   content: "sim_card";
}

.ui-icon-sim-card-alert:before {
   content: "sim_card_alert";
}

.ui-icon-skip-next:before {
   content: "skip_next";
}

.ui-icon-skip-previous:before {
   content: "skip_previous";
}

.ui-icon-slideshow:before {
   content: "slideshow";
}

.ui-icon-slow-motion-video:before {
   content: "slow_motion_video";
}

.ui-icon-smartphone:before {
   content: "smartphone";
}

.ui-icon-smoke-free:before {
   content: "smoke_free";
}

.ui-icon-smoking-rooms:before {
   content: "smoking_rooms";
}

.ui-icon-sms:before {
   content: "sms";
}

.ui-icon-sms-failed:before {
   content: "sms_failed";
}

.ui-icon-snooze:before {
   content: "snooze";
}

.ui-icon-sort:before {
   content: "sort";
}

.ui-icon-sort-by-alpha:before {
   content: "sort_by_alpha";
}

.ui-icon-spa:before {
   content: "spa";
}

.ui-icon-space-bar:before {
   content: "space_bar";
}

.ui-icon-speaker:before {
   content: "speaker";
}

.ui-icon-speaker-group:before {
   content: "speaker_group";
}

.ui-icon-speaker-notes:before {
   content: "speaker_notes";
}

.ui-icon-speaker-notes-off:before {
   content: "speaker_notes_off";
}

.ui-icon-speaker-phone:before {
   content: "speaker_phone";
}

.ui-icon-spellcheck:before {
   content: "spellcheck";
}

.ui-icon-star:before {
   content: "star";
}

.ui-icon-star-border:before {
   content: "star_border";
}

.ui-icon-star-half:before {
   content: "star_half";
}

.ui-icon-stars:before {
   content: "stars";
}

.ui-icon-stay-current-landscape:before {
   content: "stay_current_landscape";
}

.ui-icon-stay-current-portrait:before {
   content: "stay_current_portrait";
}

.ui-icon-stay-primary-landscape:before {
   content: "stay_primary_landscape";
}

.ui-icon-stay-primary-portrait:before {
   content: "stay_primary_portrait";
}

.ui-icon-stop:before {
   content: "stop";
}

.ui-icon-stop-screen-share:before {
   content: "stop_screen_share";
}

.ui-icon-storage:before {
   content: "storage";
}

.ui-icon-store:before {
   content: "store";
}

.ui-icon-store-mall-directory:before {
   content: "store_mall_directory";
}

.ui-icon-straighten:before {
   content: "straighten";
}

.ui-icon-streetview:before {
   content: "streetview";
}

.ui-icon-strikethrough-s:before {
   content: "strikethrough_s";
}

.ui-icon-style:before {
   content: "style";
}

.ui-icon-subdirectory-arrow-left:before {
   content: "subdirectory_arrow_left";
}

.ui-icon-subdirectory-arrow-right:before {
   content: "subdirectory_arrow_right";
}

.ui-icon-subject:before {
   content: "subject";
}

.ui-icon-subscriptions:before {
   content: "subscriptions";
}

.ui-icon-subtitles:before {
   content: "subtitles";
}

.ui-icon-subway:before {
   content: "subway";
}

.ui-icon-supervisor-account:before {
   content: "supervisor_account";
}

.ui-icon-surround-sound:before {
   content: "surround_sound";
}

.ui-icon-swap-calls:before {
   content: "swap_calls";
}

.ui-icon-swap-horiz:before {
   content: "swap_horiz";
}

.ui-icon-swap-vert:before {
   content: "swap_vert";
}

.ui-icon-swap-vertical-circle:before {
   content: "swap_vertical_circle";
}

.ui-icon-switch-camera:before {
   content: "switch_camera";
}

.ui-icon-switch-video:before {
   content: "switch_video";
}

.ui-icon-sync:before {
   content: "sync";
}

.ui-icon-sync-disabled:before {
   content: "sync_disabled";
}

.ui-icon-sync-problem:before {
   content: "sync_problem";
}

.ui-icon-system-update:before {
   content: "system_update";
}

.ui-icon-system-update-alt:before {
   content: "system_update_alt";
}

.ui-icon-tab:before {
   content: "tab";
}

.ui-icon-tab-unselected:before {
   content: "tab_unselected";
}

.ui-icon-tablet:before {
   content: "tablet";
}

.ui-icon-tablet-android:before {
   content: "tablet_android";
}

.ui-icon-tablet-mac:before {
   content: "tablet_mac";
}

.ui-icon-tag-faces:before {
   content: "tag_faces";
}

.ui-icon-tap-and-play:before {
   content: "tap_and_play";
}

.ui-icon-terrain:before {
   content: "terrain";
}

.ui-icon-text-fields:before {
   content: "text_fields";
}

.ui-icon-text-format:before {
   content: "text_format";
}

.ui-icon-textsms:before {
   content: "textsms";
}

.ui-icon-texture:before {
   content: "texture";
}

.ui-icon-theaters:before {
   content: "theaters";
}

.ui-icon-thumb-down:before {
   content: "thumb_down";
}

.ui-icon-thumb-up:before {
   content: "thumb_up";
}

.ui-icon-thumbs-up-down:before {
   content: "thumbs_up_down";
}

.ui-icon-time-to-leave:before {
   content: "time_to_leave";
}

.ui-icon-timelapse:before {
   content: "timelapse";
}

.ui-icon-timeline:before {
   content: "timeline";
}

.ui-icon-timer:before {
   content: "timer";
}

.ui-icon-timer-10:before {
   content: "timer_10";
}

.ui-icon-timer-3:before {
   content: "timer_3";
}

.ui-icon-timer-off:before {
   content: "timer_off";
}

.ui-icon-title:before {
   content: "title";
}

.ui-icon-toc:before {
   content: "toc";
}

.ui-icon-today:before {
   content: "today";
}

.ui-icon-toll:before {
   content: "toll";
}

.ui-icon-tonality:before {
   content: "tonality";
}

.ui-icon-touch-app:before {
   content: "touch_app";
}

.ui-icon-toys:before {
   content: "toys";
}

.ui-icon-track-changes:before {
   content: "track_changes";
}

.ui-icon-traffic:before {
   content: "traffic";
}

.ui-icon-train:before {
   content: "train";
}

.ui-icon-tram:before {
   content: "tram";
}

.ui-icon-transfer-within-a-station:before {
   content: "transfer_within_a_station";
}

.ui-icon-transform:before {
   content: "transform";
}

.ui-icon-translate:before {
   content: "translate";
}

.ui-icon-trending-down:before {
   content: "trending_down";
}

.ui-icon-trending-flat:before {
   content: "trending_flat";
}

.ui-icon-trending-up:before {
   content: "trending_up";
}

.ui-icon-tune:before {
   content: "tune";
}

.ui-icon-turned-in:before {
   content: "turned_in";
}

.ui-icon-turned-in-not:before {
   content: "turned_in_not";
}

.ui-icon-tv:before {
   content: "tv";
}

.ui-icon-unarchive:before {
   content: "unarchive";
}

.ui-icon-undo:before {
   content: "undo";
}

.ui-icon-unfold-less:before {
   content: "unfold_less";
}

.ui-icon-unfold-more:before {
   content: "unfold_more";
}

.ui-icon-update:before {
   content: "update";
}

.ui-icon-usb:before {
   content: "usb";
}

.ui-icon-verified-user:before {
   content: "verified_user";
}

.ui-icon-vertical-align-bottom:before {
   content: "vertical_align_bottom";
}

.ui-icon-vertical-align-center:before {
   content: "vertical_align_center";
}

.ui-icon-vertical-align-top:before {
   content: "vertical_align_top";
}

.ui-icon-vibration:before {
   content: "vibration";
}

.ui-icon-video-call:before {
   content: "video_call";
}

.ui-icon-video-label:before {
   content: "video_label";
}

.ui-icon-video-library:before {
   content: "video_library";
}

.ui-icon-videocam:before {
   content: "videocam";
}

.ui-icon-videocam-off:before {
   content: "videocam_off";
}

.ui-icon-videogame-asset:before {
   content: "videogame_asset";
}

.ui-icon-view-agenda:before {
   content: "view_agenda";
}

.ui-icon-view-array:before {
   content: "view_array";
}

.ui-icon-view-carousel:before {
   content: "view_carousel";
}

.ui-icon-view-column:before {
   content: "view_week";
}

.ui-icon-view-comfy:before {
   content: "view_comfy";
}

.ui-icon-view-compact:before {
   content: "view_compact";
}

.ui-icon-view-day:before {
   content: "view_day";
}

.ui-icon-view-headline:before {
   content: "view_headline";
}

.ui-icon-view-list:before {
   content: "view_list";
}

.ui-icon-view-module:before {
   content: "view_module";
}

.ui-icon-view-quilt:before {
   content: "view_quilt";
}

.ui-icon-view-stream:before {
   content: "view_stream";
}

.ui-icon-view-week:before {
   content: "view_week";
}

.ui-icon-vignette:before {
   content: "vignette";
}

.ui-icon-visibility:before {
   content: "visibility";
}

.ui-icon-visibility-off:before {
   content: "visibility_off";
}

.ui-icon-voice-chat:before {
   content: "voice_chat";
}

.ui-icon-voicemail:before {
   content: "voicemail";
}

.ui-icon-volume-down:before {
   content: "volume_down";
}

.ui-icon-volume-mute:before {
   content: "volume_mute";
}

.ui-icon-volume-off:before {
   content: "volume_off";
}

.ui-icon-volume-up:before {
   content: "volume_up";
}

.ui-icon-vpn-key:before {
   content: "vpn_key";
}

.ui-icon-vpn-lock:before {
   content: "vpn_lock";
}

.ui-icon-wallpaper:before {
   content: "wallpaper";
}

.ui-icon-warning:before {
   content: "warning";
}

.ui-icon-watch:before {
   content: "watch";
}

.ui-icon-watch-later:before {
   content: "watch_later";
}

.ui-icon-wb-auto:before {
   content: "wb_auto";
}

.ui-icon-wb-cloudy:before {
   content: "wb_cloudy";
}

.ui-icon-wb-incandescent:before {
   content: "wb_incandescent";
}

.ui-icon-wb-iridescent:before {
   content: "wb_iridescent";
}

.ui-icon-wb-sunny:before {
   content: "wb_sunny";
}

.ui-icon-wc:before {
   content: "wc";
}

.ui-icon-web:before {
   content: "web";
}

.ui-icon-web-asset:before {
   content: "web_asset";
}

.ui-icon-weekend:before {
   content: "weekend";
}

.ui-icon-whatshot:before {
   content: "whatshot";
}

.ui-icon-widgets:before {
   content: "widgets";
}

.ui-icon-wifi:before {
   content: "wifi";
}

.ui-icon-wifi-lock:before {
   content: "wifi_lock";
}

.ui-icon-wifi-tethering:before {
   content: "wifi_tethering";
}

.ui-icon-work:before {
   content: "work";
}

.ui-icon-wrap-text:before {
   content: "wrap_text";
}

.ui-icon-youtube-searched-for:before {
   content: "youtube_searched_for";
}

.ui-icon-zoom-in:before {
   content: "zoom_in";
}

.ui-icon-zoom-out:before {
   content: "zoom_out";
}

.ui-icon-zoom-out-map:before {
   content: "zoom_out_map";
}

.ui-paginator,
.p-paginator {
   background: transparent;
   border: none;
   margin: $spacing-md 0;
   & > a .fa {
   display: none;
}

   .ui-paginator-first:before,
   .p-paginator-first:before {
   content: "first_page";
}

   .ui-paginator-prev:before,
   .p-paginator-prev:before {
   content: "navigate_before";
}

   .ui-paginator-next:before,
   .p-paginator-next:before {
   content: "navigate_next";
}

   .ui-paginator-last:before,
   .p-paginator-last:before {
   content: "last_page";
}

   .ui-paginator-icon:before,
   .p-paginator-icon:before {
   content: none;
}
}

.ui-dialog.ui-widget .ui-dialog-content,
.p-dialog .p-dialog-content {
   overflow: auto;
   border-radius: 3px;
}

.ui-dialog,
.p-dialog {
   box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
   // per primeNG devs, resizing and re-centering for dynamically loaded content is not working. thus we use workaround for now.
   //  source: https://github.com/primefaces/primeng/issues/2735
   width: auto;
   margin: auto !important;
   position: relative;
}

.ui-dialog-content,
.p-dialog-content {
   max-width: 62.5rem;
   min-width: 320px;
   overflow: auto;
   max-height: $dialog-max-height;
}

body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button,
body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
   width: 2em;
   min-width: 2em;
}

.ui-calendar,
.p-calendar {
   width: 100%;
   height: 100%;
   display: flex;

   .ui-widget.ui-inputtext,
   .p-component.p-inputtext {
      display: inline-block;
      width: calc(100% - 2em);
      border: none;
   }

   .ui-widget.ui-calendar-button,
   button.p-button {
      @include border-color("input-border");
      @include background-color("input-background");
      top: 0px;
      right: 0px;
      border-width: 0px 0px 0px 1px;
      border-radius: 0px 2px 2px 0px;
      margin: 0;

      &:hover {
         @include hover-transition;
         @include background("primary-button-accent");
         @include border-color("primary-button-accent");
         color: white;
      }

      .ui-button-icon-left,
      .p-button-icon {
         @include color("input-icon");

         &:hover {
            color: white;
         }
      }
   }
}

.ui-datepicker .ui-datepicker-next span {
   margin-left: -0.7em;
}

.ui-inputtext:enabled:hover,
.p-inputtext:enabled:hover {
   // @include border-color('primary');
   border: none;
}

.ui-dropdown {
   @include border-color("input-border");
   @include background-color("input-background");
   overflow: visible;
   border: 1px solid;
   margin-bottom: 0.2em;

   .ui-dropdown-label {
      padding-left: 8px;
      padding-top: 8px;
      background-color: transparent;
   }

   &:hover {
      @include background-color("input-background");
   }

   .ui-dropdown-trigger {
      @include background-color("input-background");
      border: none;

      &:hover {
         @include background-color("input-background");
      }
   }
}

.ui-multiselect {
   @include border-color("input-border");
   @include background-color("input-background");
   height: $input-height;
   align-items: center;
   justify-content: flex-start;
   display: flex;
   border: 1px solid;

   .ui-multiselect-label-conainer {
      height: inherit;
      align-items: center;
      background-color: transparent;
      justify-content: center;
      display: flex;
   }

   .ui-widget-header {
      display: flex;
      align-items: center;

      .ui-chkbox,
      .p-checkbox {
         padding-left: 5px;
         padding-right: 5px;
      }

      .ui-multiselect-filter-container {
         flex-grow: 1;

         .fa,
         .pi {
            @include color("text-primary");
            right: 0.125em;
            left: auto;
            top: 0.5em;
            font-size: 1.25em;
            @include color("input-icon");
         }
      }

      .ui-multiselect-close {
         display: none;
      }
   }

   .ui-multiselect-trigger {
      border: none;
      background-color: transparent;

      .fa {
         margin-top: 3px;
         margin-left: 0;
      }
   }

   .ui-multiselect-panel {
      .ui-multiselect-item {
         align-items: center;
         height: 2.2em;
         padding: 0.4em 0.25em;

         &.ui-state-highlight {
            @include background-color("primary");

            label {
               color: white;
            }
         }

         &:hover {
            @include background-color("input-background");
         }
      }
   }

   .ui-multiselect-label {
      background-color: transparent;
      white-space: nowrap;
   }
}

.ui-multiselect-header .ui-inputtext,
.ui-multiselect-header .p-inputtext {
   padding: 0.125em 0.125em 0.125em 1.8em;
}

.ui-multiselect:not(.ui-state-disabled):hover {
   @include border-color("input-border");
   @include background-color("input-background");
   border: 1px solid;
}

body .ui-datepicker {
   display: block;
   padding: 0;

   .ui-datepicker-header {
      display: flex;
      align-items: center;

      .ui-datepicker-prev,
      .ui-datepicker-next {
         position: relative;
         top: initial;
         width: 2.5em;

         .pi {
            margin-left: -0.5em;
         }
      }

      .ui-datepicker-next {
         order: 4;
      }

      .ui-datepicker-title {
         display: flex;
         flex: 1;
         margin: 0;

         select.ui-datepicker-month {
            flex: 6;
            width: inherit;
         }

         select.ui-datepicker-year {
            flex: 3;
            width: inherit;
         }
      }
   }
}

.lcs-slide-panel-close-icon {
   padding: 0;
}

.p-datepicker{
   min-width: auto !important;
}