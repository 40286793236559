/* Button class */

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, 
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month
{
   @include color("calendar-text-primary-button-accent-color"); 
}

.button {
   @include background-color('primary-button-accent');
   display: inline-block;
   text-align: center;
   line-height: 1;
   cursor: pointer;
   -webkit-appearance: none;
   transition: background-color 0.25s ease-out, color 0.25s ease-out;
   vertical-align: middle;
   border: 1px solid transparent;
   border-radius: 2px;
   padding: 0 $spacing-md;
   height: 36px;
   margin: 0 $spacing-sm $spacing-md 0;
   font-size: em(14px);
   font-weight: $font-weight--semi-bold;
   color: $white;
   position: relative;
   overflow: hidden;
   min-width: 80px;
   user-select: none;
   @media (max-width: 40em) {
      width: 100%;
   }
   &:hover,
   &:focus {
      @include background-color('primary-button-accent-darken-5');
      @include color("text-primary-button-accent-color");
   }
   &.primary {
      @include background-color('primary-button-accent');
      color: white;
      &:hover,
      &:focus {
         @include background-color('primary-button-accent-darken-5');
         @include color("text-primary-button-accent-color");
      }
   }
   &.secondary {
      @include background-color('secondary');
      color: white;
      &:hover,
      &:focus {
         @include background-color('secondary-darken-10');
         color: white;
      }
   }
   &.accent {
      @include background-color('accent');
      color: white;
      &:hover,
      &:focus {
         @include background-color('accent-darken-10');
         color: white;
      }
   }
   &.success {
      @include background-color('success');
      color: white;
      &:hover,
      &:focus {
         @include background-color('success-dark');
         color: white;
      }
   }
   &.warning {
      @include background-color('warning');
      color: white;
      &:hover,
      &:focus {
         @include background-color('warning-dark');
         color: white;
      }
   }
   &.alert {
      @include background-color('alert');
      color: $white;
      &:hover,
      &:focus {
         @include background-color('alert-dark');
         color: $white;
      }
   }
   &.disabled {
      @include disabled;
   }
}

.button-group .button {
   margin: 0;
   margin-right: 1px;
   margin-bottom: 1px;
   font-size: 1em;
   -ms-flex: 0 0 auto;
   flex: 0 0 auto;
}

// Material Buttons
.mat-button {
   @include color('primary-button-accent');
   @include background('primary-button-accent');
   font-weight: normal;
   font-size: 1em;
   border-radius: 2px;
   height: 36px;
   padding: 0 $spacing-md;
   .mat-button-focus-overlay {
      @include hover-transition;
      @include background('primary-lighten-5');
   }
}

.mat-button.mat-raised-button {
   border-radius: 2px;
   @include color("text-primary-button-accent-color");
   padding: 0 $spacing-md;
   line-height: normal;
   &:hover {
      @include color("text-primary-button-accent-color");
   }
   &.primary {
      @include background-color('primary-button-accent');
   }
   &.secondary {
      @include background-color('secondary');
   }
   &.accent {
      @include background-color('accent');
   }
   &.success {
      @include background-color('success');
   }
   &.warning {
      @include background-color('warning');
   }
   &.alert {
      @include background-color('alert');
   }
}

.login-button-container {
   .mat-button {
      height: auto;
      padding: $spacing-md;
   }
}