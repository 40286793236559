$border-radius: 3px;
$white: #fff;
$black: #000;
$layout-animation-transition-time: 0.2s;
$multiselector-height: 200px;
$report-parameter-label-height: 19px;
$dialog-max-height: 90vh;
$white: #fff;
$layout-animation-transition-time: 0.2s;
$multiselector-height: 200px;
$report-parameter-label-height: 19px;
$footer-height: 50px;
$dialog-padding: 24px;
$dialog-max-height: 90vh;
$context-bar-height: 60px;
$top-bar-height: 50px;
$footer-height: 50px;
$dialog-header-height: 48px;
// z-indexes
$full-menu-z-index: 100000;
$content-spinner-overlay-z-index: calc(#{$full-menu-z-index} - 1);
$favorites-tooltips-z-index: calc(#{$full-menu-z-index} + 1);
$spinner-overlay-z-index: calc(#{$favorites-tooltips-z-index} + 1);
$image-carousel-control-z-index: 2;
$site-image-container-height: 40px;
$datatable-top-filter-section-height: 52px;
$file-carousel-height: 61vh;
$file-carousel-small-height: 40vh;
$image-carousel-close-font: 2em;
