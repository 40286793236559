.tile {
   @include background-color('content-tile');
   border-radius: $border-radius;
   height: calc(100% - #{$spacing-xs});
   margin: $spacing-xs;
   box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.25);
   &-title {
      font-size: 1em;
      padding: 00px 0px 0px 15px;
      font-weight: $font-weight--semi-bold;
      text-transform: uppercase;
      word-wrap: none;
      text-overflow: ellipsis;
      overflow: hidden;
   }
   &-content {
      padding: 15px;
      // font-size: 12px;
      line-height: 1.2;
   }
}

.tile-wrapper{
   height: 100%;
}

.header-border {
   @include border-color('primary');
   border-top: 4px solid;
}

.tile-header-wrapper {
   display: grid;
   grid-template-columns: 1fr auto auto;
   grid-template-rows: 1fr;
   grid-row-start: 1;
   grid-row-end: 2;
   align-items: center;
   width: 100%;
   border-bottom: 1px solid #e5e5e5;
   border-radius: 2px 2px 0px 0px;
   height: 4em;
}

.view-more-icon{
   
   padding: 0 0.5em;
   grid-column: 3;
   cursor: pointer;
}
.icon-color {
   @include color("primary-icons") ;

   &:hover {
     // @include hover-transition;
      @include color("primary-icons-lighten-5");
      opacity: 0.7;
   }
}