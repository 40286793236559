owa-signable-documents-list-item {
   flex: 1 per-line(4);
   max-width: 500px;
   @media (max-width: 1400px) {
      flex: 1 1 per-line(3);
   }
   @include medium-screen {
      flex: 1 1 per-line(2);
      max-width: 100%;
   }
   @include small-screen {
      flex: 1 1 per-line(1);
      max-width: 100%;
   }
}

.tile-wrapper {
   padding: $spacing-tile;
}

.flex-stay {
   flex-grow: 0;
   flex-shrink: 0;
}

.no-documents {
   width: 100%;
   text-align: center;
}

.signable-documents-list-container {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding: $spacing-tile;
   .signable-document-tile {
      @include background-color('content-tile');
      box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.25);
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      .signable-document-name {
         padding: $spacing-md;
         @include color('text-primary');
         @include border-color('border');
         @include font-secondary;
         border-bottom: 1px solid;
         font-size: em(18px);
      }
      .signable-document-list-item-detail {
         padding: 1em;
         .detail-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: $spacing-sm;
            border-radius: $border-radius;
            @include background-color('ui-background');
            text-align: center;
            width: 100%;
            line-height: 1.45;
            @include small-screen {
               display: block;
               text-align: left;
               overflow: hidden;
            }
            .title-value {
               font-size: em(16px);
               font-weight: $font-weight--semi-bold;
               padding: $spacing-xs;
               @include small-screen {
                  display: inline;
                  line-height: 1.45;
                  float: right;
               }
            }
            .title {
               padding: $spacing-xs;
               font-size: .875em;
               @include small-screen {
                  display: inline;
                  text-align: left;
                  float: left;
                  clear: both;
               }
            }
         }
         .status-container {
            padding-top: $spacing-md;
            font-size: em(18px);
            display: flex;
            flex-direction: column;
            .status-container-item {
               text-align: center;
               display: flex;
               align-items: center;
               justify-content: center;
               &.expiration {
                  font-size: em(12px);
                  margin: $spacing-md auto;
                  .expiration-heading {
                     @include color('text-primary');
                     font-weight: $font-weight--semi-bold;
                     margin-right: $spacing-sm;
                  }
                  .expiration-value {
                     @include color('text-accent');
                  }
               }
               .signable-icon {
                  @include color('primary');
                  margin-right: $spacing-xs;
               }
               .signable-icon-black {
                  @include color(#000);
                  margin-right: $spacing-xs;
               }
               .complete-icon {
                  @include color('success');
                  margin-right: $spacing-xs;
               }
            }
         }
      }
      .button-wrapper {
         @include color('primary');
         @include border-color('border');
         @include font-secondary;
         border-top: 1px solid;
         text-align: center;
         margin-top: auto;
         .button-container {
            padding: $spacing-md;
            @include color("link");
            cursor: pointer;
         }
      }
   }
}

#signatureDisableMessage {
   line-height: 1.45;
}