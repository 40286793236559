.reports-hub-container {
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 100%;
   overflow-y: auto;

   .reports-hub-content {
      flex: 1;
      min-height: 0;
      height: 100%;

      .reports-hub-header {
         @include color("text-primary");
         @include border-color("border");
         border-bottom: 1px solid;
         display: flex;
         grid-area: header;
         align-items: center;
         grid-row: 1;
         cursor: pointer;

         &.is-pdf-viewer-visible {
            cursor: default;
         }

         .reports-hub-header-title-wrapper {
            display: flex;
            justify-content: flex-start;

            button {
               padding-right: $spacing-sm;
            }
         }

         .reports-hub-header-title {
            @include color("text-primary");
            font-weight: $font-weight--normal;
         }
      }

      owa-report-parameters {
         display: grid;
         grid-area: parameters;
         grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
         grid-auto-rows: min-content;
         grid-gap: $spacing-md;
         grid-auto-flow: dense;
         overflow-y: auto;
         padding: $spacing-md;

         @include medium-screen {
            overflow-y: initial;
         }

         .report-parameter-tile,
         lcs-label-selection-report-parameter {
            @include grid-layout;
            grid-auto-rows: min-content;
            grid-template-columns: 1fr;
            grid-gap: 15px;

            label {
               margin-top: 0;
            }

            .individual-report-parameter-option {
               margin-bottom: 15px;
            }
         }

         .multiselector-wrapper {
            margin: 0;
         }

         .additional-controls-wrapper {
            margin-left: 1em;
            margin-right: 1em;
            margin-bottom: 1em;
         }
      }

      .properties-side-bar {
         grid-area: properties;
         position: flex;
         color: $white;
         height: 100%;
         top: 0px;
         bottom: 0px;
         padding: 0 $spacing-md $spacing-md $spacing-md;
         overflow-x: hidden;
         transition: all $layout-animation-transition-time ease-out;
         min-width: 40px;
      }

      .no-memorized-reports-splash-screen {
         grid-column-start: 1;
         grid-column-end: -1;
         grid-row-start: 2;
         grid-row-end: -1;

         @include medium-screen {
            grid-row-start: 1;
         }
      }

      owa-pdf-viewer {
         flex: 1;
         display: flex;
         flex-direction: column;
         height: 100%;

         .pdf-viewer-iframe {
            height: 100%;
         }

         @include medium-screen {
            grid-row-start: 1;
         }

         pdf-viewer>div.ng2-pdf-viewer-container {
            position: initial;
            overflow: hidden;
         }

         div.annotationLayer {
            position: initial;
         }
      }

      owa-multi-pdf-viewer {
         height: 100%;

         @include medium-screen {
            grid-row-start: 1;
         }

         .sidebar-layout-content-wrapper {
            overflow-y: hidden;
         }
      }
   }

   >lcs-footer {
      grid-area: report-footer;

      .selector-container {
         display: flex;
      }
   }
}

.reports-hub-container {
   &.is-pdf-viewer-visible {
      .reports-hub-content {
         margin: 0;
         padding: 0;
         display: flex;
      }
   }
}

.glLedger-report {
   padding-top: 12px;
}

.memorized-date-range-report-parameter-start {
   padding-bottom: $spacing-md;
}

.charge-type-order-report-parameter {
   width: 100%;
   height: calc(#{$multiselector-height} + #{$report-parameter-label-height});

   lcs-sortable-list {
      .sortable-list {
         height: $multiselector-height;
      }
   }
}

owa-collapsible-context-bar {
   grid-area: collapsible-context-bar;
   justify-content: flex-start;

   .collapsible-context-bar-content {
      display: flex;
      justify-content: space-between;

      button {
         padding-right: $spacing-sm;
      }
   }
}

.context-bar,
.reports-hub-header {
   @include background("context-bar");
   @include color("text-primary");
   @include border-color("border");
   border-bottom: 1px solid;
   display: flex;
   justify-content: space-between;
   height: $context-bar-height;
   padding: 0 $spacing-md;
   align-items: center;
   z-index: 1;
   position: relative;

   @include small-screen {
      display: flex;
      justify-content: space-between;
      @include color("text-primary");
      flex-wrap: nowrap;

      lcs-select {
         order: 2;
         width: 100%;
      }
   }

   .context-bar-title {
      font-size: em(24px);
      font-weight: $font-weight--normal;
      @include color("text-secondary");

      @include medium-screen {
         font-size: em(18px);
      }
   }

   .context-bar-links {
      display: flex;
      align-items: center;

      .context-bar-refresh-icon {
         display: flex;
         margin: $spacing-md 0 $spacing-md $spacing-md;
      }
   }
}

.rerun-report {
   .list-view-toggle-btn {
      background: none;
      display: inline-flex;
      align-items: center;
      user-select: none;
      width: auto;
      @include color("link");

      @include small-screen {
         display: inline-flex;
      }
   }
}

.content-header-arrow {
   display: none;
}

.content-display-title-value {
   .content-display-title {
      font-size: em(14px);
      opacity: 0.7;
   }

   .content-display-value {
      @include color("text-primary");
      opacity: 1;
      font-size: em(18px);
      font-weight: $font-weight--normal;
      margin-bottom: 0px;
      line-height: 24px;
      min-height: 24px;
      display: flex;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: space-between;
      text-align: left;
      opacity: 1;
   }
}

.reports-list-search-container {
   margin-top: 1em;
   margin-bottom: 0.5em;
   margin-left: 1em;
   margin-right: 1em;
}

.report-flex {
   flex-grow: 0;

   .reports-list-item {
      padding: 1em;
      margin: 0.5em;
      @include background-color("content-tile");
      box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      @include color("link");
      @include border-color("border");
      @include font-secondary;
      border-bottom: 1px solid;

      a {
         width: 100%;
      }

      &:hover {
         @include background-color("menu-hover");
      }
   }
}

.ui-tooltip,
.p-tooltip {
   box-shadow: none;
}

.sidebar-layout-sidebar-wrapper.left {
   overflow-y: auto;
}

.ui-tooltip,
.p-tooltip {
   @include small-screen {
      display: none !important;
   }
}