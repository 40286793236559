.sortable-list {
   width: 100%;
   height: 100%;
   @extend .ios-scrollable;
   @include border-color("border");
   overflow-y: auto;
   overflow-x: hidden;
   text-overflow: ellipsis;
   border: 1px solid;

   @include background-color("input-background");
}

// this exist outside the .sortable-list parent, otherwise the drag preview won't have styles applied
.sortable-list-item {
   padding: $spacing-xs;

   &:hover {
      .item-wrapper {
         .vertical-align-icon-wrapper {
            visibility: visible;
         }
      }
   }

   .item-wrapper {
      @include flexy($wrap: wrap, $dir: row);
      justify-content: space-between;
      flex-wrap: nowrap;

      @include background-color("input-background");

      .item-label-wrapper {
         display: flex;
         width: 100%;
         align-items: center;
         padding: $spacing-xs;

         i {
            @include color("text-accent");
            margin-right: $spacing-sm;
            cursor: move;
         }
      }

      .vertical-align-icon-wrapper {
         display: flex;
         padding-right: $spacing-sm;
         align-items: center;
         visibility: hidden;
         i {
            @include color("text-accent");
            padding-right: $spacing-md;
            cursor: pointer;
         }
      }
   }
}
