.file-carousel {
   max-width: 100%;
   box-shadow: none !important;
   background: none;

   .modal-header {
      background-color: transparent !important;
      align-items: center;
   }

   .modal-heading {
      color: #ffffff !important;
   }

   .ui-dialog-content,
   .p-dialog-content {
      background-color: transparent !important;
      border: none !important;
      background: none !important;
   }

   .close-icon .material-icons {
      color: #ffffff;
      font-size: 2em;
      margin-right: 13px;
   }
}

.ui-dialog-content,
.p-dialog-content {
   max-width: 100vw;
}

.image-carousel {
   max-height: 100%;
   box-shadow: none !important;
   background: none;

   .modal-container {
      max-height: 98vh;
      overflow: visible !important;
   }

   .image-close-icon {
      font-size: $image-carousel-close-font;
      cursor: pointer;
   }

   .image-icon-container {
      padding: 1vh 0 1vh 0;
      padding-left: $image-carousel-close-font;
      display: flex;
      text-align: center;
      margin: auto;
   }

   .close-icon-container {
      height: inherit;
   }

   .modal-header {
      border: none !important;
      background: none !important;

      i {
         opacity: 0;
      }

      .modal-heading {
         opacity: 0;
      }
   }

   .ui-dialog-content,
   .p-dialog-content {
      max-height: 98vh;
      border: none !important;
      background: none !important;
      overflow: visible !important;
   }

   .ng-star-inserted {
      color: #ffffff;
   }

   .image-carousel-preview-container {
      height: $file-carousel-height;
      display: flex;
      text-align: center;
      align-items: center;
   }

   .image-carousel-preview-link {
      margin-top: 25px;
   }

   .image-carousel-preview {
      max-height: $file-carousel-height - 5;
      max-width: 100%;
      object-fit: contain;

      @include hover-transition;

      &:hover {
         border: 2px solid #f28228;
      }
   }

   .image-carousel-hidden {
      opacity: 0.6;
   }
}

.image-carousel-arrow-left {
   left: 0;
}

.image-carousel-arrow-right {
   right: 0;
}

.image-carousel-arrow-left,
.image-carousel-arrow-right {
   @include background-color("content-tile");
   @include color("text-primary");
   position: absolute;
   width: auto;
   min-width: auto;
   border-radius: 100%;
   flex: auto;
   top: 30%;
   cursor: pointer;
   z-index: $image-carousel-control-z-index;

   &:hover {
      @include background-color("primary");
      @include hover-transition;
      color: white;
   }

   i {
      background-color: transparent;
      font-size: 2em;
      align-self: center;
   }

   &:focus {
      outline: none;
   }

   &:disabled {
      @include disabled;
      cursor: default;

      &:hover {
         @include background-color("content-tile");

         i {
            @include background-color("content-tile");
         }
      }
   }
}

.upload-link,
.drop-zone-directions {
   .drop-option-text {
      display: inline;
      @include color("text-secondary");
   }
}

.image-carousel-wrapper {
   margin-top: 10px;
   position: relative;
   height: 14em;
   padding: 0 4em $spacing-sm;

   .ui-scrollpanel-bar-y {
      display: none;
   }

   .ui-scrollpanel-bar-x {
      display: none;
   }

   &.no-images {
      height: 4em;
      padding: 0;
   }

   .image-carousel-container {
      height: calc(100% + 18px);
   }

   .image-type-label {
      @include color("text-primary");
      text-align: left;
      padding: 1em 0;
      font-size: em(12px);
      display: flex;
      align-items: center;

      &::after {
         @include border-color("border");
         content: "";
         border-top: 1px solid;
         margin-left: 0.5em;
         flex: 1;
      }
   }

   .image-type-container {
      margin-right: $spacing-md;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      width: max-content;

      &:last-of-type {
         margin-right: $spacing-xl;
      }
   }

   .image-type-images {
      display: flex;
      flex: 1;
      align-items: flex-start;
      height: 11.5em;

      a.image-delete-icon-wrapper {
         position: absolute;
         cursor: pointer;
         width: 1.25em;
         height: 1.25em;
         top: -0.625em;
         border-radius: 50%;
         right: -0.625em;
         font-size: $base__font-size;
         @include background-color("primary");
         @include color("content-tile");
         display: flex;
         align-items: center;
         justify-content: center;

         i {
            font-size: 0.875em;
         }
      }
   }

   .image-carousel-image {
      flex-shrink: 0;
      height: inherit;
      margin-right: $spacing-xl;
      position: relative;
      @include border-color("border");

      &:last-of-type {
         margin-right: 25px;
      }

      @include hover-transition;

      &:hover {
         border: 2px solid #f28228;
      }
   }

   .image-carousel-img {
      height: 100%;
      cursor: pointer;
   }

   .image-carousel-file {
      width: 25px;
      height: 25px;
   }

   .image-carousel-file-container {
      background-color: #e4e4e4;
      padding: 60px;
      display: flex;
      align-items: center;
      height: 100%;
      overflow-y: hidden;
   }

   .image-carousel-no-images {
      height: 100%;
      width: 100%;
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      .no-images-found {
         @include color("text-primary");
         text-align: center;
         padding: 8px 0;
      }

      .upload-link {
         text-align: center;
         display: inline;
         padding-bottom: 8px;

         .upload-option-text {
            @include color("primary");
            cursor: pointer;
            display: inline;
            font-weight: $font-weight--semi-bold;
         }
      }
   }

   .add-image-button {
      @include background-color("content-tile");
      @include color("text-primary");
      @include flexy();
      justify-content: center;
      height: $spacing-lg;
      position: absolute;
      width: $spacing-xl;
      flex: auto;
      right: 0;
      z-index: $image-carousel-control-z-index;
      cursor: pointer;

      &:hover {
         @include background-color("primary-lighten-5");
         @include hover-transition;

         i {
            color: white;
         }
      }

      &:focus {
         outline: none;
      }
   }
}

.image-with-type-upload-wrapper {
   padding: 0 $spacing-md;

   .image-type-dropdown-wrapper {
      padding-bottom: $spacing-md;
      @include color("text-primary");

      .image-type-selector {
         min-width: 50%;
         flex-grow: 1;
      }
   }
}

.upload-drop-zone-wrapper {
   .selected-file-text {
      @include color("text-primary");
   }
}

@media screen and (max-height: 700px) {
   .image-carousel {
      .image-type-images {
         height: 7em;
      }

      .dialog-content {
         overflow-y: auto;
      }

      .image-carousel-preview-container {
         height: $file-carousel-small-height;
      }

      .image-carousel-preview {
         max-height: $file-carousel-small-height - 8;
      }

      .image-carousel-arrow-left,
      .image-carousel-arrow-right {
         top: 10%;
      }
   }
}

@media screen and (max-height: 300px) {
   .image-carousel {
      .image-type-images {
         height: 3em;
      }

      .image-carousel-arrow-left,
      .image-carousel-arrow-right {
         top: 0;
         padding: 8px;
      }
   }
}
