.selector-container {
   align-self: center;

   .select {
      height: $input-height;
      padding: $spacing-sm;
      font-size: 1em;
      font-family: inherit;
      line-height: normal;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      transition: background-color 0s;
      width: 100%;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
   }
}

.sort-select {
   display: flex;
   align-items: center;
   max-width: $input-width;

   .sort-arrow-button {
      cursor: pointer;
      max-width: $input-height;
      width: 100%;
      border: 1px solid;
      padding: 3px;
      @include border-color("primary");
      @include background-color("primary");
      outline: none;

      .sort-arrow-icon {
         display: inline-flex;
         align-self: center;

         a {
            color: $white;
         }
      }
   }

   .ui-state-focus {
      @include border-color("focus");
      outline: none;
   }
}

.ui-dialog-content,
.p-dialog-content {
   width: 100%;

   .sort-select {
      display: flex;
      align-items: center;
      max-width: $max-width;

      .ui-state-focus {
         @include border-color("focus");
         outline: none;
      }

      .enum-selector {
         width: 100%;
      }
   }
}

.searchable-selector-wrapper {
   &.searchable-selector-inline-label {
      display: flex;
      align-items: center;

      label {
         margin: 0;
         margin-right: 1em;
         padding: $spacing-md $spacing-sm;
      }

      .searchable-selector-container {
         margin: 0;
      }
   }
}

.searchable-selector-container,
.action-bar-overlay-panel {
   position: relative;
   align-self: center;

   .input-wrapper {
      position: relative;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      width: 100%;

      .material-icons {
         @include color("input-icon");
         position: absolute;
         top: 0;
         bottom: 0;
         right: 5px;
         margin-top: auto;
         margin-bottom: auto;
         transform: translateZ(0);

         &.validation-error-icon {
            @include color("alert");
         }

         &.optional-icon {
            left: em(5px);
            right: 0;
         }

         &.search-icon {
            @include color("primary");
            cursor: pointer;
            left: em(5px);
            right: 0;
         }
      }

      input {
         width: 100%;
         padding: $input-padding;
         padding-right: $spacing-xl;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
         font-weight: normal;

         &.optional-icon {
            padding-left: $spacing-2xl;
         }

         &.search-icon {
            padding-left: $spacing-2xl;
         }
      }
   }
}

.selector-overlay-panel-section-label {
   @include color("text-primary");
   @include background-color("datatable-header");
   padding: $spacing-md $spacing-sm;
   margin-top: 0;
   margin-bottom: 0;
   font-weight: $font-weight--semi-bold;
}

.selector-overlay-panel {
   @include color("text-primary");
}

.selector-overlay-item {
   @include background-color("overlay");

   &:hover {
      @include color("text-primary");
      @include background-color("overlay-hover");
      @include hover-transition;
   }

   .selector-overlay-item-selected {
      @include background-color("selected");
      @include color("text-selected");

      .selector-overlay-item-display {
         .selector-overlay-item-additional-info {
            @include color("text-selected");
         }
      }

      &:hover {
         @include background-color("selected-hover");
         @include hover-transition;
      }
   }

   lcs-action-link,
   lcs-link,
   lcs-link.selector-link,
   a.selector-link {
      display: block;
      height: 100%;
      width: 100%;
      cursor: pointer;
   }
}

.selector-overlay-item,
.selector-overlay-error-message {
   display: flex;
   justify-content: space-between;
   font-size: 1em;
   line-height: normal;
   width: 100%;
   text-overflow: ellipsis;
   white-space: nowrap;
   align-items: center;

   i {
      cursor: pointer;
      @include color("primary");
      flex-shrink: 0;
      width: 1.5em;
   }

   .selector-overlay-item-display-wrap {
      flex-grow: 1;
   }

   a.selector-overlay-item-display,
   .selector-overlay-item-display {
      padding: #{$input-padding + em(1px)};
      @include grid-layout;
      min-height: 2.5em;
      font-size: 1em;
      font-weight: 400;
      text-transform: none;
      cursor: pointer;
      width: 100%;

      .selector-overlay-item-display-label {
         overflow-x: hidden;
         text-overflow: ellipsis;
      }

      &.selector-overlay-additional-message-display {
         cursor: not-allowed;
         @include color("text-accent");
         font-style: italic;
      }
   }
}

.selector-overlay-item-additional-info {
   @include color("text-accent");
   font-size: em(12px);
   overflow-x: hidden;
   text-overflow: ellipsis;

   .selector-overlay-item-selected & {
      @include color("text-selected");
   }
}

.selector-overlay-item.label {
   .selector-overlay-item-display {
      @include color("text-primary");
   }
}

.transparent-background {
   background: transparent;
}

.ui-dialog-content,
.p-dialog-content {
   transition: none;
   transform: none;
   transition-duration: 0ms;
   transition-delay: 0ms;
}

.ng-trigger-slideDialog {
   transition: none;
   transform: none;
   transition-duration: 0ms;
   transition-delay: 0ms;
}

.ui-overlaypanel-content {
   padding: 0;
}

.multiselector-wrapper {
   height: $multiselector-height;
   @include background-color("input-filter");
   @include border-color("input-border");
   @include color("text-primary");
   border-radius: 3px;
   padding: 0.5em;
   overflow-y: auto;
   overflow-x: hidden;
   margin-bottom: 1rem;
   border: 1px solid;
   min-height: 54px;

   .checkbox-wrapper {
      margin: 1.3em 0;
   }

   .tabs-content {
      margin-bottom: 1rem;
   }

   .tabs-panel {
      max-height: $multiselector-height;
      overflow-y: auto;
      overflow-x: hidden;
   }

   .checkbox-wrapper {
      padding-left: $spacing-md;
   }
}

.single-selector-wrapper.select,
.single-line-multi-selector-wrapper.select {
   display: flex;
   align-items: center;
   outline: none;

   .selector-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;

      .selector-icons {
         display: flex;
         align-items: center;
         outline: none;
      }
   }

   &.selector-disabled {
      @include disabled;
   }

   i {
      width: 1.5em;

      &.material-icon {
         // material icons have a different line height defined in our overrides. When that is cleaned up, this can be removed
         width: 1em;
      }
   }
}

lcs-single-line-multi-select {
   @include grid-layout;
   grid-template-rows: auto 1fr auto;
   grid-template-areas: "header" "selector" "footer";

   .inline-selector {
      display: flex;
      align-items: center;

      label {
         white-space: nowrap;
         height: 100%;
         flex-shrink: 0;
      }

      .single-line-multi-selector-wrapper {
         flex-grow: 1;
      }

      @include medium-screen {
         display: block;
      }
   }
}

.overlay-panel-contents {
   .multi-selector-overlay-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      .multi-selector-search-header {
         padding: $spacing-sm;
         align-items: center;
         flex-wrap: nowrap;
         flex-shrink: 0;
         flex-grow: 0;

         .checkbox-wrapper {
            margin-right: $spacing-sm;

            .checkbox-label {
               display: none;
            }
         }

         .multi-selector-overlay-panel-search {
            flex-grow: 1;
         }
      }

      .multiselector-wrapper {
         flex-grow: 1;
         max-height: 200px;
         margin-bottom: 0;
         padding: 0;
         border-radius: 0;
         border-left: none;
         border-right: none;
         @include background-color("input-filter");

         .checkbox-wrapper {
            margin: 0;
            padding-left: $spacing-sm;
         }

         lcs-checkbox .checkbox-wrapper {
            margin: 0;
            padding: $spacing-sm;

            .selector-overlay-item {
               @include background-color("input-filter");

               &:hover {
                  @include color("text-primary");
                  @include background-color("overlay");
               }

               .selector-overlay-item-display {
                  min-height: unset;
                  padding: 0;
               }
            }
         }
      }

      .multi-selector-search-footer {
         padding: $spacing-md $spacing-sm;
         align-items: center;
         flex-wrap: nowrap;
         flex-shrink: 0;
         flex-grow: 0;

         .selected-item-count {
            padding-right: $spacing-sm;
         }

         label {
            margin: 0;
         }
      }
   }
}

lcs-select {

   &.ng-invalid.ng-touched,
   &.ng-invalid.ng-dirty {
      input {
         @include border-color("alert");
      }

      i.validation-error-icon {
         @include color("alert");
      }
   }

   .ui-multiselect-label {
      background-color: transparent;
   }
}

.ui-multiselect-header .ui-inputtext {
   padding: 0.125em 0.125em 0.125em 1.8em;
}

.ui-multiselect:not(.ui-state-disabled):hover {
   @include background-color("input-background");
   @include border-color("border-light");
   border: 1px solid;
}

.ui-multiselect.ui-state-focus {
   @include border-color("focus");
   box-shadow: unset;
   border: 1px solid;
}

.tab-wrapper {
   @include border-color("border");
   border: 1px solid;

   .single-tab-wrapper {
      padding: 0.5em 1em 1em 1em;

      label {
         margin-top: $spacing-sm !important;
      }
   }

   .ui-widget-header {
      background: transparent;
   }
}

.report-parameter-link-wrapper {
   display: flex;
   align-items: center;
   white-space: break-spaces;

   .secondary-parameter-label {
      a {
         @include color("text-accent");
      }
   }
}

.radio-button-wrapper {
   padding: 0.5em 0em 0 0em;
}

lcs-select {
   .selector-error {
      @include border-color("alert");

      .validation-error-icon {
         @include color("alert");
      }

      .selector-expand-icon {
         display: none;
      }
   }
}

.searchable-menu-search {
   margin-bottom: 1em;
   position: relative;

   .material-icons {
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      right: 5px;
   }

   i.material-icons {
      @include color("input-icon");
   }
}

.searchable-menu-list {
   margin: 0;
   max-height: 21em;
   overflow-y: auto;

   .searchable-menu-list-item {
      padding: 1em calc(0.5em + 1px);
      cursor: pointer;

      &.selected {
         @include background-color("input-background");
      }

      &:hover {
         @include background-color("border");
      }
   }
}