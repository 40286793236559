.lcs-slide-panel-container {
   display: block;
   width: 100vw;
   height: 100vh;
   padding: 2em;
   position: relative;
}

.lcs-slide-panel-base {
   display: block;
   height: 100%;
   position: relative;
   overflow-y: hidden;
}

.lcs-slide-panel-base-wrapper {
   height: 100%;
   width: 100%;
   &.lcs-slide-panel-visible {
      position: absolute;
   }
}

.lcs-slide-panel-close-icon {
   position: absolute;
   top: 1em;
   right: 1em;
   cursor: pointer;
}

.lcs-slide-panel {
   display: flex;
   flex-direction: column;
   position: relative;
   height: 100%;
   width: 100%;
   padding: 2em;
}

.lcs-slide-panel-container {
   .lcs-slide-panel {
      padding: 0;
      .lcs-slide-panel-close-icon {
         display: none;
      }
   }
}

.lcs-slide-panel-title {
   padding: 0;
   margin: 0 0 1.5em;
   display: block;
}

.lcs-slide-panel-content {
   display: block;
   margin: 0 -2em;
   padding: 0 2em;
   overflow-y: auto;
   overflow-x: hidden;
}

.lcs-slide-panel-footer {
   display: block;
   padding: 0.5em 0;
   margin-bottom: -2em;
}

.lcs-slide-panel-outlet-wrapper {
   height: 100%;
   position: relative;
}
