lcs-slide-panel-outlet{
   height: 100%;
}

.lcs-slide-panel-container,
.lcs-slide-panel {
   @include background-color('content-tile');
}

.lcs-slide-panel-content {
   height: 100%;
}.lcs-slide-panel-title{
   font-size: 1em;
   font-weight: $font-weight--semi-bold;
   text-transform: uppercase;
   word-wrap: none;
   text-overflow: ellipsis;
   overflow: hidden;
}