.flex-grid {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.tile-item {
   padding: 1em;
}

.title {
   font-size: 0.875em;
   opacity: 0.7;
   font-weight: 500;
}

.tile-value {
   // font-size: 1.125em;
   font-weight: 400;
   text-overflow: ellipsis;
}

.grid-container {
   .title {
      @include color('text-secondary');
      padding-top: 0.25em;
      font-size: em(14px);
   }

   .tile-value {
      @include color('text-primary');
      padding-top: $spacing-xs;
      margin-bottom: $spacing-md;
      white-space: pre-wrap;
      font-weight: $font-weight--semi-bold;
      font-size: em(16px);
   }
}

.address-container {
   display: grid;
   grid-template-columns: 200px 1fr;
   grid-gap: 0px 5px;
}

.address {
   .tile-value {
      white-space: normal;
   }
}

.searchInput {
   @include color('text-accent');
   @include border-color('input-border');
   width: auto;
   border-radius: 3px;
   border: 1px solid;
   display: inline-block;
   margin-left: 10px;
}

.lcs-table-dialog {
   width: 70%;
}

.full-width {
   width: 100%;
}

.half-width {
   width: 50%;
}