.overlay-panel-contents-wrapper {
   @include background('content-tile');
   position: fixed;
   display: none;
   opacity: 0;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);

   .overlay-panel-contents {
      text-align: left;

      .error-messages-container {
         font-size: 13px;
         min-height: $input-height;
         padding: $input-padding;
         display: flex;
         align-items: center;

         .error-message {
            @include color('alert');
            text-align: left;
            white-space: nowrap;
         }
      }
   }

   .checkbox-wrapper {
      display: flex;
   }
}