.linked-accounts-wrapper {
   border-top: 1px solid #e5e5e5;
}

.linked-accounts {
   max-height: 400px;
   overflow-y: auto;
}

.linked-management-dialog {
   @include small-screen {
      &.ui-dialog,
      &.p-dialog {
         width: 100%;
      }

      .modal-header {
         min-height: min-content;
      }
   }
}

.saveButton {
   padding-top: 10px;
}
