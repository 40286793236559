.lcs-progress-button {
   .mat-button {
      @include color('primary-button-accent');
      &.error,
      &.error[disabled] {
         @include background('alert');
         &:hover {
            @include background('alert-light');
         }
      }
      &.successful,
      &.successful[disabled] {
         @include background('success');
         &:hover {
            @include background('success-light');
         }
      }
      &.warning,
      &.warning[disabled] {
         @include background('warning');
         &:hover {
            @include background('warning-light');
         }
      }
      &.successful,
      &.error {
         color: $white;
         &[disabled] {
            color: $white;
         }
      }
   }
   .mat-raised-button {
      @include background('primary-button-accent');
      @include color("text-primary-button-accent-color");
      &:hover {
         @include color("text-primary-button-accent-color");
      }
      &.error,
      &.error[disabled] {
         @include background('alert');
         &:hover {
            @include background('alert-light');
         }
      }
      &.successful,
      &.successful[disabled] {
         @include background('success');
         &:hover {
            @include background('success-light');
         }
      }
      &.warning,
      &.warning[disabled] {
         @include background('warning');
         &:hover {
            @include background('warning-light');
         }
      }
   }
}