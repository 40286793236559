.lcs-progress-button {
   display: inline-block;
   .mat-button,
   .mat-raised-button {
      .progress-button-text {
         transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
         &.icon-visible {
            color: transparent;
         }
      }
      .mat-button-wrapper {
         height: inherit;
         vertical-align: middle;
         display: flex;
         justify-content: center;
         align-content: center;
         align-items: center;
         .material-icons {
            &.progress-icon {
               font-size: em(24px);
               position: absolute;
               transform: translate(-50%, -50%);
               left: 50%;
               top: 50%;
               align-self: center;
               color: $white;
            }
            &.button-icon {
               font-size: em(24px);
               align-self: center;
               padding-right: 1.25em;
            }
         }
      }
   }
   lcs-loading-overlay {
      .loading-overlay-wrapper {
         .loading-overlay-container {
            background: none;
         }
      }
   }
}
