.site-footer-visible {
   owa-pdf-viewer {
      .pdf-viewer-iframe {
         width: 100%;
         height: calc(
            100vh - #{$site-image-container-height} - #{$top-bar-height} - #{$context-bar-height} - #{$footer-height}
         );
      }
   }
}

owa-pdf-viewer {
   .pdf-viewer-iframe {
      width: 100%;
      height: calc(
         100vh - #{$site-image-container-height} - #{$top-bar-height} - #{$context-bar-height} - #{$footer-height}
      );
   }
   .pdf-viewer-link {
      margin: auto;
      margin-top: 10%;
   }
}

div.slide-panel-content-wrapper {
   owa-pdf-viewer {
      .pdf-viewer-iframe {
         width: 100%;
         height: calc(100vh - #{$top-bar-height} - #{$dialog-header-height});
      }
   }
}

owa-multi-pdf-viewer {
   .sidebar-layout {
      .sidebar-layout-content-wrapper {
         overflow-y: hidden;
         overflow-x: hidden;
      }
   }
   .sidebar-layout-content {
      height: 100%;
   }
}
