a.action-link {
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: left;


   &.button {
      color: $white;
      text-align: center;
      white-space: nowrap;
      text-decoration: none;

      &:hover {
         color: white;
         text-decoration: none;
      }
   }

   &.icon {
      cursor: pointer;

      &:hover {
         text-decoration: none;
      }
   }

   cursor: pointer;

   &:hover {
      text-decoration: none;
    
   }

   &.text-primary {
      @include color("text-primary");
   }

   &.text-accent {
      @include color("text-accent");
   }

   &.not-link {
      cursor: default;
   }

   &.disabled {
      pointer-events: none;
      cursor: default;
   }
}
.drilldown-link {
   overflow: hidden;
   text-overflow: ellipsis;
}
