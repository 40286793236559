label {
   @include color("text-secondary");
   margin: $spacing-md 0 $spacing-xs 0;
   line-height: 1.45;
}

input[type="button"],
button,
input[type="submit"] {
   @include background-color("primary-button-accent");
   width: 100%;
   padding: 16px;
   @include color("text-primary-button-accent-color");
   border: none;
   box-sizing: border-box;
   position: relative;
   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
   cursor: pointer;
   outline: 0;
   -webkit-tap-highlight-color: transparent;
   display: inline-block;
   white-space: nowrap;
   text-decoration: none;
   vertical-align: baseline;
   text-align: center;
   margin: 0;
   min-width: 64px;
   border-radius: 4px;
   overflow: visible;
}

lcs-text-area {
   &.email {
      textarea {
         resize: none;
         height: 120px !important;
      }
   }
}

lcs-text-area {
   &.update-personal-information {
      textarea {
         resize: none;
         height: 85px !important;
      }
   }
}

lcs-text-box,
lcs-text-area,
lcs-date-picker,
lcs-time-picker,
i,
.datatable-control,
lcs-calculator-input,
.validation-wrapper,
lcs-numeric-input,
lcs-phone-number,
owa-numeric-input,
rmx-secure-input,
owa-phone-number {
   position: relative;

   .validation-error-icon {
      @include color("alert");
      visibility: hidden;
   }

   &.ng-invalid.ng-dirty,
   &.ng-invalid.ng-touched {
      .validation-error-icon {
         visibility: visible;
      }

      input[type="text"],
      input[type="search"],
      input[type="password"],
      input[type="number"],
      textarea {
         @include border-color("alert");
         padding-right: 26px;

         &:focus {
            @include border-color("alert");
            @include outline-color("alert");
            box-shadow: none !important;
            border: 1px solid;
            outline-style: double;
            outline-width: 1px;
         }
      }
   }
}

.validation-error-icon {
   @include color("alert");
   position: absolute;
   top: 6.5px;
   right: 4.5px;
   font-size: $spacing-lg;
   text-align: center;
   pointer-events: none;
}

lcs-calculator-input.ng-invalid.ng-dirty,
lcs-calculator-input.ng-invalid.ng-touched {
   input {
      @include border-color("alert");

      &:focus {
         @include border-color("alert");
         @include outline-color("alert");
         box-shadow: none !important;
         border: 1px solid;
         outline-style: double;
         outline-width: 1px;
      }
   }

   .rmicon-calculator {
      display: none;
   }
}

lcs-input-with-options .input-with-options-wrapper {
   display: flex;
   align-items: center;
   position: relative;

   &:after {
      border-bottom: 2px solid #90a1b5;
      border-right: 2px solid #90a1b5;
      content: "";
      display: block;
      height: 7px;
      position: absolute;
      width: 7px;
      right: 7px;
      pointer-events: none;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
   }

   &.open:after {
      -webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
   }

   .text-box {
      flex-grow: 1;
   }
}

lcs-date-picker .validation-error-icon {
   right: $spacing-lg;
   @include color("alert");
}

p-calendar.ng-invalid.ng-touched>.ui-calendar>.ui-inputtext,
p-calendar.ng-invalid.ng-dirty>.ui-calendar>.ui-inputtext,
p-calendar.ng-invalid.ng-touched .p-calendar .p-inputtext,
p-calendar.ng-invalid.ng-dirty .p-calendar .p-inputtext {
   @include border-color("alert");
}

p-calendar>.ui-calendar>.ui-inputtext,
p-calendar .p-calendar .p-inputtext {
   padding-right: 7px;
   padding-left: 0.5em;
}

lcs-date-picker,
lcs-time-picker,
lcs-text-box,
lcs-text-area,
lcs-calculator-input,
lcs-select,
lcs-color-picker,
lcs-date-time-picker,
lcs-date-range-picker,
lcs-input,
lcs-numeric-input,
lcs-phone-number,
owa-phone-number,
owa-numeric-input,
rmx-secure-input {
   display: block;
}

lcs-date-time-picker {
   .date-picker-wrapper {
      margin-right: 0.5em;
   }
}

.calculator-input {
   input {
      position: relative;
      padding-right: $spacing-xl;
   }

   span {
      position: absolute;
      bottom: 8px;
      right: 5.9px;
      font-size: 1.3em;
      cursor: pointer;
   }
}

.material-icons.visibility-icon {
   position: absolute;
   bottom: 8px;
   right: 5.9px;
   cursor: pointer;
   color: lighten(#303030, 40%);

   &.visibility-on {
      color: #303030;
   }
}

.calculator-input-history-total {
   position: relative;
   padding-bottom: 30px;
   max-height: 320px;
   background-color: white;
}

.calculator-input-history-content {
   text-align: right;
   overflow-y: auto;
   max-height: 300px;
}

.calculator-history-operand {
   padding-top: 0;
   padding-bottom: 0;
}

.calculator-history-operator {
   float: left;
}

.calculator-overlay-item {
   padding: $spacing-xs $spacing-sm;
   margin-bottom: 0.3em;
   height: 1.4em;
}

.first-history-container {
   padding: $spacing-sm;
}

.select,
textarea,
[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"] {
   @include border-color("input-border");
   @include background-color("input-background");
   @include color("text-primary");
   @include font-primary;
   display: block;
   box-sizing: border-box;
   width: 100%;
   height: $input-height;
   padding: $input-padding;
   border: 1px solid;
   border-radius: 2px;
   font-family: inherit;
   font-size: $spacing-md;
   font-weight: normal;
   -webkit-appearance: none;
   -moz-appearance: none;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
   box-shadow: none;
   outline: none;
   -webkit-transform: scale3d(1, 1, 1);

   &:focus,
   &.open {
      @include border-color("focus");
      box-shadow: none !important;
      border: 1px solid;
      box-sizing: border-box;
   }
}

textarea {
   font-weight: normal;
}

input[type="text"].white,
input[type="search"].white,
select.white,
.select.white,
textarea.white {
   background-color: $white;
}

textarea.datatable-control {
   min-height: $input-height;
   border-radius: unset;
}

textarea.history-note {
   width: 500px;
   height: 150px;
}

.total-container {
   @include border-color("ui-03");
   position: absolute;
   width: 100%;
   border-top: 1px solid;
   margin-top: -30px;
   margin-bottom: 0;
   padding: 0 $spacing-sm;
   bottom: 2px;
   background-color: white;

   .total-item {
      margin-top: 2px;
   }

   .total-label {
      padding-left: 0.1em;
      float: left;
   }

   .total-amount {
      padding: 0.1em;
      float: right;
   }
}

.checkbox-container {
   display: flex;
   justify-content: space-around;
}

.font-container {
   display: flex;
   flex-wrap: nowrap;
}

.starting-label-container {
   display: flex;
   justify-content: space-between;
}

div,
a {
   &.icon-button {
      @include background-color("primary-button-accent");
      @include color("text-primary-button-accent-color");
      height: calc(#{$input-height} - 1px);
      width: calc(#{$input-height} - 1px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $spacing-sm;
      border-radius: 2px;
      cursor: pointer;

      &:before {
         font-size: em(12px);
      }

      &.disabled {
         @include background-color("disabled");
         color: $white;
         pointer-events: none;
      }

      &:hover:not(.disabled) {
         @include background-color("primary-lighten-5");
      }

      svg {
         height: 25px;
         width: 25px;
      }
   }
}

input[type="number"][id="contact-annual-income"]::-webkit-outer-spin-button,
input[type="number"][id="contact-annual-income"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
}

rmx-confirm-date-picker {
   .confirm-date-picker-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      lcs-date-picker {
         flex: 1 1;
      }

      lcs-checkbox {
         .checkbox-wrapper {
            margin: 0;
         }
      }

      .icon-button {
         @include background-color("primary-button-accent");
         @include color("text-primary-button-accent-color");
      }

      &.confirmed {

         lcs-date-picker input,
         .icon-button {
            @include background-color("success");
            color: $white;

            &:hover:not(.disabled) {
               @include background-color("success");
            }
         }
      }
   }
}

lcs-date-picker {
   position: relative;
   border-radius: $border-radius;
   border: 1px solid;
   box-sizing: border-box;
   height: $input-height;
   @include border-color("input-border");

   input[type="text"] {
      height: 100%;
      // override $input-height to make sure date-picker has same height as other input elements
   }

   .validation-error-icon {
      @include color("alert");
      visibility: hidden;
   }

   &.ng-invalid.ng-dirty,
   &.ng-invalid.ng-touched {
      @include border-color("alert");

      .validation-error-icon {
         visibility: visible;
      }

      &:focus-within {
         @include border-color("focus");
      }

      input[type="text"] {
         padding-right: 26px;
      }
   }

   &:focus-within {
      @include border-color("focus");
   }

   &.disabled {
      @include disabled;
   }
}

rmx-workflow-wrapper {
   rmx-entity-information-form-section {
      .form-element {
         height: calc(#{$input-height} + #{$label-height});

         >lcs-checkbox,
         rmx-dynamic-form-input>lcs-checkbox {
            .checkbox-wrapper {
               margin-top: $label-height;
               height: $input-height;
               display: flex;
               align-items: center;
            }
         }

         &.rent-period {
            min-height: calc(#{$input-height} + #{$label-height});
            height: 100%;
         }
      }
   }
}

mat-radio-group {
   display: flex;
   flex-direction: column;

   .radio-group-horizontal {
      flex-direction: row;
   }

   .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      @include border-color("primary");
   }

   .mat-radio-disabled {
      .mat-radio-label-content {
         @include color("text-primary");
      }
   }

   .mat-radio-label-content {
      padding-top: $spacing-xs;
   }

   .mat-radio-button.mat-accent .mat-radio-inner-circle {
      @include color("primary");
      @include background-color("primary");
   }

   .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
      @include background-color("primary");
   }

   mat-radio-button {
      padding: $spacing-tile;

      .mat-radio-label {
         overflow: visible;
         white-space: normal;
      }

      .mat-radio-ripple {
         left: calc(50% - 15px) !important;
         top: calc(50% - 15px) !important;
         height: 30px !important;
         width: 30px !important;
      }
   }
}

.checkbox-wrapper {
   display: flex;
   margin: $spacing-sm 0;
   user-select: none;
   align-items: center;

   &:not(.checkbox-disabled) {
      cursor: default;

      .checkbox-label {
         cursor: pointer;
      }
   }

   .checkbox-label {
      padding: 0.2em $spacing-sm 0 0;
      margin: 0;
      font-family: $font-family--primary;
      width: auto;
      max-width: 100%;

      &:empty {
         display: none;
      }

      &:not(.label-enabled, .field-placeholder-input) {
         @include disabled;
      }
   }

   p-checkbox {
      margin-right: 0.5em;
      flex-shrink: 0;
   }
}