owa-footer {
   width: 100%;
   .button-group {
      margin: $spacing-sm;
   }
   .footer {
      @include background-color('ui-footer');
      @include border-color('border');
      border-top: 1px solid;
      padding: 0 $spacing-md;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: $footer-height;
      @include small-screen {
         width: 100%;
      }      
      .footer-errors {
         height: 100%;
         cursor: pointer;
         margin-right: $spacing-md;
         .footer-errors-label {
            @include color('alert');
            height: 100%;
            display: flex;
            padding: 0 $spacing-md;
            align-items: center;
            i {
               margin-right: $spacing-xs;
            }
         }
      }
      &.empty {
         height: 0;
         border-color: transparent;
      }
      button, input[type="button"]{
         margin: 0;
         width: inherit;
         padding: .8em;
         line-height: 1;
      }
   }
}

.footer-validation-summary .error-message {
   cursor: pointer;
   padding: 0.5em;
}

.modal-container.ui-widget-content owa-footer .footer {
   @include background-color('context-bar');
   padding: 0 $dialog-padding;
   grid-row: 3;
}
