.PacketBanner {
   margin: 0 auto;
   font-size: 12px;
   margin-bottom: 10px;
   margin-top: 10px;
   overflow: hidden;
   -webkit-user-select: none;
   /* Chrome/Safari */
   -moz-user-select: none;
   /* Firefox */
   -ms-user-select: none;
   /* IE10+ */
   /* Rules below not implemented in browsers yet */
   -o-user-select: none;
   user-select: none;
}

.PacketBanner .PacketBanner-DocumentSummary {
   margin: 0 auto;
   text-align: center;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-Name {
   font-size: 22px;
   width: 100%;
   margin: 0 auto;
   text-align: center;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-Name>div {
   display: inline-block;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-PacketName {
   padding-right: 5px;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-DocumentName {
   font-weight: bold;
   padding-right: 5px;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-Info {
   width: 100%;
   margin: 0 auto;
   text-align: center;
   padding-bottom: 1em;
   padding-top: 1em;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-Info>div {
   display: inline-block;
   padding-right: 5px;
}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-DocumentProgress {}

.PacketBanner .PacketBanner-DocumentSummary .PacketBanner-DocumentSummary-SignatureProgress {}

.PacketBanner .PacketBanner-ChangeDocumentFlyout {
   display: none;
   width: 100%;
   padding-top: 5px;
   position: relative;
   white-space: nowrap;
   color: black;
}

.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile {
   display: inline-block;
   background-color: #f4f4f4;
   border: 1px solid darkgray;
   padding: 10px;
   margin-right: 10px;
   cursor: pointer;
   vertical-align: bottom;
}

.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Image {
   height: 80px;
   display: inline-block;
}

.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info {
   width: 150px;
   padding: 0;
   display: inline-block;
   min-height: 131px;
}

.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info-Name {
   /*color: #07ab14;*/
   font-weight: 400;
   font-size: 15px;
   width: 150px;
   overflow: hidden;
   text-overflow: ellipsis;
   margin: 0;
}

.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info-PageCount,
.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info-DocumentProgress,
.PacketBanner .PacketBanner-ChangeDocumentFlyout .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info .PacketBanner-ChangeDocumentFlyout-DocumentNavigationTile-Info-SignatureProgress {
   padding-bottom: 5px;
}

.PacketBanner .PacketBanner-ChangeDocumentButton {
   font-size: 12px;
   margin: 0 auto;
}

#pdfIFrameWrapper {}

#pdfViewer,
#pdfViewerOverlay {
   top: 220px;
   width: 100%;
   margin: 0 auto;
   display: block;
}

#pdfViewerOverlay {
   background: transparent;
   position: relative;
}

@media screen and (max-width:640px) {
   body {
      min-width: 100% !important;
   }
}