.page {
   display: flex;
   flex-direction: column;
}

.page-header {
   flex: 0 0 auto;
}

.page-content {
   flex: 1 1 auto;
   position: relative;
   overflow-y: auto;
   height: 100%;
   display: flex;
}

owa-layout {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   height: 100%;

   .layout-wrapper {
      @include background-color("ui-background");
      overflow-y: auto;
      height: 100%;
   }

   .content-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      height: calc(100% - 4em);
   }
}

.loading-overlay-wrapper {
   height: 100%;

   .site-content {
      height: 100%;
   }
}

owa-report-hub {
   display: flex;
   flex-direction: column;
   height: 100%;

   form {
      flex: 1;
      display: flex;
      flex-direction: column;
   }
}

owa-rm-report-hub {
   flex: 1;
   display: flex;
   flex-direction: column;
}

.flex-wrapper {
   @include flexy($wrap: wrap, $dir: row);
   padding: 0 $spacing-sm;
}

owa-dashboard-list {
   .content-wrapper {
      padding: $spacing-md;

      @include small-screen {
         padding: $spacing-md $spacing-sm;
      }
   }
}

owa-bill-list,
owa-check-list,
owa-owner-distribution-list,
owa-management-fees-list-view,
owa-management-fees-list,
owa-service-issue-list,
owa-owner-contributions-list,
owa-owner-contributions,
owa-history-notes-list,
owa-ownerships-list-view,
owa-ownerships-list,
owa-inspection-report-list,
.ownerships-section,
.management-fees-section,
.owner-contribution-section {
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 100%;
}

owa-estimate-list-container{
   .tile-wrapper{
      height: auto;
   }
}

.layout-flex {
   display: flex;
   flex-direction: column;
   flex: 1;
}

.default {
   background-color: $white;
}

.detail-layout {
   margin: 0;
   background-color: #f2f5fa !important;
}

.table {
   min-height: min-content;
   display: flex;
}

.scoreboard {
   @include color("text-primary");
   font-family: $font-family--primary;
   padding: 0;
   margin: 0;
   font-size: 0.6em;
   line-height: 1;
}

.scoreboard-wrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding: 1em;
   width: 100%;

   span {
      padding: 0.25em;
   }

   .item-bold {
      font-weight: 600;
   }

   .item-color {
      @include color("primary-icons");
   }

   .ownership {
      display: flex;
      align-items: center;
   }
}

.scoreboard-item {
   display: grid;
   padding-left: 1em;
}

.owner-prospect-information {
   .scoreboard-label {
      @include color("primary");
   }

   .scoreboard-item {
      padding-bottom: 1em;
   }

   .prospect-address {
      padding: 0;
      white-space: pre-line;
   }

   .prospect-phone {
      padding: 0;
   }
}

.single-ownership {
   .scoreboard-item {
      padding-bottom: 1em;
   }

   .ownership {
      display: flex;
      align-items: center;
   }

   @include small-screen {
      .scoreboard-item {
         padding-bottom: 0.25em;
      }
   }
}

.header-message {
   display: flex;
   justify-content: space-between;
   flex: 0 0 auto;
}

.sidebar-layout {
   @include grid-layout;
   @include background-color("content-tile");
   grid-template-columns: auto 1fr auto;
   grid-template-rows: 1fr;
   height: 100%;

   @include medium-screen {
      grid-template-columns: 1fr;
      grid-template-rows: max-content;
   }

   @include small-screen {
      grid-template-columns: 1fr;
      grid-template-rows: max-content;
   }
}

.sidebar-layout .expandable-label {
   @include medium-screen {
      @include color("primary");
      border: none;
      font-size: 1em;
      padding: $spacing-sm $spacing-sm $spacing-sm 0;
      margin-bottom: 0;
      margin-top: $spacing-sm;
   }
}

.sidebar-layout-sidebar-wrapper {
   &.left .sidebar-layout-sidebar {
      @include border-color("border");
      border-right: 1px solid;

      @include medium-screen {
         border-right: none;
      }
   }

   &.right .sidebar-layout-sidebar {
      @include border-color("border");
      border-left: 1px solid;

      @include medium-screen {
         border-left: none;
      }
   }

   .sidebar-layout-sidebar {
      @include color("text-primary");
      @include background-color("sidebar");
      @include grid-layout();
      grid-template-rows: auto 1fr;
      overflow-y: auto;
      width: 320px;
      height: 100%;

      @include medium-screen {
         width: 100%;
      }

      @include small-screen {
         width: 100%;
      }

      .sidebar-layout-list-item {
         border-left: $sidebar-layout-sidebar-item-border-left-width solid transparent;
         line-height: #{$sidebar-layout-sidebar-item-height};
         padding: 0 $sidebar-layout-sidebar-item-padding;
         cursor: pointer;

         &:active:not(.disabled),
         &.selected:not(.disabled) {
            @include border-left-color("primary");
            @include color("primary");
            border-left: $sidebar-layout-sidebar-item-border-left-width solid;
         }

         &:hover {
            @include hover-transition;
            @include background-color("input-background");
         }

         &.disabled {
            @include background-color("input-background");
            color: inherit;
            cursor: not-allowed;
         }
      }

      .sidebar-layout-draggable-list-item {
         @include background-color("input-background");
         padding: 8px 0 8px 10px;
         margin: 15px;
         border-radius: 2px;
         font-size: em(14px);
         cursor: move;

         &:hover {
            @include hover-transition;
            @include background-color("input-background");
         }
      }

      .sidebar-layout-sidebar-information {
         @include flexy($wrap: wrap);
         justify-content: space-between;
         padding: $spacing-sm $spacing-md;

         &:first-of-type {
            margin-top: $spacing-sm;
         }

         .label-with-text {
            @include color("text-primary");
            font-weight: $font-weight--semi-bold;
            line-height: 1.45;
         }

         .value {
            @include color("text-accent");
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.45;

            &.link {
               @include color("primary");
            }

            &.disabled {
               @include disabled;
            }
         }
      }
   }
}

.sidebar-layout-sidebar-header {
   font-weight: normal;
   @include background-color("content-tile");
   @include sticky;
}

.sidebar-layout-sidebar-header,
.sidebar-layout-content-header-wrapper {
   @include color("text-primary");
   height: #{$sidebar-layout-sidebar-item-height};
   padding-left: $base__font-size;
   padding-right: $base__font-size;
   display: flex;
   align-items: center;
   margin: 0;

   @include medium-screen() {
      padding: $spacing-sm 0;
      height: auto;
   }
}

.sidebar-layout-sidebar-header,
.sidebar-layout-content-header-wrapper,
.sidebar-layout-list-item {
   @include border-color("border");
   border-bottom: 1px solid;
}

.sidebar-layout-sidebar-wrapper,
.sidebar-layout-content-wrapper {
   @include background-color("content-tile");

   @include medium-screen {
      height: 100%;
      padding: 0;
   }
}

.modal-container {
   .sidebar-layout,
   .sidebar-layout-sidebar-wrapper,
   .sidebar-layout-sidebar-wrapper .sidebar-layout-sidebar,
   .sidebar-layout-content-wrapper {
      background-color: inherit;
   }
}

.sidebar-layout-content-wrapper {
   overflow-y: auto;

   .sidebar-layout-content {
      height: 100%;
   }

   .sidebar-layout-content-header-wrapper {
      @include background-color("content-tile");
      @include sticky;
      justify-content: space-between;
      padding: $spacing-md;

      .sidebar-layout-content-header {
         font-weight: normal;

         .sidebar-layout-content-information-header {
            @include color("text-accent");
            font-weight: $font-weight--normal;
         }

         h1,
         h2,
         h3,
         h4,
         h5 {
            margin: 0;
            font-weight: normal;
            display: inline;

            @include medium-screen {
               display: block;
            }
         }
      }
   }
}

.sidebar-layout-sidebar-wrapper {
   @include medium-screen() {
      padding-left: $spacing-md;
      padding-right: $spacing-md;

      .sidebar-layout-sidebar {
         border: none;
      }

      .sidebar-layout-sidebar-content.editable {
         padding: $spacing-md;
      }
   }
}
owa-epay,
owa-epay-ach,
owa-epay-cc {
   p-checkbox {
      margin-right: 0.5em;
   }
   form {
      max-width: 850px;
   }
   .checkbox-wrapper {
      margin-left: 0.5em;
      margin-right: 0.5em;
      text-align: justify;
   }
}
