.multi-document-export-viewer-wrapper {
   height: 100%;
}

lcs-multi-document-export-viewer {
   height: 100%;

   .cached-document-list {
      height: 100%;
      width: 100%;

      &.unselected-document {
         height: 0px;
         overflow: hidden;
      }
   }

   iframe {
      height: 100%;
      width: 100%;
   }
}

lcs-single-document-export-viewer {
   height: 100%;

   lcs-single-document-export-iframe-viewer {
      display: block;
      height: 100%;
      overflow: hidden;

      iframe {
         height: 100%;
         width: 100%;
      }
   }

   lcs-link {
      a {
         display: inline-block !important;
         min-width: $input-width;
      }
   }
}

.report-document-export-wrapper {
   @include flexy($dir: column);
   height: 100%;

   lcs-document-export-viewer,
   lcs-multi-document-export-sidebar-viewer {
      height: 100%;
   }
}

lcs-sidebar-layout-list,
lcs-sidebar-layout-list {
   .right-wrapper {
      @include color("text-primary");
      @include background-color("sidebar");
      overflow-y: auto;
      width: $sidebar-layout-sidebar-width;
   }

   .center-wrapper {
      flex: 1;
      overflow-y: auto;
   }

   .overlay {
      @include small-screen {
         width: auto;
      }
   }

   .left-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid;
      @include border-color("border");
      @include color("text-primary");
      @include background-color("sidebar");
      width: $sidebar-layout-sidebar-width !important;

      &.left-wrapper-active-mobile {
         width: 100% !important;
      }

      .selectAll {
         line-height: #{$sidebar-layout-sidebar-item-height};
         padding: 0 $sidebar-layout-sidebar-item-padding + $sidebar-layout-sidebar-item-border-left-width;
         @include background-color("sidebar");
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
         cursor: pointer;
         border-bottom-width: 2px;
         flex-shrink: 0;
      }

      .left-list {
         flex: 1 1 auto;
         overflow-y: auto;
      }

      .left-bottom-wrapper {
         flex-shrink: 0;
         border-top: 2px solid;
         @include border-color("border");
         padding: 0px 8px;
      }
   }

   .select-checkbox {
      flex-shrink: 0;
   }

   .hide-overflow {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
   }

   &.responsive-layout {
      position: relative;
      flex-wrap: wrap;

      .left-wrapper {
         position: absolute;
         top: 0;
         height: 100%;
         z-index: 2;
         transition: ease-in-out $layout-animation-transition-time;
      }

      .center-wrapper {
         flex: 1 0 auto;
         overflow-y: auto;
         height: 100%;
      }

      .right-wrapper {
         width: 100%;
         height: auto;
         overflow-y: unset;
      }

      .left-bottom-wrapper {
         bottom: 0;
      }

      &.overlay-active {
         #overlay {
            position: fixed;
            width: 100%;

            @include small-screen {
               width: auto;
            }

            height: 100%;
            z-index: 2;
            @include background-color("menu-overlay");
         }

         .left-wrapper {
            @include box-shadow("menu-boxshadow");
         }
      }
   }

   .sidebar-layout-list-item {
      border-left: $sidebar-layout-sidebar-item-border-left-width solid transparent;
      line-height: #{$sidebar-layout-sidebar-item-height};
      padding: 0 $sidebar-layout-sidebar-item-padding;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      cursor: pointer;

      &:active:not(.disabled),
      &.selected:not(.disabled) {
         @include border-left-color("primary");
         border-left: $sidebar-layout-sidebar-item-border-left-width solid;
         background-color: var(--listbox-selected);
      }

      &:hover {
         @include hover-transition;
         @include background-color("input-background");
      }

      &.disabled {
         @include background-color("input-background-disabled");
         color: inherit;
         cursor: not-allowed;
      }
   }
}

lcs-document-export-viewer {
   lcs-multi-document-export-sidebar-viewer lcs-sidebar-layout-list {
      .left-wrapper .left-bottom-wrapper {
         padding: $spacing-sm;
      }
   }
}