.site-spinner>.loading-overlay-wrapper>.loading-overlay-container {
   z-index: $spinner-overlay-z-index;
   height: 100vh;
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}

.content-spinner>.loading-overlay-wrapper>.loading-overlay-container {
   z-index: $content-spinner-overlay-z-index;
}

.mat-spinner,
.mat-progress-spinner {
   circle {
      @include stroke("primary");
   }
   &.dark {
      circle {
         stroke: white;
      }
   }
   &.light {
      circle {
         @include stroke("menu");
      }
   }
}

lcs-loading-overlay {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   flex: 1;
   .loading-overlay-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
   }
   .loading-overlay-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @include color("text-accent");
      display: flex;
      justify-content: center;
      align-items: center;
      @include background-color("input-background");
      opacity: 0.8;
      .loading-overlay {
         &.dark {
            @include background-color("menu");
         }
         &.transparent {
            @include background-color("menu");
            opacity: 0.2;
         }
      }
   }
}
