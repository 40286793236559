lcs-table {
   display: block;
   width: 100%;
}

.lcs-table {
   border-collapse: collapse;
   table-layout: fixed;
   width: 100%;
   overflow-y: auto;
}

.lcs-header-row {
   @include background-color("datatable-header");
   height: 3em;
}

.lcs-header-cell {
   @include color("text-secondary");
   @include background-color("datatable-header");
   @include border-bottom-color("datatable-border");
   border-bottom: 1px solid;
   text-transform: uppercase;
   font-weight: 600;
   letter-spacing: 1px;
   font-size: em(14px);
   text-align: center;
}

.lcs-row:hover {
   @include hover-transition;
   @include background-color("datatable-hover");
}

.lcs-cell {
   @include border-bottom-color("datatable-border");
   border-bottom: 1px solid;
   padding: $spacing-sm;
   white-space: pre-wrap;
   text-align: center;
   line-height: 1.45;

   &.has-drilldown {
      padding: 0;

      .drilldown-link {
         @include color("text-primary");
         display: block;
         padding: $spacing-sm;
      }
   }
}

.lcs-table-responsive-item {
   display: flex;
   align-items: center;
}

.lcs-table-responsive-item-header {
   flex-basis: 10em;
   .register-column-header-name {
      text-overflow: ellipsis;
   }
}

.lcs-table-responsive-item-header .lcs-header-cell {
   @include color("text-primary");
   @include background-color("datatable-header");
   border-bottom-width: 0;
   font-size: 1em;
   letter-spacing: initial;
   padding: $spacing-sm $spacing-md;
   text-align: left;
   text-transform: none;
}

.lcs-table-responsive-item-cell {
   flex: 1;
}

.lcs-table-responsive-item-cell .lcs-cell {
   border-bottom-width: 0;
   display: block;
   overflow-x: hidden;
   padding: $spacing-sm $spacing-md;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 100% !important;
}

.lcs-column-responsive-toggle {
   padding: 0;
}

.lcs-column-responsive-toggle .material-icons {
   cursor: pointer;
   width: 100%;
   height: 100%;
   padding: $spacing-sm;
}
