.top-bar {
   @include background-color("primary");
   display: flex;
   height: 50px;
   color: #fff;
   width: 100%;
   &-visible {
      display: block;
   }
}

.top-bar,
.top-bar-container {
   padding: 0;
}

.top-bar-container {
   display: none;
}

.site-image-container {
   @include background-color("ui-background");
   min-height: $site-image-container-height;
   max-height: 150px;
   //display: flex;
   align-items: center;
   position: relative;
   img {
      max-height: 150px;
   }
   @include small-screen {
      display: block;
      padding: $spacing-sm;
      max-height: unset;
      height: auto;
      // text-align: center;
   }
   img {
      width: auto;
      max-height: 100%;
   }
}

.owa-svg-container {
   @include background-color("ui-background");
}

.site-menu-inner {
   width: 100%;
   display: flex;
   align-items: middle;
}

.menu-content-wrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   width: 100%;
   overflow: auto;
   overflow-y: hidden;
}

ul.owa-desktop-menu {
   white-space: nowrap;
   overflow: hidden;
   flex: 1;
   min-width: 0;
   @include medium-screen {
      display: none;
   }
}

.active-menu-item {
   @include background-color("primary-darken-5");
}
