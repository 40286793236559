// Font Stacks
$font-url--mat-icons: "https://fonts.googleapis.com/icon?family=Material+Icons";
$font-url--secondary: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
$font-url--primary: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700";
$font-family--secondary: "Open Sans", sans-serif !default;
$font-family--primary: "Roboto", sans-serif !default;
// Font Weights
$font-weight--light: 300 !default;
$font-weight--normal: 400 !default;
$font-weight--semi-bold: 600 !default;
$font-weight--bold: 700 !default;
// Color Usage
$white: #fff !default;
$black: #000 !default;
$border-radius: 4px !default;
$border-color: #e5e5e5 !default;
// Text
$base__font-size: 16px !default;
$base__line: 24px !default;
$letter-space: 1px !default;
$input-height: 34.125px !default;
$input-padding: 0.5em !default;
$input-width: 200px !default;
// Tile Layouts
$label-height: 34px !default;
$input-wrapper-height: 70px !default;
// Layout Animations
$layout-animation-transition-time: 0.2s !default;
// Flexible tile calculations
$max-width: 1000px;
$gutter: 0px;
// Layout dimensions
$footer-height: 50px !default;
$modal-footer-height: 50px !default;
$action-bar-width: 36px !default;
$context-bar-height: 56px !default;
$top-bar-height: 60px !default;
// Datatable dimensions
$top-filter-section-height: 50px !default;
$filter-button-height: 50px !default;
$filter-section-width: 340px !default;
$table-header: 3em !default;
$datatable-fixed-height: 290px !default;
// Dialog
$dialog-padding: 24px !default;
$dialog-header-height: 48px !default;
$dialog-max-height: 75vh !default;
// Reports
$menu-width: 21em !default;
$menu-item-height: 3em !default;
$report-parameter-label-height: 19px !default;
// z-indexes
$full-menu-z-index: 100000 !default;
$content-spinner-overlay-z-index: calc(#{$full-menu-z-index} - 1) !default;
$favorites-tooltips-z-index: calc(#{$full-menu-z-index} + 1) !default;
$spinner-overlay-z-index: calc(#{$favorites-tooltips-z-index} + 1) !default;
$image-carousel-control-z-index: 1 !default;
$context-bar-z-index: calc(#{$full-menu-z-index} - 1) !default;
$side-bar-menu-z-index: calc(#{$full-menu-z-index} - 1) !default;
$multiselector-height: 200px !default;
// Media Query Sizes
$small-width: 40em !default;
$medium-width: 64em !default;
$dropdown-trigger-width: 1.5em !default;
$scrollbar-width: 17px !default;
// Workflow
$workflow-step-sidebar-width: 20em !default;
// Spacing
$spacing-tile: 0.75em !default;
$spacing-xs: 0.25em !default;
$spacing-sm: 0.5em !default;
$spacing-md: 1em !default;
$spacing-lg: 1.5em !default;
$spacing-xl: 2em !default;
$spacing-2xl: 2.5em !default;
$spacing-3xl: 3em !default;
// sidebar-layout Layout
$sidebar-layout-sidebar-width: 300px !default;
$sidebar-layout-sidebar-item-border-left-width: 5px !default;
$sidebar-layout-sidebar-item-height: 50px !default;
$sidebar-layout-sidebar-item-padding: 20px !default;
// Contacts
$contact-icon-size: 24px !default;
// Multi pdf viewer
$selector-section-height: 50px !default;
$selector-label-height: 24px !default;
