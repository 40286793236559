/* theme maps */

$themes: (
   default: (
      "link": #2c77bc,
      //  "primary": #f28228,
      "primary": var(--owa-theme-color, #f28228),
      "primary-lighten-5": lighten(#f28228, 5%),
      "primary-lighten-10": lighten(#f28228, 10%),
      // "primary-darken-5": darken(#f28228, 5%),
      "primary-darken-5": var(--owa-theme-color-darken-5, darken(#f28228, 5%)),
      // "primary-darken-10": darken(#f28228, 10%),
      "primary-darken-10": var(--owa-theme-color-darken-10, darken(#f28228, 10%)),
      "secondary": #2c77bc,
      "secondary-lighten-5": lighten(#2c77bc, 5%),
      "secondary-lighten-10": lighten(#2c77bc, 10%),
      "secondary-darken-5": darken(#2c77bc, 5%),
      "secondary-darken-10": darken(#2c77bc, 10%),
      "accent": #a0cf67,
      "accent-lighten-5": lighten(#a0cf67, 5%),
      "accent-lighten-10": lighten(#a0cf67, 10%),
      "accent-darken-5": darken(#a0cf67, 5%),
      "accent-darken-10": darken(#a0cf67, 10%),
      "alt": #11457a,
      "alt-lighten-5": lighten(#11457a, 5%),
      "alt-lighten-10": lighten(#11457a, 10%),
      "alt-darken-5": darken(#11457a, 5%),
      "alt-darken-10": darken(#11457a, 10%),
      "alert": #eb343c,
      "alert-light": lighten(#eb343c, 5%),
      "alert-dark": darken(#eb343c, 5%),
      "success": #6db844,
      "success-light": lighten(#6db844, 5%),
      "success-dark": darken(#6db844, 5%),
      "warning": #fcb716,
      "warning-light": lighten(#fcb716, 5%),
      "warning-dark": darken(#fcb716, 5%),
      // App Background
      "ui-background": #f2f5fa,
      "ui-content": #ffffff,
      // Top Bar
      "top-bar": #f58220,
      "top-bar-favorite": #ffffff,
      "top-bar-image-background": var(--site-header-background-color, #f2f5fa),
      // Logo Fill
      "app-logo": #ffffff,
      "login-logo": #303030,
      // Context Bar
      "context-bar": #f0f0f0,
      // Text
      "text-primary": #303030,
      "text-primary-toolbar": var(--owa-theme-text-contrast-color, #ffffff),
      "text-primary-button-accent-color": var(--owa-theme-text-button-accent-contrast-color, #ffffff ),
      "calendar-text-primary-button-accent-color": var(--owa-theme-text-button-accent-contrast-color, #ffffff ) !important,
      "text-secondary": #606060,
      "text-accent": #909090,
      "text-inverse": #303030,
      // Menu
      "menu": #fff,
      "menu-dark": #f5f5f5,
      "menu-light": #fff,
      "menu-hover": #f2f5fa,
      "menu-border": darken(#fff, 10%),
      "menu-link": #2c77bc,
      // "menu-link": var(--owa-theme-text-contrast-color, #2c77bc),
      "menu-hover-link": #2c77bc,
      "menu-header": #303030,
      "menu-overlay": rgba(128, 128, 128, 0.6),
      "menu-boxshadow": 0 0 40px rgba(0, 0, 0, 0.3),
      // Actions
      "action-bar": #464c53,
      "action-bar-icon": #fff,
      // Buttons
      "button-overlay": rgba(0, 0, 0, 0.05),
      //Button accent color
      "primary-button-accent": var(--owa-theme-button-accent-color, #f28228),
      "primary-button-accent-darken-5": var(--owa-theme-button-accent-color-darken-5, darken(#f28228, 5%)),
      "primary-icons": var(--primary-icons, #f28228),
      "primary-icons-lighten-5": var(--primary-icons-lighten-5, lighten(#f28228, 5%)),
      "primary-filter-link-icons": var(--primary-icons, #2c77bc),
      "reports-tooltip": var(--owa-theme-button-accent-color, #2c77bc),
      "filters-hamburger-menu": var(--owa-theme-button-accent-color, #f5f5f5),
      "filter-text": var(--filter-text,#f28228),
      // Tiles
      "content-tile": #ffffff,
      "summary-tile-heading": #f9f9f9,
      // Global
      "ui-01": #f0f0f0,
      "ui-02": #f5f5f5,
      "ui-03": #e8e8e8,
      "ui-04": #464c53,
      "ui-05": #666666,
      "ui-06": #999999,
      // Borders
      "border": #e5e5e5,
      "border-light": #f5f5f5,
      "border-dark": #d5d5d5,
      // Datatable
      "datatable-header": #f5f5f5,
      "datatable-group": lighten(#f5f5f5, 2%),
      "datatable-row": #ffffff,
      "datatable-hover": lighten(#f5f5f5, 3%),
      "datatable-border": #e5e5e5,
      "datatable-expanded": #f9f9f9,
      // Input
      "input-background": #f5f5f5,
      "input-filter": #ffffff,
      "input-border": #d5d5d5,
      "input-icon": #999999,
      "focus": #2c77bc !important,
      "overlay": #ffffff,
      "overlay-hover": #f5f5f5,
      "overlay-header": #e5e5e5,
      "overlay-radius": 3px,
      "overlay-boxshadow": 0 0 6px rgba(0, 0, 0, 0.4),
      "selected": lighten(#2c77bc, 5%),
      "selected-hover": lighten(#2c77bc, 25%),
      "text-selected": #ffffff,
      // Command Launch
      "command-launch-text": #d5d5d5,
      "command-launch-background": #464c53,
      // Misc
      "favorited": #f58220,
      "sidebar": #ffffff,
   ),
) !default;

@mixin themify($property, $key, $themes: $themes) {
   @each $theme, $colors in $themes {
      .theme-#{$theme} &,
      &.theme-#{$theme} {
         #{$property}: map-get($colors, $key);
      }
   }
}

@mixin border-radius($arguments) {
   @include themify("border-radius", $arguments);
}

@mixin color($arguments) {
   @include themify("color", $arguments);
}

@mixin box-shadow($arguments) {
   @include themify("box-shadow", $arguments);
}

@mixin border-color($arguments) {
   @include themify("border-color", $arguments);
}

@mixin border-top-color($arguments) {
   @include themify("border-top-color", $arguments);
}

@mixin border-right-color($arguments) {
   @include themify("border-right-color", $arguments);
}

@mixin border-bottom-color($arguments) {
   @include themify("border-bottom-color", $arguments);
}

@mixin border-left-color($arguments) {
   @include themify("border-left-color", $arguments);
}

@mixin background-color($arguments) {
   @include themify("background-color", $arguments);
}

@mixin background($arguments) {
   @include themify("background", $arguments);
}

@mixin fill($arguments) {
   @include themify("fill", $arguments);
}

@mixin stroke($arguments) {
   @include themify("stroke", $arguments);
}

@mixin outline-color($arguments) {
   @include themify("outline-color", $arguments);
}
