.change-password-email-component {
   align-items: stretch !important;

   .change-password {
      height: calc(100% - 4.25em);

      form {
         height: 100%;
         display: flex;
         flex-direction: column;
      }

      .flex-full {
         flex: 0;
      }

      .change-password-buttons {
         display: flex;
         flex-grow: 1;

         lcs-progress-button {
            align-self: flex-end;
         }
      }
   }
}
