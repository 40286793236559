@import "external.scss";
@import "node_modules/primeng/resources/primeng.min.css";
@import "node_modules/primeng/resources/themes/nova/theme.css";
@import "node_modules/primeicons/primeicons.css";
@import "foundation/foundation-icons.css";
@import "_angular-material.scss";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// OWA Theme Styles
@import "themes/_themes.scss";
// Shared LCS libraries
@import "projects/libraries/lcs/src/lib/styles/utilities/lcs-abstract.scss";
@import "projects/libraries/lcs/src/lib/styles/layout/lcs-layout.scss";
@import "projects/libraries/lcs/src/lib/styles/components/lcs-components.scss";
// OWA Styles
@import "_variables.scss";
@import "_mixins.scss";
@import "_base.scss";
@import "_datatables.scss";
@import "_filters.scss";
@import "_account-menu.scss";
@import "_layout.scss";
@import "_top-bar.scss";
@import "_reports.scss";
@import "_dashboard.scss";
@import "_input.scss";
@import "_menu.scss";
@import "_footer.scss";
@import "_tile.scss";
@import "_service-issues.scss";
@import "_verify-pending.scss";
@import "_dialog.scss";
@import "_pdf-viewer.scss";
@import "_overlay-panel.scss";
@import "_loading-overlay.scss";
@import "_signable-documents.scss";
@import "_links.scss";
@import "_buttons.scss";
@import "_ngprime-overrides.scss";
@import "_date-range-picker.scss";
@import "_slide-panel.scss";
@import "_progress-button.scss";
@import "_webchat.scss";
@import "_forms.scss";
@import "_linked-account-management.scss";
@import "_sortable-list.scss";
@import "_image-carousel.scss";
@import "_change-password-email.scss";
