* {
   box-sizing: border-box;
   &:disabled {
      @include disabled;
   }
}

.disabled {
   @include disabled;
}

html,
body,
.page {
   height: 100%;
   width: 100%;
   min-width: 300px;
}

body {
   @include background("ui-background");
   @include color("text-primary");
   font-family: $font-family--primary;
   padding: 0;
   margin: 0;
   font-size: 0.875em;
   line-height: 1;
   overflow: hidden;
}

a {
   @include color("link");
   @include font-secondary;
   font-weight: $font-weight--normal;
   text-decoration: none;
}

.link {
   @include color("link");
   text-decoration: none;
   cursor: pointer;
}

.routing-link {
   @include color("primary-button-accent");
   text-decoration: none;
   cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   @include font-secondary;
   @include color("text-primary");
   line-height: 1.45;
   font-weight: $font-weight--semi-bold;
}

h1 {
   margin-top: 0.67em;
   margin-bottom: 0.67em;
   font-size: em(30px);
}

h2 {
   margin-top: 0.83em;
   margin-bottom: 0.83em;
   font-size: em(22px);
}

h3 {
   margin-top: $spacing-md;
   margin-bottom: $spacing-md;
   font-size: em(18px);
}

h4 {
   margin-top: 1.33em;
   margin-bottom: 1.33em;
   font-size: em(16px);
}

h5 {
   font-size: em(14px);
   margin-bottom: 1.25em;
}

h6 {
   margin-top: 2.33em;
   margin-bottom: 2.33em;
   font-size: em(12px);
}

p {
   @include font-primary;
   @include color("text-primary");
   margin: 20px 0px;
   line-height: em(28px);
}

.bold,
b,
strong {
   font-weight: $font-weight--semi-bold;
}

ul,
li {
   list-style: none;
   margin: 0 !important;
   padding: 0;
}

owa-top-bar {
   visibility: visible;
}

.header-message,
.page-title {
   @include color("text-primary");
   @include border-color("border");
   border-bottom: 1px solid;
   font-size: em(24px);
   padding: $spacing-md;
   background-color: #fff;
   height: $context-bar-height;
   display: flex;
   align-items: center;
   font-weight: $font-weight--normal;
}

.header-message {
   &.welcome {
      @include color("text-secondary");
      font-weight: $font-weight--light;
   }
}

.material-icons {
   font-size: 1.5em;
   width: 1em;
   height: 1em;
   user-select: none;         
}

.ios-scrollable {
   // -webkit-transform: translateZ(0px);
   // -webkit-transform: translate3d(0, 0, 0);
   // -webkit-perspective: 1000;
   -webkit-overflow-scrolling: touch;
}

[hidden] {
   display: none !important;
}

.justify-center {
   justify-content: center;
}

.visibility-hidden {
   visibility: hidden;
}

.strike-through {
   pointer-events: none;
   text-decoration: line-through;
}

.no-wrap {
   white-space: nowrap;
}

.flex-container {
   display: flex;
}

lcs-error-dialog-footer lcs-progress-button {
   float: right;
}

.end-tooltip {
   // background-color: #f28228 !important;
   @include background-color("primary-button-accent");
}

.email-tooltip {
   @include background-color("primary-button-accent");
}

.single-selector-wrapper.select,
.single-line-multi-selector-wrapper.select {
   .selector-content {
      .selector-content-label {
         width: 100%;
         text-overflow: ellipsis !important;
         overflow: hidden !important;
         padding-right: $spacing-xs;
         user-select: none;
      }
   }
}
.selector-overlay-item .selector-overlay-item-display .selector-overlay-item-display-label {
   padding-left: 5px;
}

.selector-container {
   min-width: 0;
}

.lock-div {
   cursor: not-allowed;
   pointer-events: none;
   opacity: 0.75;
}

.pin-icon {
   display: inline-block;
   *display: inline;
   height: 20px;
   width: 20px;
   margin: 0px 15px 0px 10px;
   vertical-align: middle;
}

.tableCell {
   display: table-cell;
}

.alignLineWithPinIcon {
   display: table-cell;
   min-width: 45px;
}

.password-tooltip {
   white-space: pre-line !important;
   font-size: 12px !important;
   max-width: unset !important;
}
.password-tootip-icon {
   vertical-align: sub;
   font-size: 1.5em !important;
}

.error-icon {
   @include color("primary-button-accent");
}
.error-tooltip {
   @include background-color("primary-button-accent");
}
.help-icon {
   @include color("text-secondary");
}
.help-tooltip {
   @include background-color("text-secondary");
}
.check-icon {
   @include color("success");
}
.check-tooltip {
   display: none;
}

.field-controls {
   margin-bottom: 1.5em !important;
}

body .ui-widget-overlay,
.p-component-overlay {
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1 !important;
}

.modal-header-border {
   border: 1px solid #e5e5e5;
   border-radius: 2px 2px 0px 0px;
   display: block;
}

.email-tooltip {
   line-height: 20px !important;
   @include background-color("primary-button-accent");
   @include color("text-primary-button-accent-color");
}
