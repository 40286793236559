lcs-datatable-filters {
   width: 100%;
   max-width: 340px;
   height: 100%;
   display: flex;

   @include small-screen {
      width: 100%;
      max-width: 100%;
      grid-column-start: 1;
      grid-column-end: -1;
      z-index: 10;
   }

   form {
      height: 100%;
   }
}

.filter-section {
   @include grid-layout;
   @include background-color("datatable-hover");
   @include border-right-color("border");
   @include border-top-color("datatable-header");
   grid-template-rows: 42px 1fr 50px;
   grid-template-columns: $filter-section-width;
   border-right: 1px solid;
   border-top: 1px solid;
   min-width: 340px;

   @include small-screen {
      grid-template-columns: auto;
      border-right: none;
      min-width: 0;
   }

   label {
      @include color("text-primary");
   }

   .input-wrapper {
      input {
         @include background-color("input-filter");
      }
   }

   .filter-collapse-icon {
      cursor: pointer;
      @include color("primary-icons");

      &:before {
         margin: 0;
      }

      &:hover {
         @include color("primary-icons-lighten-5");
      }
   }

   .apply-filters-button {
      position: absolute;
      right: 10%;
      display: none;
   }

   &.no-filters {
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

.filter-buttons {
   @include background-color("datatable-header");
   @include border-color("datatable-border");
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 $spacing-md;
   border-bottom: 1px solid;
   border-right: 1px solid;
   user-select: none;
}

.filter-list {
   overflow-y: auto;
   padding: 0 $spacing-md $spacing-md;
   display: flex;
   flex-direction: column;

   @include small-screen {
      width: 100%;
   }

   .filter {
      display: block;
      flex-wrap: wrap;
      margin: $spacing-xs 0;
      padding: 0;
      min-height: auto;

      &.saved-filters {
         @include border-color("border");
         border-bottom: 1px solid;
         margin-bottom: $spacing-md;
         padding-bottom: $spacing-md;
      }

      label {
         margin: 0;
      }

      .single-line-multi-selector-wrapper,
      .single-selector-wrapper,
      input {
         @include background-color("input-filter");
         margin: 0;
      }

      select.white {
         margin-top: $spacing-md;
      }

      .mat-form-field {
         width: 100%;

         .mat-form-field-wrapper {
            padding-bottom: 0.25em;

            .mat-form-field-infix {
               padding-top: 0.2em;
               padding-bottom: 0.2em;
            }
         }

         .mat-input-wrapper {
            background: transparent;
            padding-bottom: 0;
         }

         .mat-form-field-underline {
            display: none;
         }

         .mat-input-infix {
            margin: 0;
            border: 0;
            height: 2.25em;

            .mat-form-field-placeholder {
               position: relative;
               top: 22px;
            }

            .mat-form-field-placeholder.mat-form-field-empty {
               position: inherit;
            }
         }

         mat-select {
            &.mat-select {
               font-family: $font-family--primary;
               font-size: em(14px);
               width: 100%;
            }

            &.mat-select-value-text {
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               line-height: 30px;
               font-size: em(14px);
            }

            .mat-select-value {
               top: 7px;
            }
         }
      }
   }

   .udv-filters-wrapper {
      @include border-color("input-border");
      @include background-color("input-border");
      padding: $spacing-xs;
      border: 1px solid;
      border-radius: 3px;
      margin-bottom: 1em;

      .add,
      .remove {
         margin-left: $spacing-xs;
      }

      .udv-filters-selector-wrapper {
         display: flex;
         align-items: center;

         lcs-select {
            flex: 10;
         }

         .single-selector-wrapper select {
            @include background-color("input-background");
            margin-bottom: 0;
         }
      }

      .material-icons.remove,
      .material-icons.add {
         @include color("text-primary");
         flex: 1;
         cursor: pointer;

         &:hover {
            @include color("primary-lighten-5");
         }

         &:before {
            margin: 0;
         }
      }

      .material-icons.remove {
         @include color("text-secondary");
         position: relative;
         top: 15px;
      }

      .udv-filters {
         @include border-color("input-border");
         @include background-color("input-background");
         margin-top: $spacing-xs;
         padding: 0 $spacing-xs $spacing-xs;
         border: 1px solid;

         .filter {
            display: flex;
            align-items: center;

            .udv-filter {
               flex: 10;
            }
         }
      }
   }
}

@media screen and (max-width: 600px) {
   .small-date {
      text-align: left;
      padding-left: 7px;
   }
}

.mat-input-wrapper {
   &:hover {
      @include hover-transition;

      .mat-form-field-label {
         @include color("primary");
      }

      .mat-select-arrow {
         @include border-top-color("primary");
      }
   }
}

.mat-select-value-text {
   span {
      @include color("primary-lighten-5");
   }
}

.mat-select-arrow {
   @include border-top-color("text-primary");
}

.filter-footer-buttons {
   display: flex;
   justify-content: flex-end;
   padding: 0 $spacing-md;
}

.datatable-top-filter-section {
   @include background-color("datatable-row");
   display: flex;
   -webkit-position: sticky;
   top: 0;
   justify-content: space-between;
   width: 100%;
   height: $datatable-top-filter-section-height;

   @include medium-screen {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      display: table;
   }

   @include small-screen {
      display: table;
      padding: 0.5em 1em;
   }

   .datatable-top-filter-section-left,
   .datatable-top-filter-section-middle,
   .datatable-top-filter-section-right {
      @include flexy;
      align-items: center;
      padding: $spacing-sm;
   }

   .datatable-top-filter-section-left {
      @include medium-screen {
         height: auto;
         align-items: flex-start;
         flex-direction: column;
         width: 100%;
      }

      .filter-list-header {
         padding: $spacing-xs;
         align-items: center;
         display: flex;
         user-select: none;

         &:hover {
            @include hover-transition;
            opacity: 0.8;
         }

         span {
            @include color("text-primary");
            font-size: em(16px);
            font-weight: $font-weight--normal;
            display: inline-block;
            text-transform: uppercase;
            cursor: pointer;
            align-self: center;
         }

         i.material-icons {
            @include color("primary-icons");
            margin: 0 $spacing-xs;
            cursor: pointer;

            &:hover {
               @include hover-transition;
               @include color("primary-icons-lighten-5");
            }
         }
      }

      .datatable-search-section {
         flex: 0 1 auto;
         padding: $spacing-xs;
         display: flex;
         width: $input-width;
         min-width: $input-width;

         @include medium-screen {
            width: 100%;
         }
      }

      .datatable-quick-filter-section {
         flex: 0 1 auto;
         display: flex;
         flex-wrap: wrap;
         align-items: center;

         .search-filters {
            margin: 0;
            align-self: center;
            padding: $spacing-xs;

            rmx-searchable-selector {
               display: flex;
               align-items: center;
            }

            &.search-items {
               display: flex;
               align-items: center;

               @include medium-screen {
                  display: block;
               }

               .select {
                  width: $input-width;
                  min-width: $input-width;

                  @include medium-screen {
                     width: auto;
                  }
               }
            }
         }

         rmx-quick-filter-control {
            @include medium-screen {
               flex: 1 0 auto;
            }
         }

         label {
            margin: 0px 5px;
            font-size: 1em;
         }

         lcs-multi-selector-overlay-panel {
            label {
               margin: 0;
            }
         }

         .searchable-selector-wrapper {
            @include medium-screen {
               width: 100%;
            }

            &.searchable-selector-inline-label {
               @include medium-screen {
                  display: block;

                  label {
                     padding: 0;
                     margin: 5px 0px;
                  }
               }
            }
         }

         @include small-screen {

            rmx-quick-filter-control,
            .search-items {
               flex: 1 1 100%;
            }

            label,
            .checkbox-wrapper {
               margin: 0;
            }
         }

         @include small-screen {
            grid-template-columns: 1fr;
         }
      }
   }

   .datatable-top-filter-section-right {
      min-width: calc(100px + #{$spacing-sm} * 2);
      justify-content: flex-end;
      padding-right: 1em;

      @include medium-screen {
         justify-content: flex-end;
         width: 100%;
      }

      .datatable-action {
         font-size: 1.05em;
         padding: $spacing-xs;
      }
   }

   .datatable-breadcrumb-section {
      flex: 1 1 auto;
      flex-wrap: wrap;
      align-items: center;
      display: flex;

      @include small-screen {
         flex: 1 1 auto;
      }

      .datatable-breadcrumb-applied-filters {
         margin: 0;

         ul {
            margin: 0;
            padding: 0;
         }

         li {
            @include background("input-background");
            display: inline-block;
            list-style: none;
            margin: 0 0 0 $spacing-sm;
            padding: $spacing-md;
            box-sizing: border-box;
            vertical-align: top;
            letter-spacing: $font-weight--normal;
            text-rendering: auto;
            font-size: em(14px);
            border-radius: 3px;
         }

         .filter-category {
            @include color("text-secondary");
            margin: 0px;
         }

         .filter-value {
            @include color("filter-text");
            margin: 0px 3px;
            font-style: italic;
            font-weight: $font-weight--bold;
         }

         .remove-filter-icon {
            @include color("text-accent");
            padding-left: 5px;
            cursor: pointer;
            font-weight: bold;
            font-size: 0.8rem;
         }
      }
   }
}

.datatable-count {
   @include color("text-primary");
   display: flex;
   align-self: center;
   align-items: center;
   padding: $spacing-xs;
   font-size: em(12px);
   white-space: nowrap;
   position: absolute;
   right: 7px;
   top: -18px;

   .datatable-count-name {
      @include color("text-accent");
      margin-left: $spacing-sm;
      display: inline-block;

      @include small-screen {
         display: none;
      }
   }
}

.quick-filter-type {
   width: 10em;

   @include small-screen {
      width: 100%;
   }
}

.quick-filter-search {
   margin-left: 1em;
}

/* Service Manager Issues */

.filter-heading {
   display: flex;
   cursor: pointer;
   margin: 0 0 $spacing-md 0;
}

.options-bar {
   display: flex;
   line-height: 0;
   padding-bottom: $spacing-sm;
   margin: $spacing-md 0 $spacing-md $spacing-lg;
}

.filter-caret-icon.caret-up {
   margin-left: auto;
   align-self: flex-end;
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   transform: rotate(-90deg);
}

.filter-caret-icon.caret-down {
   cursor: pointer;
   margin-left: auto;
   align-self: flex-end;
   -webkit-transform: rotate(90deg);
   -moz-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   -o-transform: rotate(90deg);
   transform: rotate(90deg);
}

.ui-overlaypanel-content {
   max-width: 62.5rem;

   .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
         margin: 0;
      }

      .search-filter-value {
         width: 14em;
         margin: 0;
      }
   }

   .modal-header .search-filters {
      margin-left: auto;
   }

   ul.filter-alphabets {
      max-height: 70px;
      margin-top: $spacing-md;

      >li {
         display: inline;
         padding: 10px;
      }
   }

   ul.filter-values {
      max-height: 360px;
      overflow: hidden;
      overflow-y: auto;

      >li {
         display: inline;

         label {
            margin: 0;
         }
      }
   }

   ul.filter-alphabets {
      >li.is-active {
         @include background-color("primary");
      }

      >li.is-active a {
         color: $white;
      }
   }
}

.datatable-section {
   @include background-color("datatable-row");
   display: flex;
   position: relative;
   height: inherit;
   flex: 1 1 auto;

   @include small-screen {
      flex-direction: column;
   }
}

.account-group-recurring-charges-filter {
   justify-self: flex-end;
}

.datatable-grid-wrapper {
   display: flex;
   flex-direction: column;
   height: 100%;
}

lcs-datatable-filter-input,
lcs-datatable-user-defined-value-filter {
   lcs-filter-operation-selector {
      .searchable-selector-wrapper {
         background-color: transparent !important;
         border: none;
         @include color("primary-filter-link-icons");
         padding-right: 0;

         .searchable-selector-container {
            .input-wrapper {
               display: flex;

               input {
                  background-color: transparent !important;
                  @include color("primary-filter-link-icons");
                  border: none;
                  font-size: 0.75em;
                  padding-right: 0.5em;
                  text-align: right;
               }
            }
         }
      }
   }

   .datatable-filter-label-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 99%;
      height: 2em;

      label {
         text-overflow: ellipsis;
         margin: 0;
         font-weight: $font-weight--semi-bold;
      }
   }

   .datatable-filter-input-section {
      display: flex;
      align-items: center;
      width: 100%;

      .datatable-filter-input-control {
         width: 100%;
      }
   }
}

// end datatable filter operation selector block
rmx-filter-stand-alone {
   .filter-standalone-section {
      margin-top: 1em;
      display: grid;
      grid-template-rows: 45px 1fr 50px;
      grid-template-columns: 340px;
   }

   .filter-stand-alone {
      overflow-y: auto;
      padding: 0em 0em 0em;
      display: flex;
      flex-direction: column;

      .mat-form-field-wrapper {
         padding-bottom: 0px;
      }

      .filter {
         display: block;
         flex-wrap: wrap;
         margin: 0;
         padding: 0;
         min-height: auto;

         &.saved-filters {
            margin-bottom: 1em;
            padding-bottom: 1em;
            @include border-color("input-border");
            border-width: 1px;
            @include background-color("input-background");
         }

         label {
            margin: 0;
            @include color("text-secondary");
            font-family: $font-family--primary;
         }

         input {
            @include background-color("input-background");
            margin: 0;
         }

         select.white {
            margin-top: 1em;
         }

         .mat-form-field {
            width: 100%;

            .mat-input-wrapper {
               padding-bottom: 0;
            }

            .mat-form-field-underline {
               display: none;
            }

            .mat-input-infix {
               margin: 0;
               border: 0;
               height: 2.25em;

               .mat-form-field-placeholder {
                  position: relative;
                  top: 22px;
               }

               .mat-form-field-placeholder.mat-form-field-empty {
                  position: inherit;
               }
            }

            mat-select {
               &.mat-select {
                  font-family: $font-family--primary;
                  font-size: em(14px);
                  width: 100%;
               }

               &.mat-select-value-text {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 30px;
                  font-size: em(14px);

                  span {
                     @include color("text-primary");
                  }
               }

               .mat-select-value {
                  top: 7px;
               }
            }
         }
      }
   }
}