.dashboard-container {
   margin: 0 auto;
   height: 100%;
   width: 100%;
}

.dashboard-header {
   @include color("text-primary");
   h1 {
      font-weight: $font-weight--light;
   }
}

.dashboard-content-wrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;

   @include small-screen {
      margin-right: $spacing-xs;
   }

   .flex-third {
      max-width: 625px;

      @include medium-screen {
         flex: 1 1 100%;
         width: 100%;
         max-width: 100%;
      }
   }
}

.dashboard-tile {
   @include background-color("content-tile");
   border-radius: $border-radius;
   min-height: 350px;
   height: calc(100% - #{$spacing-md});
   margin: $spacing-md;
   box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.25);
   max-width: 600px;

   @include medium-screen {
      display: block;
      width: 100%;
      margin: $spacing-tile 0;
      max-width: 100%;
   }

   &-header {
      // @include background-color('alt');
      @include border-color("border");
      border-bottom: 2px solid;
      border-radius: 3px 3px 0px 0px;
      height: 50px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.5em;
   }

   &-title {
      @include font-secondary;
      @include color("text-primary"); // color: #fff;
      font-size: em(18px);
      font-weight: $font-weight--semi-bold;
   }

   &-content {
      max-height: 350px;
      overflow: hidden;
   }
}

.dashboard-tile-wrapper {
   height: 100%;
   padding-bottom: $spacing-md;

   @include medium-screen {
      padding: 0 $spacing-sm;
   }

   @include medium-screen {
      padding: 0 0 $spacing-sm 0;
   }
}

.is-view-more {
   @include color("primary-icons");
   cursor: pointer;

   &:hover {
      @include hover-transition;
      @include color("primary-icons-lighten-5");
      opacity: 0.7;
   }
}

.content-padding {
   padding: 15px;
}

.no-result {
   font-size: 13px;
   text-align: center;
}

.dash-table {
   width: 100%;

   tbody td {
      padding: 0.5rem 0.625rem 0.625rem;
   }

   tbody tr:nth-child(even) {
      border-bottom: 0;
      background-color: #f1f1f1;
   }
}

.issue-item {
   margin-bottom: $spacing-lg;
   max-width: 269px;

   @include medium-screen {
      max-width: fit-content !important;
   }

   .issue-date {
      @include color("text-secondary");
      font-size: em(14px);
   }

   .issue-title {
      @include color("link");
      font-family: $font-family--primary;
      font-weight: $font-weight--semi-bold;
      font-size: em(18px);
      cursor: pointer;
      margin: $spacing-xs 0;
      line-height: 1.45;
   }

   .issue-description {
      @include color("text-accent");
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 520px;
      font-size: em(14px);
      line-height: normal;

      @include medium-screen {
         max-width: fit-content !important;
      }

      @include small-screen {
         max-width: 434px;
      }
   }
}

.notification-count {
   @include background-color("accent");
   color: #fff;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   display: flex;

   align-items: center;
   justify-content: center;
   border: 3px solid #fff;
   box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
   position: absolute;
   right: -18px;
   top: -20px;
}

.notification-icon {
   @include background-color("accent");
   width: 12px;
   height: 12px;
   margin-right: 2px;
   border-radius: 50%;
   float: right;
}
.message-item {
   padding-top: 0.5em;
   line-height: 1.5;
}

.dashboard-message {
   @include background-color("ui-content");
   box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.25);
   display: flex;
   flex: 1 1 auto;
   align-items: center;
   min-height: 50px;
   border-radius: 3px;
   padding: 1em;
   margin-left: 1em;
   margin-right: 1em;
   margin-top: 10px;
   margin-bottom: 10px;

   @include medium-screen {
      margin-right: 0px;
      margin-left: 0px;
   }

   @include small-screen {
      margin-right: 0px;
      margin-left: 0px;
      flex-wrap: wrap;
   }

   .dashboard-message-value {
      margin-right: 1em;
      line-height: 1.45;

      @include small-screen {
         margin: 0;
      }
   }
}

.dashboard-message-link-container {
   margin-left: auto;
   @include font-secondary;

   @include small-screen {
      margin: $spacing-md 0 0 auto;
   }

   a {
      @include color("text-primary-button-accent-color");
      font-weight: $font-weight--semi-bold;
   }

   a:hover {
      cursor: pointer;
   }

   .dashboard-message-link {
      border-radius: 3px;
      padding: 0.75em;
      text-transform: capitalize;
      @include background-color("primary-button-accent");

      &:hover {
         @include background-color("primary-button-accent-darken-5");
         @include hover-transition;
      }
   }
}

.contract-end {
   @include color("primary");
   font-size: 1.75em !important;
}
.info-icon {
   @include color("secondary");
   font-size: 1.75em !important;
}

.tile-inner-wrapper {
   overflow-x: hidden;
   overflow-y: auto;
   max-height: 330px;
}

owa-dashboard-signable-documents {
   .tile-wrapper {
      padding: 0;
   }

   owa-signable-documents-list-item {
      max-width: unset;
   }

   .signable-documents-list-container {
      padding: 0;
      height: 100%;
   }

   .signable-document-name {
      font-weight: 600;
      border-bottom: 2px solid #e5e5e5 !important;
   }

   .sign {
      color: #f28228 !important;
      cursor: pointer;
   }

   .review {
      color: #6db844 !important;
      cursor: pointer;
   }

   .signable-documents {
      height: 100%;
   }

   .sign-table-status-container {
      .status-container-item {
         text-align: center;
         display: flex;
         align-items: center;
         justify-content: left;

         .signable-icon {
            @include color("primary");
            margin-right: $spacing-xs;
         }

         .complete-icon {
            @include color("success");
            margin-right: $spacing-xs;
         }
      }
   }
}

@include small-screen {
   .dashboard-tile-content {
      max-height: none;
   }
}
