.account-menu-wrapper {
   display: flex;
   align-items: center;
   padding: 0 0 0 $spacing-md;
}

.account-menu-container {
   display: flex;
   flex: 1 auto;
   flex-direction: row;
   justify-content: space-between;
   .account-name-label {
      display: flex;
      align-items: center;
      font-weight: $font-weight--semi-bold;
      user-select: none;
      &.hoverable {
         cursor: pointer;
      }
   }
   .switch-accounts-link {
      font-size: em(14px);
      @include color('link');
      &:hover {
         cursor: pointer;
      }
   }
}

.account-menu-cog {
   li {
      @include background-color('primary-darken-5');
      width: 50px;
      height: 50px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
   }
   &:hover {
      @include background-color('primary-darken-10');
      @include hover-transition;
      cursor: pointer;
   }
}

.account-context-menu-container {
   .account-context-menu {
      list-style: none;
      .account-context-menu-item {
         @include border-color('menu-border');
         box-sizing: border-box;
         border-bottom: 1px solid;
         text-transform: initial;
         text-align: left;
         padding: $spacing-sm $spacing-md;
         height: 40px;
         display: flex;
         align-items: center;
         &:not(.owner-menu-info) {
            @include hover-transition;
            &:hover {
               @include background-color('menu-hover');
            }
         }
         &.owner-menu-info {
            @include color('text-primary');
            display: block;
            padding: $spacing-md;
            line-height: 1.45;
            height: auto;
            .owner-name {
               font-size: 1em;
               font-weight: $font-weight--semi-bold;
               display: block;
            }
            .owner-email {
               @include color('text-accent');
               font-size: em(14px);
            }
         }
      }
      a.account-context-menu-item {
         @include color('link');
         @include font-secondary;
         cursor: pointer;
      }
      button.account-context-menu-item {
         @include color('text-primary');
         @include font-secondary;
         background-color: transparent;
         border-radius: 0;
      }
      .sign-out {
         margin-right: $spacing-xs;
      }
   }
}
