.top-bar {
   @include background-color("primary");
   display: flex;
   height: $top-bar-height;
   @include color("text-primary-toolbar");
   position: sticky;
   width: 100%;
   z-index: 1;
   &-visible {
      display: block;
   }
}

.top-bar,
.top-bar-container {
   padding: 0;
}

.top-bar-container {
   display: none;
   .site-image-container {
   @include background-color("top-bar-image-background");
}
}

.top-bar-visible .top-bar-container {
   display: block;
}

.owa-svg-container {
   margin: 0.8em 2em;
   @include background-color("top-bar-image-background");
}

.site-menu-inner {
   width: 100%;
   display: flex;
}

.menu-content-wrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   width: 100%;
   overflow: auto;
   overflow-y: hidden;
}

ul.owa-desktop-menu {
   white-space: normal;
   overflow: hidden;
   flex: 1;
   min-width: 0;
   height: $top-bar-height;
}

.menu-item {
   display: inline-flex;
   white-space: nowrap;
   text-overflow: ellipsis;
   height: 50px;
   user-select: none;
   align-items: center;
   @include hover-transition;
   &.hamburger {
      @include background-color("primary-darken-5");
      width: 50px;
      justify-content: center;
   }
   &:hover {
      cursor: pointer;
      @include background-color("primary-darken-10");
   }
   &:focus {
      outline: none;
      @include background-color("primary-darken-5");
   }
   a {
      text-decoration: none;
      //color: #fff;
      @include color("text-primary-toolbar");
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1.5em;
   }
}

.active-menu-item {
   @include background-color("primary-darken-5");
}

owa-account-menu {
   min-width: 200px;
}

.logout-menu {
   @include background-color("content-tile");
   @include color("text-secondary");
   width: 300px;
   max-width: 300px;
   padding-top: $spacing-md;
   top: 15px;
   right: 0px;
   @include small-screen {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      top: 19px;
      right: 0px;
   }
   h5 {
      @include color("text-primary");
      margin: 0 $spacing-md;
      font-family: $font-family--primary;
      font-weight: $font-weight--semi-bold;
      font-size: 1em;
   }
   .menu-section {
      @include border-color("input-border");
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid;
      margin: $spacing-md;
      padding: 0 0 $spacing-sm 0;
      flex-direction: row;
      &:last-of-type {
         border: none;
         margin: 0;
      }
      a.action-link {
         @include font-primary;
         &.text-accent:hover {
            @include color("primary");
         }
      }
   }
}

// lcs-sidebar-menu overrides
lcs-sidebar-menu {
   position: absolute;
   height: 100%;
   width: 100%;
   overflow: hidden;
   .sidebar-menu-overlay {
      height: calc(100vh - #{$site-image-container-height} - #{$top-bar-height});
   }
}

.sidebar-menu-page {
   overflow-y: auto !important;
   // fix to allow for site image container growth
   padding-bottom: 150px;
}
